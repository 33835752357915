import { useEventSourceRef } from '@common/hooks'
import { getDataProp } from '@common/utils'
import { selectIsLoggedIn } from '@features/auth/redux/authSlice'
import queryClient from '@infrastructure/reactQuery/queryClient'
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { supportChatNavigation } from '../navigation'
import { slice } from '../redux'
import {
  createMessage,
  getChatStatus,
  getDraftContent,
  getMessages,
  messageEventMap,
  messageRoleMap,
  subscribeOnChatStatusChangeUrl,
  subscribeOnMessageEventsUrl,
  updateDraftContent
} from '../repository'

export const keys = {
  all: ['supportChat'],
  messages: () => [...keys.all, 'messages'],
  draftContent: () => [...keys.all, 'draftContent'],
  status: () => [...keys.all, 'status']
}

export const useChatStatusQ = (options) =>
  useQuery({
    queryKey: keys.status(),
    queryFn: useCallback(() => getChatStatus().then(getDataProp)),
    ...options
  })

export const useSyncChat = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const statusEventSourceRef = useEventSourceRef({
    url: subscribeOnChatStatusChangeUrl,
    enabled: isLoggedIn
  })
  useEffect(() => {
    if (!isLoggedIn) {
      return
    }
    const fn = (e) => {
      const key = keys.status()
      if (queryClient.getQueryData(key)) {
        queryClient.setQueryData(key, e.data)
      }
    }
    statusEventSourceRef.current.addEventListener('message', fn)
    return () => {
      statusEventSourceRef.current?.removeEventListener('message', fn)
    }
  }, [isLoggedIn])
  const messageEventSourceRef = useEventSourceRef({
    url: subscribeOnMessageEventsUrl,
    enabled: isLoggedIn
  })
  const dispatch = useDispatch()
  useEffect(() => {
    if (!isLoggedIn) {
      return
    }
    const fn = (e) => {
      const { role } = JSON.parse(e.data)
      if (role === messageRoleMap.user) {
        queryClient.invalidateQueries({ queryKey: keys.draftContent() })
      }
      if (window.location.pathname !== supportChatNavigation.pathname) {
        dispatch(slice.actions.setHasNewMessages(true))
      }
    }
    messageEventSourceRef.current.addEventListener(messageEventMap.new, fn)
    return () => {
      messageEventSourceRef.current?.removeEventListener(
        messageEventMap.new,
        fn
      )
    }
  }, [isLoggedIn])
  useEffect(() => {
    if (!isLoggedIn) {
      return
    }
    const fn = (e) => {
      if (!e.data) {
        return
      }
      const { role } = JSON.parse(e.data)
      if (role !== messageRoleMap.user) {
        return
      }
      dispatch(slice.actions.deletePendingMessage())
    }
    messageEventSourceRef.current.addEventListener(messageEventMap.error, fn)
    return () => {
      messageEventSourceRef.current?.removeEventListener(
        messageEventMap.error,
        fn
      )
    }
  }, [dispatch, isLoggedIn])
}

export const useDraftContentQ = (options) =>
  useQuery({
    queryKey: keys.draftContent(),
    queryFn: useCallback(() => getDraftContent().then(getDataProp), []),
    ...options
  })

export const useMessagesInfQ = ({
  limit = 10,
  enabled = true,
  ...options
} = {}) => {
  const dispatch = useDispatch()
  const infQuery = useInfiniteQuery({
    enabled,
    queryKey: keys.messages(),
    initialPageParam: {
      limit,
      cursor: 'latest'
    },
    queryFn: useCallback(async ({ pageParam, signal }) => {
      const data = await getMessages({ params: pageParam, signal }).then(
        getDataProp
      )
      return data
    }, []),
    getPreviousPageParam: useCallback(
      (firstPage) =>
        firstPage.prevCursor && {
          operator: 'lt',
          cursor: firstPage.prevCursor,
          limit
        },
      [limit]
    ),
    getNextPageParam: useCallback(
      (lastPage) => ({
        operator: 'gt',
        cursor: lastPage.nextCursor,
        limit
      }),
      [limit]
    ),
    ...options
  })
  const { fetchNextPage } = infQuery
  const eventSourceRef = useEventSourceRef({
    url: subscribeOnMessageEventsUrl,
    enabled
  })
  const { hasNewMessages } = useSelector(slice.selectSlice)
  useEffect(() => {
    if (!enabled) {
      return
    }
    const fn = () => {
      fetchNextPage().then(() => {
        dispatch(slice.actions.deletePendingMessage())
        dispatch(slice.actions.setHasNewMessages(false))
      })
    }
    if (hasNewMessages) {
      fn()
    }
    eventSourceRef.current.addEventListener(messageEventMap.new, fn)
    return () => {
      eventSourceRef.current?.removeEventListener(messageEventMap.new, fn)
    }
  }, [fetchNextPage, enabled])
  return infQuery
}

export const useCreateMessage = (options) => {
  const dispatch = useDispatch()
  return useMutation({
    mutationFn: useCallback(({ data }) => createMessage(data), []),
    onMutate: useCallback(
      ({ data }) => {
        dispatch(slice.actions.patchPendingMessage(data))
      },
      [dispatch]
    ),
    onError: useCallback(() => {
      dispatch(slice.actions.deletePendingMessage())
    }, [dispatch]),
    onSuccess: useCallback(
      (resp) => {
        const data = getDataProp(resp)
        dispatch(slice.actions.patchPendingMessage(data))
      },
      [dispatch]
    ),
    ...options
  })
}

export const useUpdateMessageDraft = (options) =>
  useMutation({
    mutationFn: useCallback(({ data }) => updateDraftContent(data), []),
    ...options
  })

import classNames from 'classnames'
import { Link } from 'react-router-dom'

import { IconChevronDoubleDown } from '@atoms/Icons'

import { PageContentContainer, PageContentSpacing } from '@atoms/Page'
import { isNil } from 'lodash-es'
import { forwardRef } from 'react'
import { useInView } from 'react-intersection-observer'
import RecFeedContentsGuidePopup from '../RecFeedContentsGuidePopup'
import RecommendationFeedActions from '../RecommendationFeedActions'
import RecommendationFeedContentsLink from '../RecommendationFeedContentsLink'
import './style.scss'

export const RecFeedGroupRecLink = ({ className, ...rest }) => (
  <Link {...rest} className={classNames('r-f-g-rec-pointer', className)}>
    <img
      src={IconChevronDoubleDown}
      alt="arrow"
      className="r-f-g-rec-pointer__arrow"
    />
  </Link>
)

export const RecFeedGroupRecommendation = ({ children, className }) => (
  <div className={classNames(className, 'recommendations-sequence-item')}>
    {children}
  </div>
)

export const RecFeedGroup = forwardRef(function RecFeedGroup(
  {
    title,
    order,
    recommendations,
    firstRecommendationLink,
    className,
    showActionsGuide = false,
    ...restProps
  },
  ref
) {
  return (
    <div className={classNames('rec-feed-group', className)}>
      <PageContentContainer
        size="lg"
        ref={ref}
        {...restProps}
        className="rec-feed-group__container"
      >
        <PageContentSpacing className="rec-feed-group__wrapper">
          <RecommendationFeedActions
            left={
              showActionsGuide ? (
                <RecFeedContentsGuidePopup />
              ) : (
                <RecommendationFeedContentsLink />
              )
            }
          />
          <div className="recommendations-sequence">
            <div className="recommendations-sequence-title">
              <h2 className="section-title">
                {!isNil(order) && `${order + 1}. `}
                {title}
              </h2>
            </div>
            <div>
              <ul className="recommendations-sequence-items rec-feed-group__recs">
                {recommendations}
              </ul>
              {firstRecommendationLink}
            </div>
          </div>
        </PageContentSpacing>
      </PageContentContainer>
    </div>
  )
})

export function RecFeedGroupWithGuide(props) {
  const [viewRef, inView] = useInView({
    rootMargin: '0% 0% -90% 0%',
    triggerOnce: true,
    initialInView: false,
    threshold: 0
  })
  return <RecFeedGroup ref={viewRef} {...props} showActionsGuide={inView} />
}

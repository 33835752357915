import { useTranslation } from 'react-i18next'

import NonMobileBreadcrumbs from '@features/rootNavigation/components/NonMobileBreadcrumbs'

import Container from './Container'
import Heading from './Heading'
import { Page } from './Page'

const SomethingWentWrongPage = () => {
  const [t] = useTranslation()
  return (
    <Page>
      <Container>
        <NonMobileBreadcrumbs />
        <Heading size={20} level={1} style={{ whiteSpace: 'pre-wrap' }}>
          {t('SomethingWentWrongPage.title')}
        </Heading>
      </Container>
    </Page>
  )
}

export default SomethingWentWrongPage

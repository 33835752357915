import { CapacitorCookies } from '@capacitor/core'
import { cookies, isNativePlatform } from '@common/constants'
import { API_URL } from '@common/env'
import { useValueRef } from '@common/hooks'
import { useIsUserAuth } from '@features/auth/redux/authSlice'
import userRQ from '@features/user/reactQuery'
import userRepo from '@features/user/repository'
import queryClient from '@infrastructure/reactQuery/queryClient'
import { useEffect } from 'react'
import { localeCookieKey } from '.'
import i18n from './i18n'

export const useSyncLocale = () => {
  const isUser = useIsUserAuth()
  const { data: { preferredLocale } = {} } = userRQ.useDetailQ({
    enabled: isUser
  })
  const isUserRef = useValueRef(isUser)
  useEffect(() => {
    if (!preferredLocale) {
      return
    }
    if (preferredLocale !== i18n.resolvedLanguage) {
      i18n.changeLanguage(preferredLocale)
    }
  }, [preferredLocale])
  useEffect(() => {
    const listener = async (lng) => {
      isNativePlatform
        ? CapacitorCookies.setCookie({
            key: localeCookieKey,
            locale: lng,
            url: API_URL
          })
        : cookies.set(localeCookieKey, lng)
      document.documentElement.lang = lng
      await Promise.all([
        isUserRef.current && userRepo.patchDetail({ preferredLocale: lng }),
        queryClient.invalidateQueries()
      ])
    }
    i18n.on('languageChanged', listener)
    return () => {
      i18n.off('languageChanged', listener)
    }
  }, [])
}

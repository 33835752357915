import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import SectionNWrapper from '@atoms/SectionNWrapper'
import { NODE_ENV } from '@common/env'

const CheckInternetConnection = ({ children }) => {
  const [isOnline, setIsOnline] = useState(
    NODE_ENV === 'development' ? true : navigator.onLine
  )
  const [t] = useTranslation()
  useEffect(() => {
    if (NODE_ENV === 'development') {
      return
    }
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine)
    }
    window.addEventListener('online', handleStatusChange)
    window.addEventListener('offline', handleStatusChange)
    return () => {
      window.removeEventListener('online', handleStatusChange)
      window.removeEventListener('offline', handleStatusChange)
    }
  }, [])
  return isOnline ? (
    children
  ) : (
    <SectionNWrapper>{t('CheckInternetConnection.offlineMsg')}</SectionNWrapper>
  )
}

export default CheckInternetConnection

import ListsSkeleton from './ListsSkeleton'
import SectionNWrapper from './SectionNWrapper'

const ListsPageSkeleton = () => {
  return (
    <SectionNWrapper>
      <ListsSkeleton />
    </SectionNWrapper>
  )
}

export default ListsPageSkeleton

import { groupBy, map, transform } from 'lodash-es'
import { createSelector, weakMapMemoize } from 'reselect'

import automatedRecommendationGroupRepository from '@features/automatedRecommendationGroup/repository'
import medicalConclusionRepository from '@features/medicalConclusions/repository'
import queryClient from '@infrastructure/reactQuery/queryClient'
import { createEntityAdapter } from '@reduxjs/toolkit'
import { useMutation, useQuery } from '@tanstack/react-query'

import { getFeed } from '../repository'

export const keys = {
  all: ['recommendationFeed'],
  feed: () => [...keys.all, 'feed'],
  viewConcRecommendationById: () => [...keys.all, 'viewConcRecommendationById'],
  toggleFavoredConcRecommendationById: () => [
    ...keys.all,
    'toggleFavoredConcRecommendationById'
  ],
  viewAutRecommendationById: () => [...keys.all, 'viewAutRecommendationById'],
  toggleFavoredAutRecommendationById: () => [
    ...keys.all,
    'toggleFavoredAutRecommendationById'
  ]
}

const concGroupAdapter = createEntityAdapter()

const concRecommendationAdapter = createEntityAdapter()

const autGroupAdapter = createEntityAdapter()

const autRecommendationAdapter = createEntityAdapter()

const articleAdapter = createEntityAdapter()

queryClient.setQueryDefaults(keys.feed(), {
  queryFn: async () => {
    const {
      data: {
        medConcStgGroups,
        medConcStgGrRecommendations,
        automatedRecommendationGroups,
        autRecGrRecommendations,
        articles
      }
    } = await getFeed()
    return {
      medConcStgGroupState: concGroupAdapter.addMany(
        concGroupAdapter.getInitialState(),
        medConcStgGroups.map((data, groupOrder) => ({
          ...data,
          groupOrder
        }))
      ),
      medConcStgGrRecommendationState: concRecommendationAdapter.addMany(
        concRecommendationAdapter.getInitialState(),
        medConcStgGrRecommendations
      ),
      automatedGroupState: autGroupAdapter.addMany(
        autGroupAdapter.getInitialState(),
        automatedRecommendationGroups.map((data, groupOrder) => ({
          ...data,
          groupOrder: medConcStgGroups.length + groupOrder
        }))
      ),
      autGrRecommendationState: autRecommendationAdapter.addMany(
        autRecommendationAdapter.getInitialState(),
        autRecGrRecommendations
      ),
      articleState: articleAdapter.addMany(
        articleAdapter.getInitialState(),
        articles
      )
    }
  },
  placeholderData: {
    medConcStgGroupState: concGroupAdapter.getInitialState(),
    medConcStgGrRecommendationState:
      concRecommendationAdapter.getInitialState(),
    automatedGroupState: autGroupAdapter.getInitialState(),
    autGrRecommendationState: autRecommendationAdapter.getInitialState(),
    articleState: articleAdapter.getInitialState()
  }
})

export const useFeedQ = (options) =>
  useQuery({ queryKey: keys.feed(), ...options })

queryClient.setMutationDefaults(keys.viewConcRecommendationById(), {
  mutationFn: ({ id }) =>
    medicalConclusionRepository.viewRecommendationById(id),
  onMutate: async ({ id }) => {
    const rQKey = keys.feed()
    const old = queryClient.getQueryData(rQKey)
    await queryClient.cancelQueries({ queryKey: rQKey })
    if (!old) {
      return null
    }
    queryClient.setQueryData(rQKey, {
      ...old,
      medConcStgGrRecommendationState: concRecommendationAdapter.updateOne(
        old.medConcStgGrRecommendationState,
        {
          id,
          changes: {
            viewedAt: new Date().toISOString()
          }
        }
      )
    })
    return { rQKey, old }
  },
  onError: (err, data, context) => {
    if (!context) {
      return
    }
    const { rQKey, old } = context
    queryClient.setQueryData(rQKey, old)
  }
})

queryClient.setMutationDefaults(keys.toggleFavoredConcRecommendationById(), {
  mutationFn: ({ id }) =>
    medicalConclusionRepository.toggleFavoredRecommendationById(id),
  onMutate: async ({ id }) => {
    const rQKey = keys.feed()
    const old = queryClient.getQueryData(rQKey)
    await queryClient.cancelQueries({ queryKey: rQKey })
    if (!old) {
      return null
    }
    queryClient.setQueryData(rQKey, {
      ...old,
      medConcStgGrRecommendationState: concRecommendationAdapter.updateOne(
        old.medConcStgGrRecommendationState,
        {
          id,
          changes: {
            favoredAt: concRecommendationSelectors.selectById(old, id).favoredAt
              ? null
              : new Date().toISOString()
          }
        }
      )
    })
    return { rQKey, old }
  },
  onError: (err, data, context) => {
    if (!context) {
      return
    }
    const { rQKey, old } = context
    queryClient.setQueryData(rQKey, old)
  }
})

export const useViewAutRecommendationByIdM = (options) =>
  useMutation({
    mutationFn: ({ id }) =>
      automatedRecommendationGroupRepository.viewRecommendationById(id),
    onMutate: async ({ id }) => {
      const rQKey = keys.feed()
      const old = queryClient.getQueryData(rQKey)
      await queryClient.cancelQueries({ queryKey: rQKey })
      if (!old) {
        return null
      }
      queryClient.setQueryData(rQKey, {
        ...old,
        autGrRecommendationState: autRecommendationAdapter.updateOne(
          old.autGrRecommendationState,
          {
            id,
            changes: {
              viewedAt: new Date().toISOString()
            }
          }
        )
      })
      return { rQKey, old }
    },
    onError: (err, data, context) => {
      if (!context) {
        return
      }
      const { rQKey, old } = context
      queryClient.setQueryData(rQKey, old)
    },
    ...options
  })

export const useToggleFavoredAutRecommendationByIdM = (options) =>
  useMutation({
    mutationFn: ({ id }) =>
      automatedRecommendationGroupRepository.toggleFavoredRecommendationById(
        id
      ),
    onMutate: async ({ id }) => {
      const rQKey = keys.feed()
      const old = queryClient.getQueryData(rQKey)
      await queryClient.cancelQueries({ queryKey: rQKey })
      if (!old) {
        return null
      }
      queryClient.setQueryData(rQKey, {
        ...old,
        autGrRecommendationState: autRecommendationAdapter.updateOne(
          old.autGrRecommendationState,
          {
            id,
            changes: {
              favoredAt: autRecommendationSelectors.selectById(old, id)
                .favoredAt
                ? null
                : new Date().toISOString()
            }
          }
        )
      })
      return { rQKey, old }
    },
    onError: (err, data, context) => {
      if (!context) {
        return
      }
      const { rQKey, old } = context
      queryClient.setQueryData(rQKey, old)
    },
    ...options
  })

export const concGroupSelectors = concGroupAdapter.getSelectors(
  ({ medConcStgGroupState }) => medConcStgGroupState
)

export const concRecommendationSelectors =
  concRecommendationAdapter.getSelectors(
    ({ medConcStgGrRecommendationState }) => medConcStgGrRecommendationState
  )

export const autGroupSelectors = autGroupAdapter.getSelectors(
  ({ automatedGroupState }) => automatedGroupState
)

export const autRecommendationSelectors = autRecommendationAdapter.getSelectors(
  ({ autGrRecommendationState }) => autGrRecommendationState
)

export const articleSelectors = articleAdapter.getSelectors(
  ({ articleState }) => articleState
)

export const selectConcRecommendationsByGroupId = createSelector(
  [concRecommendationSelectors.selectAll, (state, groupId) => groupId],
  (recommendations, groupId) =>
    recommendations.filter((item) => item.groupId === groupId),
  {
    memoize: weakMapMemoize,
    argsMemoize: weakMapMemoize
  }
)

export const selectConcRecommendationsByGroupIdMap = createSelector(
  [concRecommendationSelectors.selectAll],
  (recommendations) => groupBy(recommendations, 'groupId')
)

export const selectConcRecommendationIdsByGroupIdMap = createSelector(
  selectConcRecommendationsByGroupIdMap,
  (recommendationsByGroupId) =>
    transform(
      recommendationsByGroupId,
      (acc, value, key) => (acc[key] = map(value, 'id'))
    )
)

export const selectAutRecommendationsByGroupId = createSelector(
  [autRecommendationSelectors.selectAll, (state, groupId) => groupId],
  (recommendations, groupId) =>
    recommendations.filter((item) => item.groupId === groupId),
  {
    memoize: weakMapMemoize,
    argsMemoize: weakMapMemoize
  }
)

export const selectAutRecommendationIdsByGroupId = createSelector(
  selectAutRecommendationsByGroupId,
  (recommendations) => map(recommendations, 'id'),
  {
    memoize: weakMapMemoize,
    argsMemoize: weakMapMemoize
  }
)

export const selectAutRecommendationsByGroupIdMap = createSelector(
  autRecommendationSelectors.selectAll,
  (recommendations) => groupBy(recommendations, 'groupId')
)

export const selectAutRecommendationIdsByGroupIdMap = createSelector(
  selectAutRecommendationsByGroupIdMap,
  (recommendationsByGroupId) =>
    transform(
      recommendationsByGroupId,
      (acc, value, key) => (acc[key] = map(value, 'id'))
    )
)

export const updateArticle = (id, changes) => {
  const key = keys.feed()
  const data = queryClient.getQueryData(key)
  if (!data) {
    return
  }
  queryClient.setQueryData(key, {
    ...data,
    articleState: articleAdapter.updateOne(data.articleState, {
      id,
      changes
    })
  })
}

const recommendationFeedRQ = {
  keys
}

export default recommendationFeedRQ

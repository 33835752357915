import { get, inRange, isNil, uniq } from 'lodash-es'
// eslint-disable-next-line import/no-unresolved
import mime from 'mime/lite'

import bloodImage from '@assets/images/content/blood-cells.png'
import soundImage from '@assets/images/content/sound-wave.png'
import { IconBlood, IconMicroscope } from '@atoms/Icons'

const entityName = 'user_analyses'

const categoryEntityName = 'user_analyses_category'

export const uniqueDescriptorMap = {
  blood: 'blood',
  urine: 'urine',
  ultrasound: 'ultrasound'
}

const imageByUD = {
  blood: bloodImage,
  ultrasound: soundImage
}

const bgColorByUD = {
  blood: 'green',
  ultrasound: 'peach'
}

const iconsByUD = {
  blood: (
    <>
      <img src={IconBlood} alt="blooddrop" style={{ height: '18px' }} />
      <img src={IconMicroscope} alt="microscope" style={{ height: '18px' }} />
    </>
  ),
  ultrasound: null,
  urine: <img src={IconMicroscope} alt="microscope" />
}

export const photoFileExts = [
  '.png',
  '.jpeg',
  '.jpg',
  '.svg',
  '.tiff',
  '.heic',
  '.webp'
]

export const docFileExts = ['.doc', '.docx', '.pdf']

export const fileExts = [...docFileExts, ...photoFileExts]

export const docFileMimes = uniq(docFileExts.map(mime.getType))

export const photoFileMimes = uniq(photoFileExts.map(mime.getType))

export const photoInputAcceptStr = photoFileMimes.join(',')

export const fileMimes = uniq(fileExts.map(mime.getType))

export const fileMimeSet = new Set(fileMimes)

export const fileMaxSize = 10 * 1000 * 1000 //10MB

export const createCategoryDateUrl = (category, takenDate) =>
  `/account/analyses/category/${category}/taken-date/${takenDate}`

export const createAccountRecommendedUrl = (id) =>
  `/account/analyses/recommended/${id}`

export const createOverviewUrl = (id) => `/home/analyses/${id}`

export const normRangeMap = {
  optimum: 'optimum',
  norm: 'norm',
  nin: 'nin',
  'nin-g': 'nin-g',
  'nin-l': 'nin-l'
}

export const getValueNormRange = (
  value,
  { norm = null, optimum = null },
  { isPrecise = false } = {}
) => {
  if (isNil(value)) {
    return normRangeMap.nin
  }
  if (!norm && !optimum) {
    return normRangeMap.optimum
  }
  if (optimum) {
    const { valueFrom, valueTo } = optimum
    if (
      !inRange(
        value,
        valueFrom ?? -Infinity,
        !isNil(valueTo) ? valueTo + 0.001 : +Infinity
      )
    ) {
      if (!norm) {
        if (isPrecise) {
          if (value > (valueTo ?? +Infinity)) {
            return normRangeMap['nin-g']
          } else {
            return normRangeMap['nin-l']
          }
        }
        return normRangeMap.nin
      }
    } else {
      return normRangeMap.optimum
    }
  }
  if (norm) {
    const { valueFrom, valueTo } = norm
    if (
      !inRange(
        value,
        valueFrom ?? -Infinity,
        !isNil(valueTo) ? valueTo + 0.001 : +Infinity
      )
    ) {
      if (isPrecise) {
        if (value > (valueTo ?? +Infinity)) {
          return normRangeMap['nin-g']
        } else {
          return normRangeMap['nin-l']
        }
      }
      return normRangeMap.nin
    }
    return normRangeMap.norm
  }
}
export default {
  entityName,
  categoryEntityName,
  getImageByUD: (ud) => get(imageByUD, ud, imageByUD.blood),
  bgColorByUD: (ud) => get(bgColorByUD, ud, bgColorByUD.blood),
  getIconsByUD: (ud) => get(iconsByUD, ud, null),
  createCategoryDateUrl,
  createAccountRecommendationUrl: createAccountRecommendedUrl,
  createSuggestionsRecommnedationUrl: createOverviewUrl,
  getValueNormRange
}

import { API_URL } from '@common/env'
import { formatISODate } from '@common/utils'
import restApi from '@infrastructure/api/restApi'
import { camelizeRespDataKeys } from '@infrastructure/api/utils'

export default {
  getRecomendedDetailById: (id) =>
    restApi.get(`/analyses/${id}/recommended`).then(camelizeRespDataKeys),
  getPdfUrl: (id) => `${API_URL}/analyses/${id}/pdf`,
  uploadDocument: async (analysesId, file, onUploadProgress) => {
    const formData = new FormData()
    formData.append('file', file)
    return restApi
      .post(`/analyses/${analysesId}/documents`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress
      })
      .then(({ data }) => {
        return data
      })
  },
  getDocumentsById: (analysesId) =>
    restApi.get(`analyses/${analysesId}/documents`).then(camelizeRespDataKeys),
  setIsAllDocumentsUploadedById: (id, is) =>
    restApi.patch(`analyses/${id}/all-documents-uploaded`, { is }),
  deleteDocument: (analysesId, fileName) =>
    restApi
      .delete(`analyses/${analysesId}/documents/${fileName}`)
      .then(({ data }) => {
        return data
      }),
  getList: () => restApi.get('/analyses').then(camelizeRespDataKeys),
  getTakenAnalysesCategoryDetail: (categoryUD, takenDate) =>
    restApi
      .get(
        `/analyses/category/${categoryUD}/taken-date/${formatISODate(takenDate)}`
      )
      .then(camelizeRespDataKeys)
}

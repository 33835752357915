import { castArray } from 'lodash-es'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

import { selectAuthType } from '@features/auth/redux/authSlice'
import { authTypeMap } from '@features/auth/service/constants'

import { getHomePathByAuthType } from '../service/navigationHelpers'

const defaultAuthType = [authTypeMap.user, authTypeMap.admin]

export default function NavigateHomeIfNotMatchAuthType({
  children,
  authType = defaultAuthType
} = {}) {
  const actualAuthType = useSelector(selectAuthType)
  const isMatch = useMemo(
    () => castArray(authType).includes(actualAuthType),
    [actualAuthType, authType]
  )
  return !isMatch ? (
    <Navigate to={getHomePathByAuthType(actualAuthType)} replace />
  ) : (
    children
  )
}

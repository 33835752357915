import classNames from 'classnames'

import './style.scss'

export default function Heading({
  className,
  level = 3,
  size = 24,
  children,
  ...rest
}) {
  const Tag = `h${level}`
  return (
    <Tag
      className={classNames(className, 'heading-2', `heading-2--${size}`)}
      {...rest}
    >
      {children}
    </Tag>
  )
}

import ListsPageSkeleton from '@atoms/ListsPageSkeleton'
import { createLazyElement } from '@common/utils'
import accountRoute from '@features/account/navigation/route'
import overviewRoute from '@features/overview/navigation/route'

import { baseSegment, orderPath, purchasePath, purchasedPath } from '.'

const userServicePackagePage = createLazyElement(
  () => import('../components/UserServicePackagePage'),
  <ListsPageSkeleton />
)

const purchaseServicePackagePage = createLazyElement(
  () => import('../components/PurchaseServicePackagePage'),
  <ListsPageSkeleton />
)

overviewRoute.children.push({
  path: baseSegment,
  children: [
    {
      path: purchasePath,
      handle: {
        breadcrumbTrPath:
          'servicePackageSection.servicePackage.purchaseBreadcrumb'
      },
      element: purchaseServicePackagePage
    }
  ]
})

accountRoute.children.push({
  path: baseSegment,
  handle: {
    breadcrumbTrPath: 'servicePackageSection.userServicePackage.breadcrumb'
  },
  children: [
    {
      path: purchasedPath,
      element: userServicePackagePage
    },
    {
      path: purchasePath,
      handle: {
        breadcrumbTrPath:
          'servicePackageSection.servicePackage.purchaseBreadcrumb'
      },
      element: purchaseServicePackagePage
    },
    {
      path: orderPath,
      handle: {
        breadcrumbTrPath: 'servicePackageSection.servicePackageOrder.breadcrumb'
      },
      element: createLazyElement(
        () => import('../components/ServicePackageOrderPage')
      )
    },
    {
      index: true,
      element: createLazyElement(
        () => import('../components/UserServicePackageSectionPage'),
        <ListsPageSkeleton />
      )
    }
  ]
})

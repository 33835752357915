import { CheckmarkIcon } from '@atoms/Icons2'
import classNames from 'classnames'

const AnalysesDocumentCardBody = ({
  className,
  checked = false,
  children,
  onClick
}) => (
  <div
    onClick={onClick}
    className={classNames(
      'recommendations-item-link account-tests-download u-a-d-card',
      {
        'u-a-d-card--checked': checked
      },
      className
    )}
  >
    {checked && (
      <div className="u-a-d-card__check">
        <CheckmarkIcon />
      </div>
    )}
    <div className="recommendations-item-inner">
      <div className="recommendations-item-cover background-white account-tests-download-cover">
        {children}
      </div>
    </div>
  </div>
)

export default AnalysesDocumentCardBody

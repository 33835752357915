import classNames from 'classnames'
import { forwardRef } from 'react'

import { FillingSkeleton } from '@atoms/Skeleton'
import arrow from '../../assets/images/icons/arrow-right.svg'
import scss from './index.module.scss'

function getButtonIcon(variant) {
  return variant === 'primary' ? (
    <span className="content-button-arrow">
      <img src={arrow} alt="arrow right" />
    </span>
  ) : (
    ''
  )
}

export default forwardRef(function Button(
  {
    As = 'button',
    className,
    children,
    variant = 'primary',
    color = 'text',
    isDisabled = false,
    isLoading = false,
    isPending = false,
    ...restProps
  },
  ref
) {
  return (
    <As
      ref={ref}
      {...restProps}
      disabled={isDisabled || isLoading || isPending}
      className={classNames(
        scss.container,
        scss[variant],
        'button-primary',
        variant === 'primary' ? 'content-button' : 'content-button-secondary',
        scss[color],
        className
      )}
    >
      {isPending && <FillingSkeleton className={scss.pendingSkeleton} />}
      {children}
      {getButtonIcon(variant)}
    </As>
  )
})

import { useEffect } from 'react'

import { selectIsLoggedIn } from '@features/auth/redux/authSlice'
import { useSelector } from 'react-redux'
import apiSocket from './apiSocket'

const useApiSocket = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn)
  useEffect(() => {
    if (!isLoggedIn) {
      apiSocket.disconnect()
      return
    }
    apiSocket.connect()
  }, [isLoggedIn])
  useEffect(() => {
    return () => {
      apiSocket.disconnect()
    }
  }, [])
  return apiSocket
}

export default useApiSocket

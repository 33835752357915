import { Filesystem } from '@capacitor/filesystem'
import { isNativePlatform } from '@common/constants'
import { noop } from 'lodash-es'
import { useEffect } from 'react'

const permissions = async () => {
  let status = (await Filesystem.checkPermissions()).publicStorage
  if (status === 'granted') {
    return
  }
  if (status === 'prompt' || status === 'prompt-with-rationale') {
    status = (await Filesystem.requestPermissions()).publicStorage
  }
  if (status !== 'granted') {
    throw new Error('User denied permissions!')
  }
}

export const useFilesystem = isNativePlatform
  ? () => {
      useEffect(() => {
        permissions()
      }, [])
    }
  : noop

import classNames from 'classnames'

import logo from '@assets/images/biobetter-logo-blue.svg'

import './style.scss'

const Loader = ({ className, size = 'sm', ...rest }) => {
  return (
    <div
      className={classNames('loader', className, size && `loader--${size}`)}
      {...rest}
    >
      <img
        className="loader__img animate__animated animate__bounceIn animate__infinite"
        src={logo}
        alt="Biobetter"
      />
    </div>
  )
}

export default Loader

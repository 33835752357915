import classNames from 'classnames'
import { forwardRef } from 'react'

import { IconListBullet } from '@atoms/Icons2'

export default forwardRef(function RecommendationFeedContentsButton(
  { className, hasNew, As = 'button', ...rest },
  ref
) {
  return (
    <As
      {...rest}
      ref={ref}
      className={classNames(className, 'rec-feed-contents-btn')}
    >
      {hasNew && (
        <div className="rec-feed-contents-btn__badge popup-header-badge show" />
      )}
      <IconListBullet {...rest} />
    </As>
  )
})

import classNames from 'classnames'

export const Section = ({ children, As = 'section', className, ...rest }) => {
  return (
    <As className={classNames('section', className)} {...rest}>
      {children}
    </As>
  )
}

export const SectionWrapper = ({
  children,
  As = 'div',
  className,
  ...rest
}) => {
  return (
    <As className={classNames('section-wrapper', className)} {...rest}>
      {children}
    </As>
  )
}

export function SectionTitle({ As = 'h2', className, children, ...rest }) {
  return (
    <As className={classNames(className, 'section-title')} {...rest}>
      {children}
    </As>
  )
}

import classNames from 'classnames'

import Heading from '@atoms/Heading'
import Text from '@atoms/Text'

import './style.scss'

export const CardPicture = ({
  className,
  color = 'secondary-white-100',
  children,
  ...rest
}) => {
  return (
    <div
      className={classNames(className, `bg-${color}`, 'card-2__picture')}
      {...rest}
    >
      {children}
    </div>
  )
}

export const CardContent = ({ className, As = 'div', children, ...rest }) => {
  return (
    <As {...rest} className={classNames(className, 'card-2__content')}>
      {children}
    </As>
  )
}

export const CardTagline = ({ className, children, As = 'p', ...rest }) => {
  return (
    <Text
      As={As}
      size={12}
      saturation={75}
      className={classNames(className, 'card-2__tagline')}
      {...rest}
    >
      {children}
    </Text>
  )
}

export const CardHeading = ({ className, children, level = 3, ...rest }) => {
  return (
    <Heading
      level={level}
      size={20}
      className={classNames(className, 'card-2__heading')}
      {...rest}
    >
      {children}
    </Heading>
  )
}

export const CardDescription = ({ className, As = 'p', children, ...rest }) => {
  return (
    <Text
      As={As}
      size={14}
      saturation={100}
      className={classNames(className, 'card-2__description')}
      {...rest}
    >
      {children}
    </Text>
  )
}

export const Card = ({
  className,
  As = 'div',
  picture,
  tagline,
  heading,
  description,
  ...rest
}) => {
  return (
    <As {...rest} className={classNames(className, 'card-2')}>
      {picture}
      <CardContent>
        {tagline}
        {heading}
        {description}
      </CardContent>
    </As>
  )
}

import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import overviewNavigationHelpers from '@features/overview/navigation'
import userRQ from '@features/user/reactQuery'

import { supportChatRedux } from '@features/supportChat/redux'
import { useSelector } from 'react-redux'
import {
  IconTabbarAccount,
  IconTabbarAccountSelected,
  IconTabbarBook,
  IconTabbarBookSelected,
  IconTabbarChecked,
  IconTabbarCheckedSelected,
  IconTabbarHelp,
  IconTabbarHelpSelected,
  IconTabbarHome,
  IconTabbarHomeSelected
} from '../../../atoms/Icons'

const useNavItems = () => {
  const [t] = useTranslation()
  const { data: { hasNewRecommendations, hasNewOverviewData } = {} } =
    userRQ.useDetailQ()
  const { hasNewMessages } = useSelector(supportChatRedux.slice.selectSlice)
  return useMemo(
    () => [
      {
        to: overviewNavigationHelpers.basePath,
        icon: IconTabbarHome,
        iconActive: IconTabbarHomeSelected,
        title: t('nav.overview'),
        hasNew: hasNewOverviewData
      },
      {
        to: '/recommendation-feed',
        icon: IconTabbarBook,
        iconActive: IconTabbarBookSelected,
        title: t('nav.recommendationFeed'),
        hasNew: hasNewRecommendations
      },
      {
        to: '/my-day',
        icon: IconTabbarChecked,
        iconActive: IconTabbarCheckedSelected,
        title: t('nav.myDay')
      },
      {
        to: '/support',
        icon: IconTabbarHelp,
        iconActive: IconTabbarHelpSelected,
        title: t('nav.help'),
        hasNew: hasNewMessages
      },
      {
        to: '/account',
        icon: IconTabbarAccount,
        iconActive: IconTabbarAccountSelected,
        title: t('nav.account')
      }
    ],
    [t, hasNewOverviewData, hasNewRecommendations]
  )
}

export default useNavItems

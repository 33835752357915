import ListsPageSkeleton from '@atoms/ListsPageSkeleton'
import { createLazyElement } from '@common/utils'
import rootRoute from '@features/rootNavigation/navigation/route'

import { baseSegment, surveyDetailSubPath } from '.'
import OverviewPage from '../components/OverviewPage'

const overviewRoute = {
  path: baseSegment,
  handle: {
    breadcrumbTrPath: 'overview.OverviewPage.breadcrumb'
  },
  children: [
    {
      path: surveyDetailSubPath,
      element: createLazyElement(
        () => import('@features/userSurvey/components/UserSurveyPage'),
        <ListsPageSkeleton />
      ),
      handle: {
        breadcrumbTrPath: 'userSurvey.UserSurveyPage.breadcrumb'
      }
    },
    {
      index: true,
      element: <OverviewPage />
    }
  ]
}

rootRoute.children.push(overviewRoute)

export default overviewRoute

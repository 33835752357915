import restApi from '@infrastructure/api/restApi'

export const name = 'automatedRecommendationGroup'

export const recommendationName = 'autRecGrRecommendation'

export const viewRecommendationById = (id) =>
  restApi.patch(`/automated-recommendation-groups/recommendations/${id}/view`)

export const toggleFavoredRecommendationById = (id) =>
  restApi.patch(
    `/automated-recommendation-groups/recommendations/${id}/toggle-favored`
  )

const automatedRecommendationGroupRepository = {
  viewRecommendationById,
  toggleFavoredRecommendationById
}

export default automatedRecommendationGroupRepository

import toast from '@infrastructure/toast/toast'

import userSurveyHelpers from '../service/userSurveyHelpers'

export default {
  entity: userSurveyHelpers.entityName,
  config: [
    {
      changements: ['created'],
      listener: () => {
        toast.info({ title: 'New survey suggestion' })
      }
    }
  ]
}

import store from '@features/redux/store'
import { createSlice } from '@reduxjs/toolkit'
import { merge } from 'lodash-es'
import { name } from '../repository'

const initialState = {
  pendingMessage: null,
  hasNewMessages: false
}

export const slice = createSlice({
  initialState,
  name,
  reducers: {
    patchPendingMessage: (state, action) => {
      if (!state.pendingMessage) {
        state.pendingMessage = {}
      }
      merge(state.pendingMessage, action.payload)
    },
    setHasNewMessages: (state, action) => {
      state.hasNewMessages = action.payload
    },
    deletePendingMessage: (state) => {
      state.pendingMessage = null
    }
  }
})

store.reducerManager.add(slice.reducerPath, slice.reducer)

export const supportChatRedux = {
  slice
}

import classNames from 'classnames'
import { compact, isNil, upperFirst } from 'lodash-es'
import {
  cloneElement,
  createContext,
  forwardRef,
  isValidElement,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'

import {
  FloatingArrow,
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  arrow,
  autoUpdate,
  flip,
  offset, // shift,
  useClick,
  useDismiss,
  useFloating,
  useId,
  useInteractions,
  useMergeRefs,
  useRole
} from '@floating-ui/react'

import './style.scss'

export function usePopupState({
  initialOpened = false,
  opened: controlledOpened,
  placement = 'bottom',
  onOpenedChange
}) {
  const [uncontrolledOpened, setUncontrolledOpened] = useState(initialOpened)
  const [labelId, setLabelId] = useState()
  const [descriptionId, setDescriptionId] = useState()
  const arrowRef = useRef(null)
  const opened = controlledOpened ?? uncontrolledOpened
  const setOpened = useCallback(
    (opened) => {
      if (isNil(controlledOpened)) {
        setUncontrolledOpened(opened)
        onOpenedChange?.(opened)
      }
      onOpenedChange?.(opened)
    },
    [controlledOpened]
  )
  const data = useFloating({
    placement,
    open: opened,
    onOpenChange: setOpened,
    whileElementsMounted: autoUpdate,
    middleware: compact([
      flip({
        crossAxis: placement.includes('-'),
        fallbackAxisSideDirection: 'end',
        padding: {
          top: 12,
          right: 8,
          left: 8,
          bottom: 12
        }
      }),
      offset({ mainAxis: 12, alignmentAxis: -8 }),
      arrow({
        element: arrowRef
      })
    ])
  })
  const { context } = data
  const click = useClick(context, {
    enabled: controlledOpened == null
  })
  const dismiss = useDismiss(context)
  const role = useRole(context, { role: 'dialog' })
  const interactions = useInteractions([click, dismiss, role])
  return useMemo(
    () => ({
      arrowRef,
      setOpened,
      ...interactions,
      ...data,
      labelId,
      descriptionId,
      setLabelId,
      setDescriptionId
    }),
    [setOpened, interactions, data, labelId, descriptionId]
  )
}

const PopupContext = createContext(null)

export const usePopupContext = () => {
  const context = useContext(PopupContext)

  if (context == null) {
    throw new Error('Popup components must be wrapped in <PopupProvider />')
  }

  return context
}

export const usePopupReference = () => {
  const {
    refs: { setReference: ref },
    getReferenceProps: getProps
  } = usePopupContext()
  return useMemo(() => ({ ref, getProps }), [ref, getProps])
}

export const usePopupFloating = () => {
  const {
    context,
    refs: { setFloating: ref },
    floatingStyles: styles,
    labelId,
    descriptionId,
    getFloatingProps: getProps
  } = usePopupContext()
  return useMemo(
    () => ({
      context,
      ref,
      getProps,
      styles,
      labelId,
      descriptionId
    }),
    [context, ref, getProps, styles, labelId, descriptionId]
  )
}

export const usePopupHeading = () => {
  const { setLabelId } = usePopupContext()
  const id = useId()
  useLayoutEffect(() => {
    setLabelId(id)
    return () => setLabelId(undefined)
  }, [id, setLabelId])
  return useMemo(
    () => ({
      id
    }),
    [id]
  )
}

export const usePopupDescription = () => {
  const { setDescriptionId } = usePopupContext()
  const id = useId()
  useLayoutEffect(() => {
    setDescriptionId(id)
    return () => setDescriptionId(undefined)
  }, [id, setDescriptionId])
  return useMemo(
    () => ({
      id
    }),
    [id]
  )
}

export const usePopupClose = () => {
  const { setOpened } = usePopupContext()
  return useMemo(() => ({ close: () => setOpened(false) }), [setOpened])
}

export function PopupProvider({ children, ...restOptions }) {
  const state = usePopupState(restOptions)
  return <PopupContext.Provider value={state}>{children}</PopupContext.Provider>
}

export const PopupTrigger = forwardRef(function PopupTrigger(
  { children, custom = false, ...props },
  propRef
) {
  const {
    refs,
    getReferenceProps,
    context: { open }
  } = usePopupContext()
  const childrenRef = children.ref
  const ref = useMergeRefs([refs.setReference, propRef, childrenRef])
  // `asChild` allows the user to pass any element as the anchor
  if (custom && isValidElement(children)) {
    return cloneElement(
      children,
      getReferenceProps({
        ref,
        ...props,
        ...children.props,
        'data-state': open ? 'open' : 'closed'
      })
    )
  }
  return (
    <button ref={ref} type="button" {...getReferenceProps(props)}>
      {children}
    </button>
  )
})

export const PopupArrow = ({ className, ...rest }) => {
  const { context, arrowRef } = usePopupContext()
  return (
    <FloatingArrow
      context={context}
      ref={arrowRef}
      className={classNames('popup-2__arrow', className)}
      {...rest}
    />
  )
}

export const PopupPortal = function PopupPortal({ children, ...rest }) {
  const {
    context: { open }
  } = usePopupContext()
  return <FloatingPortal {...rest}>{open ? children : null}</FloatingPortal>
}

export const PopupOverlay = forwardRef(function PopupPortal(
  { children, lockScroll = true, ...rest },
  ref
) {
  return (
    <FloatingOverlay
      ref={ref}
      className="popup-2__overlay"
      lockScroll={lockScroll}
      {...rest}
    >
      {children}
    </FloatingOverlay>
  )
})

export const PopupHeading = forwardRef(function PopupHeading(
  { as: As = 'h3', className, ...props },
  ref
) {
  const { id } = usePopupHeading()
  return (
    <As
      className={classNames(className, 'popup-2__heading')}
      {...props}
      ref={ref}
      id={id}
    >
      {props.children}
    </As>
  )
})

export const PopupDescription = forwardRef(function PopupDescription(
  { as: As = 'p', className, ...props },
  ref
) {
  const { id } = usePopupDescription()
  return (
    <As
      className={classNames(className, 'popup-2__description')}
      {...props}
      ref={ref}
      id={id}
    />
  )
})

export const PopupBody = forwardRef(function PopupBody(
  { className, ...props },
  ref
) {
  return (
    <div
      className={classNames(className, 'popup-2__body popup-2__container')}
      ref={ref}
      {...props}
    />
  )
})

export const PopupClose = forwardRef(function PopupClose(
  { className, ...props },
  ref
) {
  const { setOpened } = usePopupContext()
  const [t] = useTranslation()
  return (
    <button
      type="button"
      ref={ref}
      className={classNames(className, 'popup-2__close-btn')}
      {...props}
      onClick={(event) => {
        props.onClick?.(event)
        setOpened(false)
      }}
    >
      {upperFirst(t('common.ok'))}
    </button>
  )
})

export const PopupFloating = forwardRef(function PopupFloating(
  { className, children, style, modal = false, ...rest },
  controlledRef
) {
  const { context, ref, getProps, styles, labelId, descriptionId } =
    usePopupFloating()
  const mergedRef = useMergeRefs([ref, controlledRef])
  return (
    <FloatingFocusManager context={context} modal={modal}>
      <div
        ref={mergedRef}
        className={classNames('popup-2', className)}
        style={{ ...styles, ...style }}
        aria-labelledby={labelId}
        aria-describedby={descriptionId}
        {...getProps(rest)}
      >
        {children}
        <PopupArrow width={12} height={9} fill="#1D1E2C" />
      </div>
    </FloatingFocusManager>
  )
})

export const Popup = forwardRef(function Popup(
  { heading, description, ...props },
  controlledRef
) {
  return (
    <PopupPortal>
      <PopupFloating ref={controlledRef} {...props}>
        <PopupBody>
          {typeof heading === 'string' ? (
            <PopupHeading>{heading}</PopupHeading>
          ) : (
            heading
          )}
          {typeof description === 'string' ? (
            <PopupDescription>{description}</PopupDescription>
          ) : (
            description
          )}
        </PopupBody>
        <div className="popup-2__actions">
          <PopupClose />
        </div>
      </PopupFloating>
    </PopupPortal>
  )
})

import { useCallback } from 'react'
import urlJoin from 'url-join'

import accountNavigation from '@features/account/navigation'
import overviewNavigation from '@features/overview/navigation'

export const baseSegment = 'service-packages'

export const purchaseSegment = 'purchase'

export const purchasedSegment = 'purchased'

export const ordersSegment = 'orders'

export const detailSegment = ':id'

export const purchasePath = urlJoin(purchaseSegment, detailSegment)

export const purchasedPath = urlJoin(purchasedSegment, detailSegment)

export const orderPath = urlJoin(ordersSegment, detailSegment)

export const tabHashMap = {
  current: '#current',
  paymentHistory: '#payment-history'
}

export const paymentHistoryPath = urlJoin(
  accountNavigation.basePath,
  baseSegment,
  tabHashMap.paymentHistory
)

export const useMakePurchasePath = () => {
  const isUnderOverview = overviewNavigation.useIsUnderOverview()
  return useCallback(
    (servicePackageId) =>
      urlJoin(
        isUnderOverview
          ? overviewNavigation.basePath
          : accountNavigation.basePath,
        baseSegment,
        purchaseSegment,
        servicePackageId
      ),
    [isUnderOverview]
  )
}

export const makeUserPackagePath = (userServicePackageId) =>
  urlJoin(
    accountNavigation.basePath,
    baseSegment,
    purchasedSegment,
    userServicePackageId
  )

const servicePackageNavigation = {
  baseSegment,
  paymentHistoryPath
}

export default servicePackageNavigation

import cn from 'classnames'
import Modal from 'react-modal'

import { isNativePlatform } from '@common/constants'
import { useEffect } from 'react'
import { IconCancel } from './Icons'

Modal.setAppElement('#root')

const BBModal = ({ className, children, headerChildren, isOpen, onClose }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
      if (!isNativePlatform) {
        document.body.style.paddingRight = '15px'
      }
    }
    return () => {
      document.body.style.overflow = 'unset'
      if (!isNativePlatform) {
        document.body.style.paddingRight = '0px'
      }
    }
  }, [isOpen])
  return (
    <Modal
      overlayClassName="popup-block"
      className={cn('popup-block-background', className)}
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Example Modal"
    >
      <div className="popup-close">
        <button className="popup-close-button" onClick={onClose}>
          <img src={IconCancel} alt="cancel" />
        </button>
      </div>
      {headerChildren}
      <div className="popup-content">
        <div className="popup-content-block">{children}</div>
      </div>
    </Modal>
  )
}

export default BBModal

import { createLazyElement } from '@common/utils'
import { authTypeMap } from '@features/auth/service/constants'

import {
  introPath,
  passwordSubPath,
  registrationPath
} from './introNavigationHelpers'

export default {
  path: introPath,
  handle: {
    breadcrumbTrPath: 'intro.IntroPage.breadcrumb'
  },
  children: [
    {
      path: passwordSubPath,
      element: createLazyElement(
        () => import('@features/Intro/components/IntroPasswordPage')
      ),
      handle: {
        breadcrumbTrPath: 'intro.IntroPasswordPage.breadcrumb',
        authType: authTypeMap.regpwd
      }
    },
    {
      path: registrationPath,
      element: createLazyElement(
        () => import('@features/Intro/components/IntroRegistrationPage')
      ),
      handle: {
        breadcrumbTrPath: 'intro.IntroRegistrationPage.breadcrumb',
        authType: authTypeMap.guest
      }
    },
    {
      path: 'survey/*',
      element: createLazyElement(
        () => import('@features/Intro/components/IntroSurveyPage')
      ),
      handle: {
        authType: authTypeMap.guest
      }
    }
  ]
}

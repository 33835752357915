import { property } from 'lodash-es'

import { getDataProp } from '@common/utils'
import queryClient from '@infrastructure/reactQuery/queryClient'
import { useMutation, useQuery } from '@tanstack/react-query'

import {
  createOrder,
  getAcquiringsHistory,
  getCurrentUserPackages,
  getFirstAcquirablePreview,
  getOrderById,
  getPackageById,
  getPackagePromocode,
  getUserPackageById
} from '../repository'

export const keys = {
  all: ['servicePackage'],
  firstAcquirablePreview: () => [...keys.all, 'firstAcquirablePreview'],
  userPackages: () => [...keys.all, 'userPackages'],
  currentUserPackages: () => [...keys.userPackages(), 'current'],
  userPackage: (id) => [...keys.userPackages(), id],
  package: (id) => [...keys.all, 'packages', id],
  packagePromocode: (id, code) => [
    ...keys.all,
    'packagePromocodes',
    { id, code }
  ],
  order: (id) => [...keys.all, 'orders', id],
  acquiringsHistory: () => [...keys.all, 'acquiringsHistory']
}

const getProp1 = property('data.servicePackage')
export const usePackageQ = (id, options) =>
  useQuery({
    queryKey: keys.package(id),
    queryFn: () => getPackageById(id).then(getProp1),
    ...options
  })

const getProp2 = property('data.userServicePackage')
export const useUserPackageQ = (id, options) =>
  useQuery({
    queryKey: keys.userPackage(id),
    queryFn: () => getUserPackageById(id).then(getProp2),
    ...options
  })

const getProp3 = property('data.servicePackageOrder')
export const useOrderQ = (id, options) =>
  useQuery({
    queryKey: keys.order(id),
    queryFn: () => getOrderById(id).then(getProp3),
    ...options
  })

export const useAcquringsHistoryQ = (options) =>
  useQuery({
    queryKey: keys.acquiringsHistory(),
    queryFn: () => getAcquiringsHistory().then(getDataProp),
    ...options
  })

export const prefetchAcquringsHistoryQ = (options) =>
  queryClient.prefetchQuery({
    queryKey: keys.acquiringsHistory(),
    queryFn: () => getAcquiringsHistory().then(getDataProp),
    ...options
  })

const getProp5 = property('data.servicePackageOrderAcquiring')

export const useCreateOrderM = (options) =>
  useMutation({
    mutationFn: ({ data }) => createOrder(data).then(getProp5),
    ...options
  })

const getProp6 = property('data.userServicePackages')

export const useCurrentUserPackagesQ = (options) =>
  useQuery({
    queryKey: keys.currentUserPackages(),
    queryFn: () => getCurrentUserPackages().then(getProp6),
    ...options
  })

export const prefetchCurrentUserPackagesQ = (options) =>
  queryClient.prefetchQuery({
    queryKey: keys.currentUserPackages(),
    queryFn: () => getCurrentUserPackages().then(getProp6),
    ...options
  })

const getProp7 = property('data.promocode')

export const usePackagePromocodeQ = (id, code, options) =>
  useQuery({
    queryKey: keys.packagePromocode(id, code),
    queryFn: () => getPackagePromocode(id, code).then(getProp7),
    ...options
  })

export const useFirstAcquirablePreviewQ = (options) =>
  useQuery({
    queryKey: keys.firstAcquirablePreview(),
    queryFn: () => getFirstAcquirablePreview().then(getDataProp),
    ...options
  })

const servicePackageRQ = {
  useFirstAcquirablePreviewQ
}

export default servicePackageRQ

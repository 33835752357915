import { upperFirst } from 'lodash-es'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import {
  Card,
  CardDescription,
  CardHeading,
  CardPicture,
  CardTagline
} from '@atoms/Card'
import { IconAnalysesJar } from '@atoms/Icons2'
import NewBadge from '@atoms/NewBadge'

import { createOverviewUrl } from '../service/userAnalysesHelpers'

export default memo(function UserAnalysesCard({
  data: {
    id,
    category: { uniqueDescriptor } = {},
    viewedByCustomerAt,
    markerCount
  },
  ...rest
}) {
  const [t] = useTranslation()
  return (
    <Card
      {...rest}
      As={Link}
      className="unset"
      to={createOverviewUrl(id)}
      picture={
        <CardPicture style={{ backgroundColor: '#FFCDB2' }}>
          <IconAnalysesJar width={27} />
        </CardPicture>
      }
      tagline={
        <CardTagline>
          {t([
            `analysesSection.UserAnalysesCard.tagline.${uniqueDescriptor}`,
            'analysesSection.UserAnalysesCard.tagline.unspecific'
          ])}
        </CardTagline>
      }
      heading={
        <CardHeading level={2}>
          {upperFirst(
            t([
              `analysesSection.categoryName.${uniqueDescriptor}`,
              'analysesSection.categoryName.unspecific'
            ])
          )}
          {!viewedByCustomerAt && <NewBadge />}
        </CardHeading>
      }
      description={
        <CardDescription>
          {t(
            [
              `analysesSection.categoryMarkerNameCount.${uniqueDescriptor}`,
              'analysesSection.categoryMarkerNameCount.unspecific'
            ],
            {
              count: markerCount
            }
          )}
        </CardDescription>
      }
    />
  )
})

import urlJoin from 'url-join'

export const loginPath = '/login'

export const logoutPath = '/logout'

export const recoverPasswordPath = '/recover-password'

export const resetPasswordPath = '/reset-password/:token'

export const createResetPasswordPath = (token) =>
  urlJoin('reset-password', token)

import { formatISODate } from '@common/utils'
import restApi from '@infrastructure/api/restApi'
import { uniqueDescriptorMap } from '../service/userAnalysesHelpers'

export const getRecommendedAsPdfById = (id, options) =>
  restApi.get(`/analyses/${id}/pdf`, options)

export const makePdfFilename = (uniqueDescriptor, assignedAt) =>
  `Biobetter-recommended-${uniqueDescriptor === uniqueDescriptorMap.blood ? 'blood-analyses' : uniqueDescriptor === uniqueDescriptorMap.ultrasound ? 'ultrasound-diagnostics' : 'urine-analyses'}-from-${formatISODate(assignedAt)})
  )}.pdf`

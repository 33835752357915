import { EventEmitter } from 'eventemitter3'

import { customSnakecaseKeys } from '@common/utils'
import restApi from '@infrastructure/api/restApi'
import { camelizeRespDataKeys } from '@infrastructure/api/utils'
import queryClient from '@infrastructure/reactQuery/queryClient'

export const AUTH_EVENTS = {
  login: 'login',
  logout: 'logout',
  error: 'error',
  initialized: 'initialized'
}

function CreateAuthService() {
  const tokenExp = 14 * 60 * 1000
  const events = new EventEmitter()
  let isInitialized = false
  let isAuthFailed = false
  let isLoggedIn = false
  let isLoggingIn = false
  let authData = null
  let authError = null
  let tokenTimer = null
  let tokenExpTimestamp = null

  function getIsInitialized() {
    return isInitialized
  }

  function setIsInitialized(value) {
    isInitialized = value
  }

  function getIsLoggedIn() {
    return isLoggedIn
  }

  function setIsLoggedIn(value) {
    isLoggedIn = value
  }

  function getIsLoggingIn() {
    return isLoggingIn
  }

  function setIsLoggingIn(value) {
    isLoggingIn = value
  }

  function getAuthData() {
    return authData
  }

  function setAuthData(value) {
    authData = { ...authData, ...value }
  }

  function getAuthError() {
    return authError
  }

  function setAuthError(value) {
    authError = value
  }

  function getIsAuthFailed() {
    return isAuthFailed
  }

  function setIsAuthFailed(value) {
    isAuthFailed = value
  }

  function getTokenExpTimestamp() {
    return tokenExpTimestamp
  }

  function setTokenExpTimestamp(value) {
    tokenExpTimestamp = value
  }

  function clearTokenTimer() {
    if (tokenTimer) {
      clearTimeout(tokenTimer)
      tokenTimer = null
    }
    setTokenExpTimestamp(0)
  }

  function handleSuccessWithAuthData(response) {
    setAuthData(response.data)
    setIsLoggingIn(false)
    setIsLoggedIn(true)
    setIsAuthFailed(false)
    setAuthError(null)
    clearTokenTimer()

    setTokenExpTimestamp(Date.now() + tokenExp)
    tokenTimer = setTimeout(refreshToken, tokenExp)
    events.emit(AUTH_EVENTS.login, getAuthData())
  }

  function handleAuthError(error) {
    setAuthData(null)
    setIsLoggingIn(false)
    setIsLoggedIn(false)
    setIsAuthFailed(true)
    setAuthError(error)
    clearTokenTimer()
    events.emit(AUTH_EVENTS.error, error)
  }

  async function login(email, password) {
    if (getIsLoggingIn()) {
      return
    }
    setIsLoggingIn(true)
    try {
      const resp = await restApi
        .post('/auth/login', {
          email,
          password
        })
        .then(camelizeRespDataKeys)
      handleSuccessWithAuthData(resp)
      return resp
    } catch (e) {
      handleAuthError(e.response)
      return Promise.reject(e)
    }
  }

  async function logout() {
    try {
      return restApi.post('/auth/logout')
    } finally {
      setAuthData(null)
      events.emit(AUTH_EVENTS.logout)
    }
  }

  async function refreshToken() {
    if (getIsLoggingIn() || getIsAuthFailed()) {
      return
    }
    setIsLoggingIn(true)
    try {
      const resp = await restApi
        .post('/auth/refresh-token')
        .then(camelizeRespDataKeys)
      handleSuccessWithAuthData(resp)
      return resp
    } catch (e) {
      handleAuthError(e.response)
      return Promise.reject(e)
    }
  }

  async function register({ email, code, timeZone, answers, agreeToTCPP }) {
    try {
      const resp = await restApi
        .post(
          '/auth/register',
          customSnakecaseKeys({
            email,
            code,
            timeZone,
            answers,
            agreeToTCPP
          })
        )
        .then(camelizeRespDataKeys)
      handleSuccessWithAuthData(resp)
      return resp
    } catch (e) {
      // handleAuthError(e.response)
      return Promise.reject(e)
    }
  }

  async function sendVerificationToEmail({ email, locale }) {
    return restApi.post('/auth/verify-email', { email, locale })
  }

  async function createPassword(password) {
    try {
      const resp = await restApi.post('/auth/register-password', { password })
      return resp
    } catch (e) {
      // handleAuthError(e.response)
      return Promise.reject(e)
    }
  }

  async function recoverPassword(email, locale) {
    return restApi
      .post('/auth/recover-password', {
        email,
        locale
      })
      .then(({ data }) => data)
  }

  async function changePassword(oldPwd, newPwd) {
    return restApi.put('/auth/change-password', { oldPwd, newPwd })
  }

  async function resetPassword(token, password) {
    return restApi
      .post('/auth/reset-password', {
        token,
        password
      })
      .then(({ data }) => data)
  }

  async function initialize() {
    if (getIsInitialized()) {
      return
    }

    window?.addEventListener('focus', async () => {
      if (getTokenExpTimestamp() < Date.now()) {
        await refreshToken()
      }
    })

    restApi.interceptors.response.use(
      (response) => Promise.resolve(response),
      async (error) => {
        if (error.response?.status === 401) {
          if (getTokenExpTimestamp() < Date.now()) {
            await queryClient.cancelQueries()
            await refreshToken().catch(() => {})
          }
        }
        //  else if (error.response?.status === 403) {
        //   handleAuthError(error.response)
        // }
        return Promise.reject(error)
      }
    )
    try {
      await refreshToken().catch(() => {})
    } finally {
      setIsInitialized(true)
      events.emit(AUTH_EVENTS.initialized)
    }
  }

  return {
    addListener: events.addListener.bind(events),
    removeListener: events.removeListener.bind(events),
    listenerCount: events.listenerCount.bind(events),
    getAuthData,
    getAuthError,
    getIsLoggedIn,
    getIsLoggingIn,
    getIsInitialized,
    initialize,
    login,
    logout,
    refreshToken,
    register,
    sendVerificationToEmail,
    createPassword,
    recoverPassword,
    changePassword,
    resetPassword
  }
}

const authService = CreateAuthService()

export default authService

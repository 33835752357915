import { memo, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { IconBankCardSrc } from '@atoms/Icons2'
import servicePackageNavigation from '@features/servicePackage/navigation'

import Icon from '../../../atoms/Icon'
import { IconAngleRight } from '../../../atoms/Icons'
import { analysesSubPath, passedSurveysSubPath } from '../navigation'
import SelectLanguageModal from './ChangeLanguageModal'

export default memo(function AccountPage() {
  const [t] = useTranslation()
  const [selectLanguage, setSelectLanguage] = useState(false)
  const close = useCallback(() => {
    setSelectLanguage(false)
  }, [])
  const accountPages = useMemo(
    () => [
      {
        id: 'analyses',
        translationKey: 'account.AccountPage.menu.analyses',
        icon: 'Analyses',
        type: 'link',
        to: analysesSubPath
      },
      {
        id: 'surveys',
        translationKey: 'account.AccountPage.menu.surveys',
        icon: 'Survey',
        type: 'link',
        to: passedSurveysSubPath
      },
      {
        id: 'conclusions',
        translationKey: 'account.AccountPage.menu.conclusions',
        icon: 'Conclusion',
        type: 'link',
        to: 'conclusions'
      },
      {
        id: 'language',
        translationKey: 'account.AccountPage.menu.language',
        icon: 'LanguageAccount',
        type: 'action',
        action: () => {
          setSelectLanguage(true)
        }
      },
      {
        id: 'settings',
        translationKey: 'account.AccountPage.menu.settings',
        icon: 'Settings',
        type: 'link',
        to: 'settings'
      },
      {
        id: 'logout',
        translationKey: 'account.AccountPage.menu.logout',
        icon: 'Exit',
        type: 'link',
        to: '/logout'
      }
    ],
    [setSelectLanguage]
  )
  return (
    <section className="account-main">
      <div className="account-wrapper">
        <h2 className="account-title">{t('account.AccountPage.title')}</h2>
        <div className="account-menu">
          <Link className="unset" to={servicePackageNavigation.baseSegment}>
            <div className="account-menu-item">
              <div className="account-menu-item-icon">
                <img src={IconBankCardSrc} />
              </div>
              <div className="account-menu-item-block">
                <div className="account-menu-item-title">
                  {t('servicePackageSection.userServicePackage.breadcrumb')}
                </div>
                <div className="account-tests-list-right">
                  {/*<span className="account-tests-list-status show">Новое</span>*/}
                  <img
                    className="account-tests-list-arrow"
                    src={IconAngleRight}
                    alt="angle-right"
                  />
                </div>
              </div>
            </div>
          </Link>
          {accountPages.map((item) => {
            const props = {}
            let Element = 'div'
            switch (item.type) {
              case 'link': {
                Element = Link
                Object.assign(props, {
                  to: item.to,
                  target: item.newWindow ? '_blank' : '_self'
                })
                break
              }
              case 'action': {
                Object.assign(props, {
                  onClick: item.action
                })
                break
              }
              default: {
                break
              }
            }
            return (
              <Element key={item.id} className={'unset'} {...props}>
                <div className="account-menu-item">
                  <div className="account-menu-item-icon">
                    <Icon icon={item.icon} />
                  </div>
                  <div className="account-menu-item-block">
                    <div className="account-menu-item-title">
                      {t(item.translationKey)}
                    </div>
                    <div className="account-tests-list-right">
                      {/*<span className="account-tests-list-status show">Новое</span>*/}
                      <img
                        className="account-tests-list-arrow"
                        src={IconAngleRight}
                        alt="angle-right"
                      />
                    </div>
                  </div>
                </div>
              </Element>
            )
          })}
        </div>
      </div>
      {selectLanguage && (
        <SelectLanguageModal onClose={close} onChange={close} />
      )}
    </section>
  )
})

import { NODE_ENV, SENTRY_ENV } from '@common/env'
import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom'

Sentry.init({
  enabled: NODE_ENV !== 'development',
  environment: SENTRY_ENV ?? NODE_ENV,
  dsn: 'https://6d5710c4597541a98cb750e4a45e1bf3@o4505029536317440.ingest.sentry.io/4505040394780672',
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    }),
    Sentry.replayIntegration()
  ],
  tracesSampleRate: 0.5,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
})

export const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter)

export const surveyUniqueDescriptorMap = {
  intro: 'intro',
  mainMale: 'main-male',
  mainFemale: 'main-female'
}

export const surveyUniqueDescriptorSet = new Set(
  Object.values(surveyUniqueDescriptorMap)
)

export const getSurveyResultTrKey = (uniqueDescriptor) =>
  surveyUniqueDescriptorSet.has(uniqueDescriptor)
    ? 'userSurvey.surveyFrom'
    : 'userSurvey.surveyFromUnspecific'

export const entityName = 'user_survey'

export default {
  entityName
}

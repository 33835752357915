export function wait(amount) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve()
    }, amount)
  })
}

export function shallowEqual(object1, object2) {
  const keys1 = Object.keys(object1)
  const keys2 = Object.keys(object2)

  if (keys1.length !== keys2.length) {
    return false
  }

  for (const key of keys1) {
    if (object1[key] !== object2[key]) {
      return false
    }
  }

  return true
}

export function stringToArray(str) {
  const res = []

  if (!str) return res

  for (let i = 0; i < str.length; i++) {
    res.push(str[i])
  }

  return res
}

export const confirmAction = (action) => {
  // eslint-disable-next-line no-restricted-globals
  const openTime = new Date()
  const proceed = confirm('Are you sure?')
  const closeTime = new Date()
  if (proceed) {
    action()
  } else {
    //make actions work when modal windows are disabled
    if (closeTime - openTime < 10) {
      action()
    }
  }
}

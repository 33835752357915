import urlJoin from 'url-join'

export const introPath = '/intro'

export const surveyPath = urlJoin(introPath, 'survey')

export const registrationSubPath = 'registration'

export const registrationPath = urlJoin(introPath, registrationSubPath)

export const passwordSubPath = 'register-password'

export const passwordPath = urlJoin(introPath, passwordSubPath)

export default {
  surveyPath,
  introPath,
  registrationPath,
  passwordPath
}

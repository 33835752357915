import { memo, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
// eslint-disable-next-line import/no-unresolved, import/extensions
import { Navigation } from 'swiper/modules'
// eslint-disable-next-line import/no-unresolved, import/extensions
import { Swiper, SwiperSlide } from 'swiper/react'
// eslint-disable-next-line import/no-unresolved, import/extensions
import 'swiper/scss'

import plusIcon from '@assets/images/plus.svg'
import { IconButtonAngleLeft, IconButtonAngleRight } from '@atoms/Icons'
import Switch from '@atoms/Switch'
import overviewNavigation from '@features/overview/navigation'

import { PageContentSpacing } from '@atoms/Page'
import {
  useIsUploadedAllDocumentsM,
  useUploadAnalysesDocumentM
} from '../../reactQuery'
import AnalysesDocumentCard from '../AnalysesDocumentCard'
import AnalysesDocumentCardBody from '../AnalysesDocumentCardBody'
import SelectAnalysesResultsDocModal from '../SelectAnalysesResultDocModal'
import UploadingUserAnalysesDocumentCard from '../UploadingUserAnalysesDocumentCard'
import './style.scss'

const UploadCard = (props) => {
  const [t] = useTranslation()
  return (
    <AnalysesDocumentCardBody className="u-a-d-upload-card" {...props}>
      <button className="account-tests-download-button">
        <img src={plusIcon} alt="plus" />
      </button>
      {t('UserAnalysesUploadCard.title')}
    </AnalysesDocumentCardBody>
  )
}

export const UserAnalysesDocumentsSkeleton = memo(
  function UserAnalysesDocumentsSkeleton() {
    const [t] = useTranslation()
    return (
      <PageContentSpacing className="upload u-a-documents">
        <div className="recommendations-wrapper u-a-documents__wrapper">
          <div className="angle angle-left show">
            <button id="upload-analyses-prev-nav-el" className="angle-button">
              <img src={IconButtonAngleLeft} alt="left" />
            </button>
          </div>
          <div className="angle angle-right show">
            <button id="upload-analyses-next-nav-el" className="angle-button">
              <img src={IconButtonAngleRight} alt="right" />
            </button>
          </div>
          <div className="u-a-documents__slider recommendations-block">
            <div className="u-a-documents__slider-wrapper">
              <div className="u-a-documents__slide ">
                <UploadCard />
              </div>
              <div className="u-a-documents__slide ">
                <AnalysesDocumentCardBody />
              </div>
              <div className="u-a-documents__slide ">
                <AnalysesDocumentCardBody />
              </div>
            </div>
          </div>
        </div>
        <Switch
          isDisabled
          value={false}
          label={t([
            'analysesSection.UserAnalysesDocuments.allAnalysesUploadedMsg'
          ])}
        />
      </PageContentSpacing>
    )
  }
)

export const UserAnalysesDocuments = memo(function UserAnalysesDocuments({
  id,
  allDocumentsUploaded,
  documents
}) {
  const navigate = useNavigate()
  const [addFile, setAddFile] = useState(false)
  const [loadingFiles, setLoadingFiles] = useState(() => new Map())
  const [t] = useTranslation()
  const { mutate: uploadDoc } = useUploadAnalysesDocumentM()
  const { mutate: setIsUploadedAll, isPending: settingIsUploadedAll } =
    useIsUploadedAllDocumentsM({
      onSuccess: () => navigate(overviewNavigation.basePath)
    })
  const handleFileUpload = useCallback(
    (file) => {
      setAddFile(false)
      uploadDoc(
        {
          analysesId: id,
          file,
          onUploadProgress: (data) => {
            setLoadingFiles((state) => {
              state.set(file, Math.round(data.loaded / data.total))
              return new Map([...state])
            })
          }
        },
        {
          onSettled: () => {
            setLoadingFiles((state) => {
              state.delete(file)
              return new Map([...state])
            })
          }
        }
      )
    },
    [id]
  )
  const renderLoadingDocuments = useMemo(
    () =>
      [...loadingFiles].map(([, progressCoef], index) => (
        <SwiperSlide key={index} className="u-a-documents__slide ">
          <UploadingUserAnalysesDocumentCard progressCoef={progressCoef} />
        </SwiperSlide>
      )),
    [loadingFiles]
  )
  const renderDocumentSlides = useMemo(() => {
    const slides = documents.map((doc) => (
      <SwiperSlide key={doc.fileUrl} className="u-a-documents__slide">
        <AnalysesDocumentCard analysesId={id} {...doc} />
      </SwiperSlide>
    ))
    const emptySlidesCount = slides.length > 3 ? 0 : 3 - slides.length
    for (let i = 0; i < emptySlidesCount - 1; i++) {
      slides.push(
        <SwiperSlide key={i} className="u-a-documents__slide">
          <AnalysesDocumentCardBody />
        </SwiperSlide>
      )
    }
    return slides
  }, [documents])
  return (
    <PageContentSpacing className="upload u-a-documents">
      <div className="recommendations-wrapper u-a-documents__wrapper">
        <div className="angle angle-left show">
          <button id="upload-analyses-prev-nav-el" className="angle-button">
            <img src={IconButtonAngleLeft} alt="left" />
          </button>
        </div>
        <div className="angle angle-right show">
          <button id="upload-analyses-next-nav-el" className="angle-button">
            <img src={IconButtonAngleRight} alt="right" />
          </button>
        </div>
        <Swiper
          className="u-a-documents__slider recommendations-block"
          modules={[Navigation]}
          slidesPerView="auto"
          observer
          navigation={{
            prevEl: '#upload-analyses-prev-nav-el',
            nextEl: '#upload-analyses-next-nav-el'
          }}
        >
          <SwiperSlide className="u-a-documents__slide">
            <UploadCard onClick={() => setAddFile(true)} />
          </SwiperSlide>
          {renderLoadingDocuments}
          {renderDocumentSlides}
        </Swiper>
      </div>
      {documents.length > 0 && (
        <Switch
          className="u-a-documents__switch"
          onChange={(is) => setIsUploadedAll({ id, is })}
          isDisabled={documents.length === 0}
          isLoading={settingIsUploadedAll}
          value={allDocumentsUploaded}
          label={t([
            'analysesSection.UserAnalysesDocuments.allAnalysesUploadedMsg'
          ])}
        />
      )}
      {addFile && (
        <SelectAnalysesResultsDocModal
          isOpen
          onClose={() => setAddFile(false)}
          onSelected={handleFileUpload}
        />
      )}
    </PageContentSpacing>
  )
})

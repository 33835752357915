import classNames from 'classnames'
import { forwardRef } from 'react'
import { Link } from 'react-router-dom'

import { IconBiobetterLogoBlue } from '@atoms/Icons'
import { usePrevRenderValue } from '@common/hooks'

import './style.scss'

const DesktopHeaderBase = (
  { children, homePath, className, sticky = false, visible = true },
  ref
) => {
  const previousVisible = usePrevRenderValue(visible)
  return (
    <header
      ref={ref}
      className={classNames(className, 'header', {
        'header--sticky': sticky,
        show: !previousVisible && visible,
        hide: !visible
      })}
    >
      <div className="header-wrapper">
        <Link to={homePath} className="header__logo unset">
          <div className="logo">
            <img src={IconBiobetterLogoBlue} alt="biobetter logo" />
            <h3>biobetter</h3>
          </div>
        </Link>
        {children}
      </div>
    </header>
  )
}

export default forwardRef(DesktopHeaderBase)

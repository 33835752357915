import { Directory, Filesystem } from '@capacitor/filesystem'
import { getDataProp, getLastUrlSegment } from '@common/utils'
import queryClient from '@infrastructure/reactQuery/queryClient'
import { useMutation, useQuery } from '@tanstack/react-query'
import { keyBy } from 'lodash-es'
import { makePdfFilename } from '../repository'
import analysesApi from '../service/analysesApi'

export const keys = {
  all: ['analyses'],
  list: () => [...keys.all, 'list'],
  details: () => [...keys.all, 'detail'],
  detail: (identity) => [...keys.details(), identity],
  takenCategoryDetail: (categoryUD, takenDate) => [
    ...keys.all,
    'taken',
    { categoryUD, takenDate }
  ],
  documents: (analysesId) => [...keys.all, 'documents', analysesId]
}

export const useRecommendedAnalysesDetailByIdQ = (id, options = undefined) =>
  useQuery({
    queryKey: keys.detail(id),
    queryFn: () => analysesApi.getRecomendedDetailById(id).then(getDataProp),
    ...options
  })

export const useGetAnalysesDocumentsQ = (analysesId) =>
  useQuery({
    queryKey: keys.documents(analysesId),
    queryFn: () =>
      analysesApi.getDocumentsById(analysesId).then((resp) => {
        const { documents, ...rest } = resp.data
        documents.map((item) => ({
          ...item,
          filename: getLastUrlSegment(item.fileUrl)
        }))
        return {
          ...rest,
          documents: documents.map((item) => ({
            ...item,
            filename: getLastUrlSegment(item.fileUrl)
          }))
        }
      }),
    enabled: !!analysesId
  })

export const useUploadAnalysesDocumentM = (options) => {
  return useMutation({
    mutationFn: ({ analysesId, file, onUploadProgress }) =>
      analysesApi.uploadDocument(analysesId, file, onUploadProgress),
    ...options
  })
}

export const useDeleteAnalysesDocumentM = () => {
  return useMutation({
    mutationFn: ({ analysesId, filename }) =>
      analysesApi.deleteDocument(analysesId, filename)
  })
}

export const useIsUploadedAllDocumentsM = (options = undefined) => {
  return useMutation({
    mutationFn: ({ id, is }) =>
      analysesApi.setIsAllDocumentsUploadedById(id, is),
    ...options,
    onMutate: async ({ id, is }) => {
      const rQKey = keys.detail(id)
      const oldData = queryClient.getQueryData(rQKey)
      if (!oldData) {
        return
      }
      await queryClient.cancelQueries({ queryKey: rQKey })
      queryClient.setQueryData(rQKey, {
        ...oldData,
        userAnalysesData: {
          ...oldData.userAnalysesData,
          allDocumentsUploaded: is
        }
      })
      return { oldData, rQKey }
    },
    onError: (err, data, context) => {
      if (!context) {
        return
      }
      const { oldData, rQKey } = context
      queryClient.setQueryData(rQKey, oldData)
    }
  })
}

export const useUserAnalysesListQ = (options) =>
  useQuery({
    queryKey: keys.list(),
    queryFn: () => analysesApi.getList().then(getDataProp),
    ...options
  })

export const useGetTakenAnalysesCategoryDetailQ = (categoryUD, takenDate) =>
  useQuery({
    queryKey: keys.takenCategoryDetail(categoryUD, takenDate),
    queryFn: () =>
      analysesApi
        .getTakenAnalysesCategoryDetail(categoryUD, takenDate)
        .then((resp) => {
          const { userAnalysesData, markers, markerPanels, units } = resp.data
          const markerById = keyBy(markers, 'id')
          const panelById = keyBy(markerPanels, 'id')
          const unitById = keyBy(units, 'id')
          return userAnalysesData.map(({ panelId, markerResults }) => ({
            panel: panelById[panelId],
            markerResults: markerResults.map(
              ({ markerId, unitId, ...rest }) => ({
                ...rest,
                marker: markerById[markerId],
                unit: unitById[unitId]
              })
            )
          }))
        })
  })

export const useDownloadRecommendedPdfM = (options) =>
  useMutation({
    mutationFn: async ({ id, uniqueDescriptor, assignedAt }) => {
      const filename = makePdfFilename(uniqueDescriptor, assignedAt)
      const res = await Filesystem.getUri({
        path: filename,
        directory: Directory.Documents
      })
      const { uri } = res
      await Filesystem.deleteFile({
        path: uri
      }).catch((e) => e)
      await Filesystem.downloadFile({
        path: filename,
        directory: Directory.Documents,
        url: analysesApi.getPdfUrl(id)
      })
      return res
    },
    ...options
  })

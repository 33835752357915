import { memoize } from 'proxy-memoize'

import { getDataProp } from '@common/utils'
import queryClient from '@infrastructure/reactQuery/queryClient'
import { useQuery } from '@tanstack/react-query'

import overviewApi from '../service/overviewApi'

export const keys = {
  all: ['overivew']
}

queryClient.setQueryDefaults(keys.all, {
  queryFn: () => overviewApi.getData().then(getDataProp)
})

export const useDataQ = (options) =>
  useQuery({ queryKey: keys.all, ...options })

export const selectHasNew = memoize(
  (state) =>
    (state.medicalConclusion && !state.medicalConclusion.viewedAt) ||
    !!state.userAnalyses.find((item) => !item.viewedByCustomerAt) ||
    !!state.userSurveys.find((item) => !item.viewedByCustomerAt)
)

const overviewRQ = {
  keys,
  useDataQ,
  selectHasNew
}

export default overviewRQ

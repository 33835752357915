import classNames from 'classnames'

import './style.scss'

export default function Text({
  className,
  As = 'p',
  size = 14,
  saturation = 100,
  weight,
  children,
  ...rest
}) {
  return (
    <As
      {...rest}
      className={classNames(
        className,
        'text-2',
        `text-2--sz-${size}`,
        `text-2--saturation-${saturation}`,
        weight && `text-2--weight-${weight}`
      )}
    >
      {children}
    </As>
  )
}

import { get } from 'lodash-es'

import { passwordPath } from '@features/Intro/navigation/introNavigationHelpers'
import { loginPath } from '@features/auth/navigation/authNavigationHelpers'
import { authTypeMap } from '@features/auth/service/constants'
import overviewNavigation from '@features/overview/navigation'

export const homePathByAuthType = {
  [authTypeMap.guest]: loginPath,
  [authTypeMap.user]: overviewNavigation.basePath,
  [authTypeMap.admin]: overviewNavigation.basePath,
  [authTypeMap.regpwd]: passwordPath
}

export const getHomePathByAuthType = (authType) =>
  get(homePathByAuthType, authType, homePathByAuthType.guest)

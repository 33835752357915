import { format, isDate, parseISO } from 'date-fns'
import { enGB, ru, uk } from 'date-fns/locale'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { defaultDateFormat } from '@infrastructure/localization/constants'

const localesMap = { en: enGB, uk: uk, ru }

const useLocalizedDateFormat = ({
  format: defaultFormat = defaultDateFormat
} = {}) => {
  const [, i18n] = useTranslation()
  const func = useCallback(
    (date, formatStr = defaultFormat) =>
      date &&
      format(isDate(date) ? date : parseISO(date), formatStr, {
        locale: localesMap[i18n.language]
      }),
    [i18n.language, defaultFormat]
  )
  return func
}

export default useLocalizedDateFormat

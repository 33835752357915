import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import {
  Card,
  CardDescription,
  CardHeading,
  CardPicture,
  CardTagline
} from '@atoms/Card'
import { IconDNA } from '@atoms/Icons2'
import NewBadge from '@atoms/NewBadge'
import recommendationFeedNavigation from '@features/recommendationFeed/navigation'
import userRQ from '@features/user/reactQuery'

export default memo(function HowItWorksGuideCard(props) {
  const [t] = useTranslation()
  const { data: { recFeedRecommendationCount } = {} } = userRQ.useMetaQ()
  return (
    <Link
      className="unset"
      style={{ display: 'block' }}
      to={recommendationFeedNavigation.basePath}
      {...props}
    >
      <Card
        picture={
          <CardPicture style={{ backgroundColor: '#FFF2E4' }}>
            <IconDNA width={36} />
          </CardPicture>
        }
        tagline={
          <CardTagline>{t('overview.HowItWorksGuideCard.tagline')}</CardTagline>
        }
        heading={
          <CardHeading level={2}>
            {t('overview.HowItWorksGuideCard.heading')}
            <NewBadge />
          </CardHeading>
        }
        description={
          <CardDescription>
            {t('article.count', {
              count: recFeedRecommendationCount
            })}
          </CardDescription>
        }
      />
    </Link>
  )
})

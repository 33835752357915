import { upperFirst } from 'lodash-es'
import { useTranslation } from 'react-i18next'

import { IconCalendar } from '@atoms/Icons'
import useLocalizedDateFormat from '@common/hooks/useLocalizedDateFormat'

// <div className="section-subtitle">
//   {"Рекомендовано за результатами \n"}
//   <br />
//   <a href="#">Анкетування</a> від 03 лютого 2022
// </div>
const DateAssignmentList = ({ date, subtitle, cards, items }) => {
  const format = useLocalizedDateFormat()
  const [t] = useTranslation()
  return (
    <div className="section-block">
      <div className="section-date">
        <img src={IconCalendar} alt="calendar" />
        {upperFirst(t('common.fromDate', { date: format(date) }))}
      </div>
      <div className="section-subtitles date">
        <div className="section-subtitle">{subtitle}</div>
      </div>
      <div className="cards">{cards}</div>
      <div className="recommendations-list">{items}</div>
    </div>
  )
}

export default DateAssignmentList

import { memo } from 'react'
import { Link } from 'react-router-dom'

import { IconLogo } from '@atoms/Icons'
import { homePathByAuthType } from '@features/rootNavigation/service/navigationHelpers'
import { useCurrentBreadcrumb } from '@features/rootNavigation/service/navigationHooks'

import HeaderLanguageSelector from './HeaderLanguageSelector'

const GuestMobileHeader = ({ className }) => {
  const currentBreadCrumb = useCurrentBreadcrumb()
  return (
    <header className={className}>
      <div className="header-mobile">
        <div className="header-mobile-left">
          <Link to={homePathByAuthType.guest} className="header-mobile-logo">
            <img src={IconLogo} alt="biobetter logo" />
          </Link>
        </div>
        <div className="header-mobile-center">
          <h6 className="header-mobile-title">
            {currentBreadCrumb?.breadcrumb ?? 'Biobetter'}
          </h6>
        </div>
        <div className="header-mobile-right">
          <HeaderLanguageSelector />
        </div>
      </div>
    </header>
  )
}

export default memo(GuestMobileHeader)

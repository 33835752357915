import ListsPageSkeleton from '@atoms/ListsPageSkeleton'
import { createLazyElement } from '@common/utils'
import { conclusionAccountRouteConfig } from '@features/medicalConclusions/navigation'
import rootRoute from '@features/rootNavigation/navigation/route'

import {
  basePath,
  passedSurveyOverviewSubPath,
  passedSurveysSubPath,
  settingsSubPath
} from '.'
import AccountPage from '../components/AccountPage'

const accountRoute = {
  path: basePath,
  handle: {
    breadcrumbTrPath: 'account.AccountPage.breadcrumb'
  },
  children: [
    {
      path: passedSurveysSubPath,
      handle: {
        breadcrumbTrPath: 'userSurvey.PassedUserSurveysPage.breadcrumb'
      },
      children: [
        {
          path: passedSurveyOverviewSubPath,
          element: createLazyElement(
            () =>
              import('@features/userSurvey/components/UserSurveyOverviewPage'),
            <ListsPageSkeleton />
          ),
          handle: {
            breadcrumbTrPath: 'userSurvey.UserSurveyOverviewPage.breadcrumb'
          }
        },
        {
          index: true,
          element: createLazyElement(
            () =>
              import('@features/userSurvey/components/PassedUserSurveysPage'),
            <ListsPageSkeleton />
          )
        }
      ]
    },
    conclusionAccountRouteConfig,
    {
      path: settingsSubPath,
      element: createLazyElement(
        () => import('@features/account/components/SettingsPage'),
        <ListsPageSkeleton />
      ),
      handle: {
        breadcrumbTrPath: 'account.SettingsPage.breadcrumb'
      }
    },
    {
      index: true,
      element: <AccountPage />
    }
  ]
}

rootRoute.children.push(accountRoute)

export default accountRoute

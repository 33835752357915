import prettyBytes from 'pretty-bytes'
import { memo, useCallback, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'

import cameraIcon from '@assets/images/camera.svg'
import pdfIcon from '@assets/images/pdffile.svg'
import photoIcon from '@assets/images/photo.svg'
import BBModal from '@atoms/BBModal'

import {
  docFileMimes,
  fileMaxSize,
  fileMimeSet,
  photoFileMimes
} from '../service/userAnalysesHelpers'

export default memo(function SelectAnalysesResultsDocModal({
  isOpen,
  onClose,
  onSelected
}) {
  const [t] = useTranslation()
  const [isMaxSizeError, setIsMaxSizeError] = useState(false)
  const handleSelect = useCallback(
    (e) => {
      const file = e.target.files?.[0]
      if (!file) {
        return
      }
      if (file.size > fileMaxSize) {
        setIsMaxSizeError(true)
      } else if (fileMimeSet.has(file.type)) {
        onSelected?.(file)
      }
    },
    [onSelected]
  )
  return (
    <BBModal onClose={onClose} isOpen={isOpen}>
      <h4 className="popup-content-block-title">
        {t('SelectAnalysesDocumentModal.title')}
      </h4>
      {isMaxSizeError && (
        <div
          style={{
            color: 'red'
          }}
        >
          {t('SelectAnalysesDocumentModal.errors.maxSizeExceeded', {
            maxSize: prettyBytes(fileMaxSize)
          })}
        </div>
      )}
      <div className="popup-content-block-list">
        {isMobile && (
          <label className=" button-secondary-grey">
            <img src={cameraIcon} alt="camera" />
            {t('SelectAnalysesDocumentModal.buttons.photo')}
            <input
              style={{ display: 'none' }}
              type="file"
              accept="image/*"
              capture="environment"
              multiple={false}
              onChange={handleSelect}
            />
          </label>
        )}
        <label className="button-secondary-grey mt-12">
          <img src={photoIcon} alt="gallery" />
          {t('SelectAnalysesDocumentModal.buttons.gallery')}
          <input
            style={{ display: 'none' }}
            type="file"
            accept={photoFileMimes}
            multiple={false}
            onChange={handleSelect}
          />
        </label>
        <label className="button-secondary-grey mt-12">
          <img src={pdfIcon} alt="rocket" />
          {t('SelectAnalysesDocumentModal.buttons.document')}
          <input
            style={{ display: 'none' }}
            type="file"
            accept={docFileMimes}
            multiple={false}
            onChange={handleSelect}
          />
        </label>
      </div>
    </BBModal>
  )
})

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import {
  Card,
  CardDescription,
  CardHeading,
  CardPicture,
  CardTagline
} from '@atoms/Card'
import { IconNoteSheet } from '@atoms/Icons2'
import NewBadge from '@atoms/NewBadge'

import { createDetailOverviewPath } from '../navigation'

const ConclusionCard = ({ data: { id, viewedAt, articleCount }, ...rest }) => {
  const [t] = useTranslation()
  return (
    <Card
      As={Link}
      className="unset"
      to={createDetailOverviewPath(id)}
      picture={
        <CardPicture style={{ backgroundColor: '#DFE2FF' }}>
          <IconNoteSheet width={24} />
        </CardPicture>
      }
      tagline={
        <CardTagline>
          {t('medicalConclusion.ConclusionCard.tagline')}
        </CardTagline>
      }
      heading={
        <CardHeading level={2}>
          {t('medicalConclusion.ConclusionCard.heading')}
          {!viewedAt && <NewBadge />}
        </CardHeading>
      }
      description={
        <CardDescription>
          {t(`medicalConclusion.pointCount`, {
            count: articleCount
          })}
        </CardDescription>
      }
      {...rest}
    />
  )
}

export default ConclusionCard

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import {
  Card,
  CardDescription,
  CardHeading,
  CardPicture,
  CardTagline
} from '@atoms/Card'
import { IconCheckedSheet } from '@atoms/Icons2'
import NewBadge from '@atoms/NewBadge'
import suggestionsNavigationHelpers from '@features/overview/navigation'

const UserSurveyCard = ({
  data: {
    id,
    survey: { title, questionCount },
    viewedByCustomerAt
  },
  ...rest
}) => {
  const [t] = useTranslation()
  return (
    <Card
      {...rest}
      As={Link}
      className="unset"
      to={suggestionsNavigationHelpers.createSurveyDetailPath(id)}
      picture={
        <CardPicture style={{ backgroundColor: '#FFF2E4' }}>
          <IconCheckedSheet width={24} />
        </CardPicture>
      }
      tagline={
        <CardTagline>{t('surveySection.UserSurveyCard.tagline')}</CardTagline>
      }
      heading={
        <CardHeading level={2}>
          {title}
          {!viewedByCustomerAt && <NewBadge />}
        </CardHeading>
      }
      description={
        <CardDescription>
          {t('surveySection.questionCount', { count: questionCount })}
        </CardDescription>
      }
    />
  )
}

export default UserSurveyCard

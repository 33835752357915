import { Fragment } from 'react'

//import Avatar from '@components/avatar'
//import { Bell, Check, X, AlertTriangle, Info } from 'react-feather'

export const PrimaryToast = ({ title, text, note }) => (
  <Fragment>
    <div className="toastify-header">
      <div className="title-wrapper">
        {/* <Avatar size="sm" color="primary" icon={<Bell size={12} />} /> */}
        <h6 className="toast-title">{title}</h6>
      </div>
      {note && <small className="text-muted">{note}</small>}
    </div>
    {text && (
      <div className="toastify-body">
        <span role="img" aria-label="toast-text">
          {text}
        </span>
      </div>
    )}
  </Fragment>
)

export const SuccessToast = ({ title, text, note }) => (
  <Fragment>
    <div className="toastify-header">
      <div className="title-wrapper">
        {/* <Avatar size="sm" color="success" icon={<Check size={12} />} /> */}
        <h6 className="toast-title">{title}</h6>
      </div>
      {note && <small className="text-muted">{note}</small>}
    </div>
    {text && (
      <div className="toastify-body">
        <span role="img" aria-label="toast-text">
          {text}
        </span>
      </div>
    )}
  </Fragment>
)

export const ErrorToast = ({ title, text, note }) => (
  <Fragment>
    <div className="toastify-header">
      <div className="title-wrapper">
        {/* <Avatar size="sm" color="danger" icon={<X size={12} />} /> */}
        <h6 className="toast-title">{title}</h6>
      </div>
      {note && <small className="text-muted">{note}</small>}
    </div>
    {text && (
      <div className="toastify-body">
        <span role="img" aria-label="toast-text">
          {text}
        </span>
      </div>
    )}
  </Fragment>
)

export const WarningToast = ({ title, text, note }) => (
  <Fragment>
    <div className="toastify-header">
      <div className="title-wrapper">
        {/* <Avatar size="sm" color="warning" icon={<AlertTriangle size={12} />} /> */}
        <h6 className="toast-title">{title}</h6>
      </div>
      {note && <small className="text-muted">{note}</small>}
    </div>
    {text && (
      <div className="toastify-body">
        <span role="img" aria-label="toast-text">
          {text}
        </span>
      </div>
    )}
  </Fragment>
)

export const InfoToast = ({ title, text, note }) => (
  <Fragment>
    <div className="toastify-header">
      <div className="title-wrapper">
        {/* <Avatar size="sm" color="info" icon={<Info size={12} />} /> */}
        <h6 className="toast-title">{title}</h6>
      </div>
      {note && <small className="text-muted">{note}</small>}
    </div>
    {text && (
      <div className="toastify-body">
        <span role="img" aria-label="toast-text">
          {text}
        </span>
      </div>
    )}
  </Fragment>
)

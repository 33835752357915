import { generatePath } from 'react-router-dom'
import urlJoin from 'url-join'

import ListsPageSkeleton from '@atoms/ListsPageSkeleton'
import { createLazyElement } from '@common/utils'
import accountNavigation from '@features/account/navigation'
import accountRoute from '@features/account/navigation/route'
import markerWithUnitNavigationHelpers from '@features/analysesSection/markerWithUnits/navigation/markerWithUnitNavigationHelpers'
import overviewNavigation from '@features/overview/navigation'
import overviewRoute from '@features/overview/navigation/route'

import LazyUserAnalysesPage from '../components/LazyUserAnalysesPage'
import RecommendedUserAnalysesDetailPageSkeleton from '../components/RecommendedUserAnalysesDetailPageSkeleton'

const baseSegment = 'analyses'

const categoryTakenDatePath = 'category/:categoryUD/taken-date/:takenDate'

const detailSegment = ':id'

const detailPath = urlJoin(baseSegment, detailSegment)

export const makeCategoryTakenDateFullPath = (categoryUD, takenDate) =>
  urlJoin(
    accountNavigation.basePath,
    baseSegment,
    generatePath(categoryTakenDatePath, {
      categoryUD,
      takenDate
    })
  )

export const makeOverviewDetailFullPath = (id) =>
  urlJoin(
    overviewNavigation.basePath,
    baseSegment,
    generatePath(detailPath, { id })
  )

export const createAccountRecommendedUrl = (id) =>
  `/account/analyses/recommended/${id}`

accountRoute.children.push({
  path: baseSegment,
  handle: {
    breadcrumbTrPath: 'analysesSection.TakenAnalysesPage.breadcrumb'
  },
  children: [
    {
      path: categoryTakenDatePath,
      children: [
        markerWithUnitNavigationHelpers.config,
        {
          index: true,
          element: createLazyElement(
            () =>
              import(
                '@features/analysesSection/analyses/components/TakenAnalysesCategoryDetailPage'
              ),
            <ListsPageSkeleton />
          )
        }
      ],
      handle: {
        breadcrumbTrPath:
          'analysesSection.TakenAnalysesCategoryDetailPage.breadcrumb'
      }
    },
    {
      path: 'recommended/:id',
      element: createLazyElement(
        () =>
          import(
            '@features/analysesSection/analyses/components/RecommendedUserAnalysesDetailPage'
          ),
        <ListsPageSkeleton />
      ),
      handle: {
        breadcrumbTrPath:
          'analysesSection.TakenAnalysesCategoryDetailPage.breadcrumb'
      }
    },
    {
      index: true,
      element: <LazyUserAnalysesPage />
    }
  ]
})

overviewRoute.children.push({
  path: detailPath,
  element: createLazyElement(
    () =>
      import(
        '@features/analysesSection/analyses/components/RecommendedUserAnalysesDetailPage'
      ),
    <RecommendedUserAnalysesDetailPageSkeleton />
  ),
  handle: {
    breadcrumbTrPath: 'analysesSection.AnalysesCategoryDetailPage.breadcrumb'
  }
})

const userAnalysesNavigation = {
  makeCategoryTakenDateFullPath,
  makeOverviewDetailFullPath
}

export default userAnalysesNavigation

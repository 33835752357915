export default function RecommendationFeedActions({
  left,
  center,
  right,
  ...rest
}) {
  return (
    <div className="rec-feed-actions recommendations-actions" {...rest}>
      <div className="recommendations-actions-left">{left}</div>
      <div className="recommendations-actions-center">{center}</div>
      <div className="recommendations-actions-right">{right}</div>
    </div>
  )
}

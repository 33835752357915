import src from '@assets/images/content/thank-you-lg.jpg'
import Button from '@atoms/Button'
import { Page, PageContentContainer, PageContentSpacing } from '@atoms/Page'
import servicePackageNavigation from '@features/servicePackage/navigation'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import scss from './index.module.scss'

export default function ThankYouPage() {
  const [t] = useTranslation()
  return (
    <Page variant="noMT">
      <PageContentContainer size="xl">
        <PageContentSpacing className={scss.container}>
          <img src={src} className={scss.img} />
          <h1 className={scss.heading}>{t('ThankYouPage.heading')}</h1>
          <p className={scss.description}>{t('ThankYouPage.description')}</p>
          <Button As={Link} to="/" className={scss.homeBtn}>
            {t('ThankYouPage.homeBtn')}
          </Button>
          <Button
            As={Link}
            to={servicePackageNavigation.paymentHistoryPath}
            className={scss.historyBtn}
            variant="transparent"
          >
            {t('ThankYouPage.historyBtn')}
          </Button>
        </PageContentSpacing>
      </PageContentContainer>
    </Page>
  )
}

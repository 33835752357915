import { upperFirst } from 'lodash-es'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Card, CardDescription, CardHeading, CardTagline } from '@atoms/Card'
import NewBadge from '@atoms/NewBadge'

import { useMakePurchasePath } from '../navigation'
import ServicePackageCardPicture from './ServicePackageCardPicture'

export default memo(function PurchaseServicePackageCard({
  id,
  variationCount,
  viewed,
  ...rest
}) {
  const [t] = useTranslation()
  const makePath = useMakePurchasePath()
  return (
    <Card
      {...rest}
      As={Link}
      className="unset"
      to={makePath(id)}
      picture={<ServicePackageCardPicture />}
      tagline={
        <CardTagline>
          {t(
            'servicePackageSection.servicePackage.PurchaseServicePackageCard.tagline'
          )}
        </CardTagline>
      }
      heading={
        <CardHeading>
          {upperFirst(t('servicePackageSection.servicePackage.name'))}
          {!viewed && <NewBadge />}
        </CardHeading>
      }
      description={
        <CardDescription>
          {t('common.variationCount', {
            count: variationCount
          })}
        </CardDescription>
      }
    />
  )
})

import medicalConclusionRepository from '@features/medicalConclusions/repository'
import configureEntityChangement from '@infrastructure/entityChangement/configureEntityChangement'
import queryClient from '@infrastructure/reactQuery/queryClient'

import { keys } from '../reactQuery'

const invalidateFeed = () => {
  queryClient.invalidateQueries({ queryKey: keys.feed() })
}

configureEntityChangement({
  entity: 'recommendationFeed',
  dependenciesConfig: [
    {
      entities: [medicalConclusionRepository.name],
      changements: ['currentStepFinished', 'nextStepStarted'],
      listener: invalidateFeed
    },
    {
      entities: [
        medicalConclusionRepository.groupName,
        medicalConclusionRepository.recommendationName
      ],
      changements: ['created', 'updated', 'deleted', 'archived'],
      listener: invalidateFeed
    },
    {
      entities: [medicalConclusionRepository.stageName],
      changements: ['groupsReordered'],
      listener: invalidateFeed
    },
    {
      entities: [medicalConclusionRepository.groupName],
      changements: ['recommendationsReordered'],
      listener: invalidateFeed
    },
    {
      entities: [medicalConclusionRepository.recommendationName],
      changements: ['activated'],
      listener: invalidateFeed
    }
  ]
})

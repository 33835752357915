import { formatISO, parseISO } from 'date-fns'
import {
  isEmpty,
  isEqual,
  isEqualWith,
  isNil,
  isPlainObject,
  isString,
  keyBy,
  map,
  partial,
  pick,
  property,
  snakeCase,
  transform
} from 'lodash-es'
import { serialize } from 'object-to-formdata'
import { Suspense, lazy } from 'react'
import urlJoin from 'url-join'

import { localeMap } from '@infrastructure/localization/constants'
import { createSelectorCreator, weakMapMemoize } from 'reselect'

export const joinStrings = (delimiter, ...strings) => {
  if (isNil(delimiter)) {
    throw 'No delimiter'
  }
  return strings
    .filter(
      (string) =>
        !isEmpty(string) || (typeof string !== 'string' && !!string?.toString)
    )
    .join(delimiter)
}

export const dotsJoin = (...strings) =>
  strings.filter((string) => !!string?.toString?.()).join('.')

export const parseDataToOptions = (data, labelSelector, valueSelector) =>
  data?.map((item) => ({
    label: labelSelector(item),
    value: valueSelector(item)
  }))

export const mapDataToOptions = (
  data,
  { labelKey = 'title', valueKey = 'id' } = {}
) =>
  parseDataToOptions(
    data,
    (item) => item?.[labelKey]?.uk,
    (item) => item?.[valueKey]
  )

export const withDefaultValues = (values = {}, base = {}) =>
  transform(
    base,
    (result, value, key) => {
      result[key] = values[key] ?? value
    },
    {}
  )

export const urlPathToJsonPath = (url) =>
  url.replace(/\//g, '.').replace(/^\.*|\.*$/g, '')

export const getPathNamePartsCount = (url) =>
  typeof url === 'string' ? url.replace(/^\//, '').split('/').length : null

export const getTranslationEndingKeyByNumeral = (numeral) => {
  const mod = parseInt(numeral) % 10
  if (mod === 1) {
    return '1'
  } else if (2 <= mod && mod <= 4) {
    return '2to4'
  } else {
    return '5to0'
  }
}

export const NUMERATOR_TYPES = {
  _1: '_1',
  _2to4: '_2to4',
  _5to20: '_5to20'
}

export const getIntegerNumeratorType = (int) => {
  const mod = parseInt(int) % 20
  return mod === 0
    ? NUMERATOR_TYPES._5to20
    : mod === 1
      ? NUMERATOR_TYPES._1
      : mod < 5
        ? NUMERATOR_TYPES._2to4
        : NUMERATOR_TYPES._5to20
}

export const fileBlobToUrl = (file) => {
  return window.URL.createObjectURL(new Blob([file]))
}

export function blobToBase64(blob) {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}

export const downloadFile = (axiosResponse) => {
  const url = fileBlobToUrl(axiosResponse.data)
  const filename =
    axiosResponse.headers['content-disposition'].split('filename=')[1]
  const link = document.createElement('a')
  link.download = filename
  link.href = url
  link.click()
  link.remove()
  window.URL.revokeObjectURL(url)
}

export const getRespFilename = (resp) =>
  resp.headers['content-disposition']?.split('filename=')[1]?.slice(1, -1) ??
  null

export const getRespFileData = (resp) => ({
  filename: getRespFilename(resp),
  data: getDataProp(resp)
})

export const getBrowserTimeZone = () =>
  Intl?.DateTimeFormat().resolvedOptions().timeZone

export const cutISODateToYMD = (date) => date?.substring(0, 10)

export const extractISODateYear = (date) => date?.substring(0, 4)

export const customSnakecaseKeys = (obj) =>
  transform(obj, (acc, curr, key) => {
    acc[snakeCase(key)] =
      isPlainObject(curr) || Array.isArray(curr)
        ? customSnakecaseKeys(curr)
        : curr
  })

const opts = { indices: true, noFilesWithArrayNotation: true }

export const serializeFormData = (
  data,
  { transformToSnakecaseKeys = true, formData = undefined } = {}
) => {
  if (transformToSnakecaseKeys) {
    return serialize(customSnakecaseKeys(data), opts, formData)
  } else {
    return serialize(data, opts, formData)
  }
}

export const getLastUrlSegment = (url) => {
  if (typeof url !== 'string' || !url) {
    return null
  }
  const segments = new URL(url).pathname.split('/')
  return segments.pop() || segments.pop()
}

export const getSecuredUrl = (url, token) => {
  if (!url) {
    return ''
  }
  return urlJoin(url, `?bb_access_token=${token}`)
}

export const combineCallbacks =
  (...callbacks) =>
  (...args) => {
    for (const callback of callbacks) {
      if (typeof callback === 'function') {
        callback(...args)
      }
    }
  }

export const combineCallbackOptions = (...callbackOptions) => {
  if (!callbackOptions.length) {
    return null
  }
  const callbacksByKey = transform(
    callbackOptions,
    (acc, curr) => {
      if (!curr) {
        return
      }
      Object.entries(curr).forEach(([key, cb]) => {
        if (acc[key]) {
          acc[key].push(cb)
        } else {
          acc[key] = [cb]
        }
      })
    },
    {}
  )
  return transform(
    callbacksByKey,
    (acc, curr, key) => {
      acc[key] = combineCallbacks(...curr)
    },
    {}
  )
}

export const getDataProp = property('data')

export const lazyWithRetry = (componentImport) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem('page-has-been-force-refreshed') || 'false'
    )

    try {
      const component = await componentImport()

      window.localStorage.setItem('page-has-been-force-refreshed', 'false')

      return component
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.localStorage.setItem('page-has-been-force-refreshed', 'true')
        return window.location.reload()
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error
    }
  })

export const createLazyElement = (lazyFn, fallbackElement = undefined) => {
  const Component = lazyWithRetry(lazyFn)
  return (
    <Suspense fallback={fallbackElement}>
      <Component />
    </Suspense>
  )
}

export const createLazyComponent = (lazyFn, fallbackElement = undefined) => {
  const Component = lazyWithRetry(lazyFn)
  return function LazyComponent(props) {
    return (
      <Suspense fallback={fallbackElement}>
        <Component {...props} />
      </Suspense>
    )
  }
}

export const getDateTrKeyByLocale = (locale) =>
  locale === localeMap.uk ? 'common.fromDateWithYearWord' : 'common.fromDate'

export const getTitleProp = property('title')

export const strToLowerCase = (inp) => inp?.toLowerCase()

const ignoreKeysList = ['_owner', '$$typeof']

export const isComponentPropsEqual = (prev, next) =>
  isEqualWith(prev, next, (prevValue, nextValue, key) => {
    return ignoreKeysList.includes(key) ? true : undefined
  })

export const isShallowEqualWithDeepDataProp = (obj1, obj2) =>
  Object.keys(obj1).length === Object.keys(obj2).length &&
  Object.keys(obj1).every(
    (key) =>
      ignoreKeysList.includes(key) ||
      (Object.prototype.hasOwnProperty.call(obj2, key) &&
        (obj1[key] === obj2[key] ||
          (key === 'data' && isEqual(obj1[key], obj2[key]))))
  )

export const combineCbs =
  (...callbacks) =>
  (...args) => {
    for (const callback of callbacks) {
      if (typeof callback === 'function') {
        callback(...args)
      }
    }
  }

export const mergeCbProps = (...objects) => {
  if (!objects.length) {
    return null
  }
  const callbacksByKey = transform(
    objects,
    (acc, curr) => {
      if (!curr) {
        return
      }
      Object.entries(curr).forEach(([key, cb]) => {
        if (typeof cb !== 'function') {
          return
        }
        if (acc[key]) {
          acc[key].push(cb)
        } else {
          acc[key] = [cb]
        }
      })
    },
    {}
  )
  return transform(
    callbacksByKey,
    (acc, curr, key) => {
      acc[key] = combineCbs(...curr)
    },
    {}
  )
}

const pickMutationCbProps = partial(pick, partial.placeholder, [
  'onSuccess',
  'onError',
  'onMutate'
])

export const mergeMutationCbProps = (...objects) =>
  mergeCbProps(...objects.map(pickMutationCbProps))

export const byId = partial(keyBy, partial.placeholder, 'id')

export const formatInstanceISODate = (dateI) =>
  formatISO(dateI, {
    representation: 'date'
  })

export const formatISODate = (dateStr) =>
  formatInstanceISODate(parseISO(dateStr))

export const byKeys = (data, ...iteratees) => {
  const [iteratee, ...restIteratees] = iteratees
  const map = keyBy(data, iteratee)
  return restIteratees.length
    ? transform(map, (acc, curr, key) => {
        acc[key] = byKeys(curr, restIteratees)
      })
    : map
}

export const trimStringValues = ({ obj, keys = null }) =>
  transform(
    obj,
    (acc, value, key) => {
      if (keys?.includes(key)) {
        acc[key] = value?.trim()
        return
      }
      acc[key] = isString(value) ? value.trim() : value
    },
    {}
  )

export const doesRectsIntersect = (rect1, rect2) => {
  if (rect1.top === rect2.top || rect1.bottom === rect2.bottom) {
    return true
  }
  if (rect1.top > rect2.top && rect1.bottom <= rect2.top) {
    return true
  }
  if (rect2.top > rect1.top && rect2.bottom <= rect1.top) {
    return true
  }
  return false
}

export const isRectContainedBy = (testRect, baseRect) => {
  if (baseRect.top <= testRect.top && baseRect.bottom >= testRect.bottom) {
    return true
  }
  return false
}

export const createSelectorWeakMap = createSelectorCreator({
  memoize: weakMapMemoize,
  argsMemoize: weakMapMemoize
})

export const mapDataKey = partial(map, partial.placeholder, 'data')

import { findLast, startCase } from 'lodash-es'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useMatches } from 'react-router-dom'

const transformToBreadcrumbData =
  (t) =>
  ({ handle: { breadcrumbTrPath, Breadcrumb } = {}, pathname, params }) => ({
    pathname,
    breadcrumb: Breadcrumb ? (
      <Breadcrumb {...params} />
    ) : breadcrumbTrPath ? (
      startCase(t(breadcrumbTrPath))
    ) : null
  })

const trailingSlashRegExp = /(^\/)|(\/$)/g

const trimTrailingSlashes = (str) => str.replace(trailingSlashRegExp, '')

export const usePreviousBreadcrumbs = () => {
  const { pathname } = useLocation()
  const [t] = useTranslation()
  const matches = useMatches()
  const breadcrumbs = useMemo(
    () =>
      matches
        .filter(
          (item) =>
            !!item.handle?.breadcrumbTrPath &&
            trimTrailingSlashes(item.pathname) !== trimTrailingSlashes(pathname)
        )
        .map(transformToBreadcrumbData(t)),
    [t, pathname, matches]
  )
  return breadcrumbs
}

export const useCurrentBreadcrumb = () => {
  const { pathname } = useLocation()
  const [t] = useTranslation()
  const matches = useMatches()
  const data = useMemo(() => {
    const found = findLast(
      matches,
      (item) =>
        !!item.handle?.breadcrumbTrPath &&
        trimTrailingSlashes(item.pathname) === trimTrailingSlashes(pathname)
    )
    return found && transformToBreadcrumbData(t)(found)
  }, [t, pathname])
  return data
}

export const useLastMatch = () => useMatches().at(-1)

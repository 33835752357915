import classNames from 'classnames'
import { NavLink } from 'react-router-dom'

import { useIsMobileScreenSize } from '@features/layout/service/layoutHelpers'
import { usePreviousBreadcrumbs } from '@features/rootNavigation/service/navigationHooks'

import './style.scss'

const Breadcrumbs = ({ className, ...rest }) => {
  const breadcrumbs = usePreviousBreadcrumbs()
  return (
    <nav className={classNames(className, 'breadcrumbs')} {...rest}>
      {breadcrumbs.map(({ breadcrumb, pathname }) => (
        <NavLink key={pathname} className="breadcrumbs-item" to={pathname}>
          {breadcrumb}
        </NavLink>
      ))}
    </nav>
  )
}

export default function NonMobileBreadcrumbs(props) {
  const isMobile = useIsMobileScreenSize()
  return !isMobile ? <Breadcrumbs {...props} /> : null
}

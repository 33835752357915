import GlobalLoader from '@atoms/GlobalLoader'
import { createLazyElement } from '@common/utils'
import rootRoute from '@features/rootNavigation/navigation/route'
import { baseSegment } from '.'

rootRoute.children.push({
  path: baseSegment,
  element: createLazyElement(
    () => import('../components/SupportChatPage'),
    <GlobalLoader />
  )
})

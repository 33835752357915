export const typeMap = {
  default: 'default',
  defaultGuest: 'defaultGuest',
  defaultAuth: 'defaultAuth',
  huge: 'huge'
}

const layoutRepository = { typeMap }

export default layoutRepository

export const localeMap = {
  en: 'en',
  uk: 'uk'
}

export const localeTrMap = {
  uk: {
    code: 'uk',
    title: 'Українська'
  },
  en: {
    code: 'en',
    title: 'English'
  }
}

export const defaultDateFormat = 'd MMMM yyyy'

import analysesHelpers from '../service/userAnalysesHelpers'

const CategoryPanelMarkerList = ({ children, title, categoryUD }) => (
  <div className="list list-block">
    <span className="list-title">
      {categoryUD && analysesHelpers.getIconsByUD(categoryUD)}
      {title ?? ''}
    </span>
    <div className="list-items">{children}</div>
  </div>
)

export default CategoryPanelMarkerList

import classNames from 'classnames'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import './style.scss'

export const FillingSkeleton = ({ className, ...rest }) => {
  return (
    <Skeleton
      containerClassName={classNames('skeleton--fill', className)}
      {...rest}
    />
  )
}

export const FlexSkeleton = ({ className, ...rest }) => {
  return (
    <Skeleton
      containerClassName={classNames('skeleton--flex', className)}
      {...rest}
    />
  )
}

export const LineSkeleton = Skeleton

import { Section } from '@atoms/Section'

import ArticleSkeleton from './ArticleSkeleton'

export default function ArticlePageSkeleton() {
  return (
    <Section>
      <ArticleSkeleton />
    </Section>
  )
}

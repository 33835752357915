import { memo, useEffect } from 'react'
import { useSelector } from 'react-redux'

import GlobalLoader from '@atoms/GlobalLoader'

import authService from '@features/auth/authService'
import {
  selectAuthType,
  selectIsAuthInitialized
} from '@features/auth/redux/authSlice'
import { authTypeMap } from '@features/auth/service/constants'

import overviewRQ from '@features/overview/reactQuery'
import recommendationFeedRQ from '@features/recommendationFeed/reactQuery'
import AppRouter from '@features/rootNavigation/components/AppRouter'
import userRQ from '@features/user/reactQuery'

import queryClient from '@infrastructure/reactQuery/queryClient'

export default memo(function AppLoader() {
  const isInitialized = useSelector(selectIsAuthInitialized)
  const authType = useSelector(selectAuthType)
  useEffect(() => {
    authService.initialize()
  }, [])

  useEffect(() => {
    if (!isInitialized) {
      return
    }
    if (authType === authTypeMap.user || authType === authTypeMap.admin) {
      queryClient.prefetchQuery({
        queryKey: userRQ.keys.detail()
      })
      queryClient.prefetchQuery({
        queryKey: userRQ.keys.meta()
      })
      queryClient.prefetchQuery({
        queryKey: recommendationFeedRQ.keys.feed()
      })
      queryClient.prefetchQuery({
        queryKey: overviewRQ.keys.all
      })
    }
  }, [
    isInitialized,
    authType === authTypeMap.user || authType === authTypeMap.admin
  ])
  return !isInitialized ? <GlobalLoader /> : <AppRouter />
})

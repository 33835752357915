import { API_URL } from '@common/env'
import restApi from '@infrastructure/api/restApi'
import urlJoin from 'url-join'

export const name = 'supportChat'

export const messageRoleMap = {
  user: 'user',
  assistant: 'assistant',
  system: 'system'
}

export const chatStatusMap = {
  init: {
    creatingInfrastructure: 'init.creatingInfrastructure',
    feedingPersonalData: 'init.feedingPersonalData',
    error: 'init.error'
  },
  preparing: 'preparing',
  ready: 'ready',
  pending: 'pending',
  responding: 'responding'
}

export const getDraftContent = () => restApi.get('/support-chat/draft-content')

export const getChatStatus = () => restApi.get('/support-chat/status')

export const getMessages = ({ params, signal } = {}) =>
  restApi.get('/support-chat/messages', {
    params,
    signal
  })

export const createMessage = (data) =>
  restApi.post('/support-chat/messages', data)

export const updateDraftContent = (data) =>
  restApi.put('/support-chat/draft-content', data)

export const subscribeOnMessageEventsUrl = urlJoin(
  API_URL,
  'support-chat',
  'subscribe-on-message-events'
)

export const subscribeOnChatStatusChangeUrl = urlJoin(
  API_URL,
  'support-chat',
  'subscribe-on-status-change'
)

export const messageEventMap = {
  new: 'new',
  error: 'error'
}

export const isInitStatusFn = (status) =>
  !status || status.startsWith('init') || false

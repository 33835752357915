import { times } from 'lodash-es'
import Skeleton from 'react-loading-skeleton'

const ListsSkeleton = ({ className }) => {
  return (
    <div className={className}>
      <h2 className="section-title">
        <Skeleton />
      </h2>
      <div className="lists">
        {times(2, (index) => (
          <div key={index} className="list mt-40">
            <span className="list-title">
              <Skeleton />
            </span>
            <div className="list-items">
              <Skeleton count={6} />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ListsSkeleton

import { CardPicture } from '@atoms/Card'
import { IconBankCard } from '@atoms/Icons2'

export default function ServicePackageCardPicture() {
  return (
    <CardPicture style={{ backgroundColor: '#C4F0E6' }}>
      <IconBankCard width={30} />
    </CardPicture>
  )
}

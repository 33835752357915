import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import BBModal from '@atoms/BBModal'
import { confirmAction } from '@common/services/helpers'
import { getLastUrlSegment } from '@common/utils'
import {
  createThumbnailUrl,
  thumbnail180,
  thumbnail720
} from '@infrastructure/api/service/apiHelpers'

import { useDeleteAnalysesDocumentM } from '../reactQuery'
import AnalysesDocumentCardBody from './AnalysesDocumentCardBody'

const AnalysesDocumentCard = ({ analysesId, fileUrl }) => {
  const [viewFile, setViewFile] = useState(false)
  const { mutate: deleteDocument } = useDeleteAnalysesDocumentM()
  const handleDocumentDelete = useCallback(
    () =>
      confirmAction(() =>
        deleteDocument({ analysesId, filename: getLastUrlSegment(fileUrl) })
      ),
    [deleteDocument, fileUrl]
  )
  const thumbnail180Url = createThumbnailUrl(fileUrl, thumbnail180)

  const thumbnail720Url = createThumbnailUrl(fileUrl, thumbnail720)

  const [t] = useTranslation()
  return (
    <>
      <AnalysesDocumentCardBody
        onClick={() => {
          setViewFile(true)
        }}
        checked
      >
        <img className="account-tests-download-image" src={thumbnail180Url} />
      </AnalysesDocumentCardBody>

      {viewFile && (
        <BBModal
          onClose={() => {
            setViewFile(false)
          }}
          headerChildren={
            <div className="popup-delete">
              <button
                onClick={() => {
                  handleDocumentDelete()
                  setViewFile(false)
                }}
              >
                {t('analysesSection.AnalysesDocumentCard.deleteFile')}
              </button>
            </div>
          }
          isOpen={viewFile}
        >
          <h4 className="popup-title">
            {t('analysesSection.AnalysesDocumentCard.uploaded')}
          </h4>
          <div className="popup-file">
            <a
              href={fileUrl}
              download
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="account-tests-download-image"
                src={thumbnail720Url}
              />
            </a>
          </div>
        </BBModal>
      )}
    </>
  )
}

export default AnalysesDocumentCard

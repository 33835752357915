import { times } from 'lodash-es'
import { memo } from 'react'

import { LineSkeleton } from '@atoms/Skeleton'

import {
  RecFeedGroup,
  RecFeedGroupRecLink,
  RecFeedGroupRecommendation
} from '../RecFeedGroup'

import '../recommendationFeed.scss'
import './style.scss'

export default memo(function RecommendationFeedPageSkeleton() {
  return (
    <RecFeedGroup
      className="rec-feed-group-skeleton"
      title={<LineSkeleton />}
      recommendations={times(7, (index) => (
        <RecFeedGroupRecommendation
          key={index}
          className="rec-feed-group-skeleton__recommendation"
        >
          <LineSkeleton />
        </RecFeedGroupRecommendation>
      ))}
      firstRecommendationLink={<RecFeedGroupRecLink />}
    />
  )
})

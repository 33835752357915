import urlJoin from 'url-join'

import { createLazyElement } from '@common/utils'
import ArticleSkeleton from '@features/article/components/ArticlePageSkeleton'

// import MarkerWithUnitDetailBreadcrumb from '../components/MarkerWithUnitDetailBreadcrumb'

const rootSegment = 'markers-with-unit'

export const createDetailPath = (id) => urlJoin(rootSegment, id)

export const config = {
  path: rootSegment,
  children: [
    {
      path: ':id',
      element: createLazyElement(
        () => import('../components/MarkerWithUnitDetailPage'),
        <ArticleSkeleton />
      )
      // breadcrumb: <MarkerWithUnitDetailBreadcrumb />
    }
  ]
}

export default {
  createDetailPath,
  config
}

import { useSelector } from 'react-redux'

import { selectAuthType } from '@features/auth/redux/authSlice'
import { authTypeMap } from '@features/auth/service/constants'

import { selectType } from '../redux'
import { typeMap } from '../repository'
import AuthHugeLayout from './AuthHugeLayout'
import AuthLayout from './AuthLayout'
import GuestLayout from './GuestLayout'

const Layout = ({ children, ...rest }) => {
  const authType = useSelector(selectAuthType)
  const type = useSelector(selectType)
  const Component =
    type === typeMap.defaultAuth
      ? AuthLayout
      : type === typeMap.defaultGuest
        ? GuestLayout
        : authType === authTypeMap.admin || authType === authTypeMap.user
          ? type === typeMap.huge
            ? AuthHugeLayout
            : AuthLayout
          : GuestLayout
  return <Component {...rest}>{children}</Component>
}

export default Layout

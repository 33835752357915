import { createLazyElement } from '@common/utils'
import { authTypeMap } from '@features/auth/service/constants'

import {
  loginPath,
  logoutPath,
  recoverPasswordPath,
  resetPasswordPath
} from './authNavigationHelpers'

export default {
  children: [
    {
      path: loginPath,
      element: createLazyElement(
        () => import('@features/auth/components/LoginPage')
      ),
      handle: {
        breadcrumbTrPath: 'auth.LoginPage.breadcrumb',
        authType: authTypeMap.guest
      }
    },
    {
      path: logoutPath,
      element: createLazyElement(
        () => import('@features/auth/components/LogoutPage')
      )
    },
    {
      path: resetPasswordPath,
      element: createLazyElement(
        () => import('@features/auth/components/ResetPasswordPage')
      ),
      handle: {
        breadcrumbTrPath: 'auth.ResetPasswordPage.breadcrumb',
        authType: authTypeMap.guest
      }
    },
    {
      path: recoverPasswordPath,
      element: createLazyElement(
        () => import('@features/auth/components/RecoverPasswordPage')
      ),
      handle: {
        breadcrumbTrPath: 'auth.RecoverPasswordPage.breadcrumb',
        authType: authTypeMap.guest
      }
    }
  ]
}

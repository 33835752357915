import { authReducer } from '@features/auth/redux/authSlice'
import { configureStore } from '@reduxjs/toolkit'

import { createReducerManager } from './helpers'

const reducerManager = createReducerManager({
  auth: authReducer
})

const store = configureStore({
  devTools: true,
  reducer: reducerManager.reduce
})

store.reducerManager = reducerManager

export default store

import { RouterProvider } from 'react-router-dom'

import '@features/account'
import '@features/analysesSection/analyses'
import '@features/overview'
import '@features/recommendationFeed'
import '@features/servicePackage'
import '@features/supportChat/import'
import '@features/thankYou/import'
import '@features/user'
import '@features/userDay'

import { sentryCreateBrowserRouter } from '@infrastructure/sentry'
import rootRoute from '../navigation/route'

const router = sentryCreateBrowserRouter([rootRoute])

const AppRouter = () => {
  return <RouterProvider router={router} />
}

export default AppRouter

import { Capacitor } from '@capacitor/core'
// eslint-disable-next-line import/no-unresolved
import Cookies from 'universal-cookie'
import { API_URL } from './env'

export const LOADING_STATES = {
  INITIAL: 'INITIAL',
  LOADING: 'LOADING',
  IDLE: 'IDLE'
}

export const SURVEY_QUESTION_CONDITIONS = {
  CONTAINS: 'CONTAINS',
  NOT_CONTAINS: 'NOT_CONTAINS',
  RATING_GTE: 'RATING_GTE',
  RATING_LTE: 'RATING_LTE'
}

export const QUESTION_TYPES = {
  TEXT: 'text',
  RADIO: 'radio',
  CHECKBOX: 'checkbox',
  TEXTAREA: 'textarea',
  RATING: 'rating',
  DATE: 'date',
  YESNO: 'yesno'
}

export const QUESTION_NONE_OF_THE_ABOVE_OPTION = {
  id: 'none_of_the_above',
  title: {
    ru: 'Ни один из перечисленных вариантов',
    uk: 'Жоден з варiантiв',
    en: 'None of the above'
  }
}

export const QUESTION_YESNO_OPTIONS = [
  {
    id: 'yes',
    title: {
      ru: 'Да',
      uk: 'Так',
      en: 'Yes'
    }
  },
  {
    id: 'no',
    title: {
      ru: 'Нет',
      uk: 'Нi',
      en: 'No'
    }
  }
]

export const QUESTION_RATING_OPTIONS = new Array(10)
  .fill('')
  .map((item, index) => {
    const indexStr = (index + 1).toString()
    return {
      id: indexStr,
      title: {
        ru: indexStr,
        uk: indexStr,
        en: indexStr
      }
    }
  })

export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

export const emptyArray = Object.freeze([])

export const isNativePlatform = Capacitor.isNativePlatform()

export const domain = API_URL.includes('biobetter.io')
  ? '.biobetter.io'
  : 'localhost'

export const cookies = new Cookies(null, {
  sameSite: 'lax',
  domain,
  secure: true,
  path: '/'
})

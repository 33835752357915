import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { useIsUserAuth } from '@features/auth/redux/authSlice'
import { memo } from 'react'
import BBModal from '../../../atoms/BBModal'
import { localeTrMap } from '../../../infrastructure/localization/constants'

import userRQ from '@features/user/reactQuery'

export default memo(function ChangeLanguageModal({
  onClose,
  isOpen = true,
  onChange = undefined
}) {
  const [t, i18n] = useTranslation()
  const isUser = useIsUserAuth()
  const { mutate: patchDetail } = userRQ.usePatchDetailM()
  return (
    <BBModal onClose={onClose} isOpen={isOpen}>
      <h4 className="popup-content-block-title">
        {t('modals.selectLanguage.title')}
      </h4>
      <div className="popup-content-block-list">
        {Object.values(localeTrMap).map((locale, index) => (
          <label
            key={locale.code}
            className={classNames('button-secondary-grey', {
              'mt-12': index > 0
            })}
            onClick={() => {
              if (i18n.language === locale.code) {
                return onChange?.(locale.code)
              }
              i18n.changeLanguage(locale.code)
              onChange?.(locale.code)
              if (isUser) {
                patchDetail({
                  data: { preferredLocale: locale.code }
                })
              }
            }}
          >
            {locale.title}
          </label>
        ))}
      </div>
    </BBModal>
  )
})

import { times } from 'lodash-es'
import { memo } from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import {
  Card,
  CardDescription,
  CardHeading,
  CardPicture,
  CardTagline
} from '@atoms/Card'
import { IconCalendar } from '@atoms/Icons'

import './style.scss'

const OverviewPageSkeleton = () => {
  return (
    <section className="section">
      <div className="section-wrapper section-wrapper-400">
        <div className="overview-page-skeleton">
          <div className="section-block">
            <div className="section-date">
              <img src={IconCalendar} alt="calendar" />
              <Skeleton containerClassName="overview-page-skeleton__date" />
            </div>
            <div className="section-subtitles date">
              <div className="section-subtitle">{/* <Skeleton /> */}</div>
            </div>
            <div className="cards">
              {times(4, (index) => (
                <Card
                  key={index}
                  picture={
                    <CardPicture>
                      <Skeleton />
                    </CardPicture>
                  }
                  tagline={
                    <CardTagline>
                      <Skeleton />
                    </CardTagline>
                  }
                  heading={
                    <CardHeading>
                      <Skeleton />
                    </CardHeading>
                  }
                  description={
                    <CardDescription>
                      <Skeleton />
                    </CardDescription>
                  }
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default memo(OverviewPageSkeleton)

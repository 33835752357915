import { useLocation } from 'react-router-dom'
import urlJoin from 'url-join'

import { createLazyElement } from '@common/utils'
import layoutRepository from '@features/layout/repository'
import rootRoute from '@features/rootNavigation/navigation/route'

import RecommendationFeedPageSkeleton from '../components/RecommendationFeedPageSkeleton'

export const baseSegment = 'recommendation-feed'

export const basePath = `/${baseSegment}`

export const contentsTabKey = 'contentsTab'

export const contentsTabMap = {
  all: 'all',
  new: 'new',
  favorites: 'favorites'
}

export function useContentsPath({ tab } = {}) {
  const { pathname, hash } = useLocation()
  return makeContentsPath({
    tab,
    hash: pathname === basePath ? hash : undefined
  })
}

export const makeContentsHash = ({
  tab = contentsTabMap.all,
  hash = ''
} = {}) => {
  const hashParams = hash.slice(1)
  if (!hashParams) {
    return `#${contentsTabKey}=${tab}`
  }
  const hashSearchParams = new URLSearchParams(hashParams)
  hashSearchParams.set(contentsTabKey, tab)
  return `#${hashSearchParams.toString()}`
}
export const makeContentsPath = ({
  tab = contentsTabMap.all,
  hash = ''
} = {}) => {
  return urlJoin(basePath, makeContentsHash({ tab, hash }))
}

const recommendationFeedNavigation = {
  basePath,
  contentsTabMap,
  makeContentsPath
}

rootRoute.children.push({
  path: baseSegment,
  element: createLazyElement(
    () => import('../components/RecommendationFeedPage'),
    <RecommendationFeedPageSkeleton />
  ),
  handle: {
    layoutType: layoutRepository.typeMap.huge
  }
})

export default recommendationFeedNavigation

import medicalConclusionRepository from '@features/medicalConclusions/repository'
import configureEntityChangement from '@infrastructure/entityChangement/configureEntityChangement'
import queryClient from '@infrastructure/reactQuery/queryClient'

import { keys } from '../reactQuery'
import { name } from '../repository'

const listener = () => {
  queryClient.invalidateQueries({ queryKey: keys.all })
}

configureEntityChangement({
  entity: name,
  config: [{ changements: ['refreshed'], listener }],
  dependenciesConfig: [
    {
      entities: [medicalConclusionRepository.name],
      changements: ['nextStepStarted', 'currentStepFinished'],
      listener
    },
    {
      entities: [medicalConclusionRepository.recommendationName],
      changements: ['archived'],
      listener
    },
    {
      entities: [medicalConclusionRepository.todoName],
      changements: ['archived', 'toggleActivated', 'refreshed'],
      listener
    },
    {
      entities: [medicalConclusionRepository.whenName],
      changements: ['toggleCompleted'],
      listener
    },
    {
      entities: [medicalConclusionRepository.intervalName],
      changements: ['archived'],
      listener
    }
  ]
})

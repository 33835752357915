import AuthDesktopHeader from '@features/layout/components/AuthDesktopHeader'
import LayoutBase from '@features/layout/components/LayoutBase'
import MobileMenu from '@features/layout/components/MobileMenu'
import { useIsMobileScreenSize } from '@features/layout/service/layoutHelpers'

import AuthMobileHeader from './AuthMobileHeader'

const AuthLayout = ({ children, ...rest }) => {
  const isMobileSize = useIsMobileScreenSize()
  return (
    <LayoutBase
      {...rest}
      style={
        isMobileSize
          ? {
              paddingBottom: '51px'
            }
          : {}
      }
    >
      {isMobileSize ? <AuthMobileHeader /> : <AuthDesktopHeader />}
      {children}
      {isMobileSize && <MobileMenu />}
    </LayoutBase>
  )
}

export default AuthLayout

import CheckInternetConnection from '@atoms/CheckInternetConnection'
import { App } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import { useFirebase } from '@features/firebase/repository'
import Layout from '@features/layout/components/Layout'
import layoutRedux from '@features/layout/redux'
import { useSyncChat } from '@features/supportChat/reactQuery'
import userRQ from '@features/user/reactQuery'
import { useFilesystem } from '@infrastructure/filesystem/repository'
import { useSyncLocale } from '@infrastructure/localization/repository/hooks'
import useApiSocket from '@infrastructure/socket/useApiSocket'
import { memo, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Outlet, useLocation } from 'react-router-dom'
import { useLastMatch } from '../service/navigationHooks'
import NavigateHomeIfNotMatchAuthType from './NavigateHomeIfNotMatchAuthType'

export default memo(function RootRoute() {
  const { pathname, state } = useLocation()
  const { handle } = useLastMatch() ?? {}
  const dispatch = useDispatch()
  useEffect(() => {
    if (handle?.layoutType) {
      dispatch(layoutRedux.slice.actions.setType({ type: handle.layoutType }))
    } else {
      dispatch(layoutRedux.slice.actions.resetType())
    }
  }, [handle?.layoutType])
  useEffect(() => {
    if (state && state.shouldScrollTop === false) {
      return
    }
    window.scrollTo(0, 0)
  }, [pathname])
  userRQ.useSyncTimezone()
  useSyncLocale()
  useFirebase()
  useApiSocket()
  useFilesystem()
  useSyncChat()
  useEffect(() => {
    if (Capacitor.getPlatform() === 'ios') {
      App.addListener('backButton', ({ canGoBack }) => {
        if (canGoBack) window.history.back()
        else App.exitApp()
      })
    }
  }, [])
  return (
    <Layout>
      <CheckInternetConnection>
        <NavigateHomeIfNotMatchAuthType authType={handle?.authType}>
          <Outlet />
        </NavigateHomeIfNotMatchAuthType>
      </CheckInternetConnection>
    </Layout>
  )
})

import { homePathByAuthType } from '@features/rootNavigation/service/navigationHelpers'

import DesktopHeaderBase from './DesktopHeaderBase'
import HeaderLanguageSelector from './HeaderLanguageSelector'

const GuestDesktopHeader = () => {
  return (
    <DesktopHeaderBase homePath={homePathByAuthType.guest}>
      <HeaderLanguageSelector />
    </DesktopHeaderBase>
  )
}

export default GuestDesktopHeader

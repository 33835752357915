import classNames from 'classnames'

import './style.scss'

export default function Container({
  children,
  className,
  As = 'div',
  marginInsteadPadding = false,
  size = null,
  side = null,
  ...rest
}) {
  return (
    <As
      {...rest}
      className={classNames(
        className,
        '__container',
        size && `__container--${size}`,
        side && `__container--${side}`,
        {
          '__container--margin': marginInsteadPadding
        }
      )}
    >
      {children}
    </As>
  )
}

import urlJoin from 'url-join'

export const baseSegment = 'account'

export const basePath = urlJoin('/', baseSegment)

export const passedSurveysSubPath = 'passed-surveys'

export const passedSurveysPath = urlJoin(basePath, passedSurveysSubPath)

export const passedSurveyOverviewSubPath = ':id'

export const createPassedSurveyOverviewPath = (id) =>
  `/account/passed-surveys/${id}`

export const analysesSubPath = 'analyses'

export const analysesPath = urlJoin(basePath, analysesSubPath)

export const createAnalysesCategoryTakenDatePath = (category, takenDate) =>
  `/account/analyses/category/${category}/taken-date/${takenDate}`

export const settingsSubPath = 'settings'

export const settingsPath = urlJoin(basePath, settingsSubPath)

const accountNavigation = {
  basePath,
  baseSegment,
  passedSurveysPath,
  createPassedSurveyOverviewPath,
  analysesPath,
  createAnalysesCategoryTakenDatePath,
  settingsPath
}

export default accountNavigation

import urlJoin from 'url-join'

import ListsPageSkeleton from '@atoms/ListsPageSkeleton'
import { createLazyElement } from '@common/utils'
import rootRoute from '@features/rootNavigation/navigation/route'
import { formatISO } from 'date-fns'

const baseSegment = 'my-day'

export const calendarFragment = '#calendar'

export const createUserDayPath = ({ date = null } = {}) =>
  urlJoin(
    '/',
    baseSegment,
    date ?? formatISO(new Date(), { representation: 'date' })
  )

export const calendarFragmentRegExp = new RegExp(`^${calendarFragment}$`)

const MyDayPage = createLazyElement(
  () => import('../components/UserDayPage'),
  <ListsPageSkeleton />
)

rootRoute.children.push({
  path: baseSegment,
  children: [
    {
      path: ':date',
      element: MyDayPage
    },
    {
      index: true,
      element: MyDayPage
    }
  ],
  handle: {
    breadcrumbTrPath: 'userDay.MyDayPage.breadcrumb'
  }
})

import classNames from 'classnames'
import { get } from 'lodash-es'

import Text from '@atoms/Text'

import './style.scss'

const defaultColor = '#1D1E2C'

const badgeColorByBgColor = {
  '#E07A5F': '#FFFFFF'
}

const getColor = (bgColor) => get(badgeColorByBgColor, bgColor, defaultColor)

export function Badge({
  className,
  children,
  As = 'span',
  color = '#E07A5F',
  ...rest
}) {
  return (
    <Text
      As={As}
      className={classNames('badge', className)}
      style={{
        lineHeight: 1,
        ...rest.style,
        color: getColor(color),
        backgroundColor: color
      }}
    >
      {children}
    </Text>
  )
}

import classNames from 'classnames'
import { forwardRef } from 'react'
import { Link } from 'react-router-dom'

import userRQ from '@features/user/reactQuery'

import { useContentsPath } from '../navigation'
import RecommendationFeedContentsButton from './RecommendationFeedContentsButton'

export default forwardRef(function RecommendationFeedContentsLink(
  { className, ...rest },
  ref
) {
  const contentsPath = useContentsPath()
  const { data: { hasNewRecommendations } = {} } = userRQ.useDetailQ()
  return (
    <Link
      to={contentsPath}
      state={{
        shouldScrollTop: false
      }}
      className={classNames(className, 'unset')}
      ref={ref}
      {...rest}
    >
      <RecommendationFeedContentsButton hasNew={hasNewRecommendations} />
    </Link>
  )
})

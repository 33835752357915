import classNames from 'classnames'

import Loader from '@atoms/Loader'

import scss from './style.module.scss'

const GlobalLoader = ({ className, ...rest }) => (
  <Loader size="xl" className={classNames(scss.loader, className)} {...rest} />
)

export default GlobalLoader

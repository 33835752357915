import { useCallback, useState } from 'react'

import { IconLanguage } from '@atoms/Icons'
import SelectLanguageModal from '@features/account/components/ChangeLanguageModal'

const HeaderLanguageSelector = () => {
  const [modalOpened, setModalOpened] = useState(false)
  const openModal = useCallback(() => {
    setModalOpened(true)
  }, [setModalOpened])
  const closeModal = useCallback(() => {
    setModalOpened(false)
  }, [setModalOpened])
  return (
    <>
      <button className="header-button" onClick={openModal}>
        <img src={IconLanguage} />
      </button>
      {modalOpened && (
        <SelectLanguageModal onClose={closeModal} onChange={closeModal} />
      )}
    </>
  )
}

export default HeaderLanguageSelector

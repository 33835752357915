import restApi from '@infrastructure/api/restApi'

export const name = 'servicePackage'

export const orderName = 'servicePackageOrder'

export const acquiringName = 'servicePackageOrderAcquiring'

export const userPackageName = 'userServicePackageOrder'

export const getPackageById = (id) => restApi.get(`/service-packages/${id}`)

export const getCurrentUserPackages = () =>
  restApi.get(`/user-service-packages/current`)

export const getUserPackageById = (userServicePackageId) =>
  restApi.get(`/user-service-packages/${userServicePackageId}`)

export const getOrderById = (servicePackageOrderId) =>
  restApi.get(`/service-package-orders/${servicePackageOrderId}`)

export const getAcquiringsHistory = () =>
  restApi.get('/service-package-orders/acquirings/history')

export const getPackagePromocode = (id, code) =>
  restApi.get(`/service-packages/${id}/promocode/${code}`)

export const getFirstAcquirablePreview = () =>
  restApi.get(`/service-packages/first/preview`)

export const createOrder = (data) =>
  restApi.post(`/service-package-orders`, data)

export const orderStatusMap = {
  success: 'success',
  expired: 'expired',
  pending: 'pending'
}

export const promocodeTypeMap = {
  fixed: 'fixed',
  percentage: 'percentage'
}

export const orderAcquiringStatusMap = {
  success: 'success',
  expired: 'expired',
  processing: 'processing',
  failure: 'failure'
}

const servicePackageRepository = {
  userPackageName
}

export default servicePackageRepository

import userAnalysesHelpers from '@features/analysesSection/analyses/service/userAnalysesHelpers'
import medicalConclusionRepository from '@features/medicalConclusions/repository'
import servicePackageRepository from '@features/servicePackage/repository'
import userSurveyHelpers from '@features/userSurvey/service/userSurveyHelpers'
import configureEntityChangement from '@infrastructure/entityChangement/configureEntityChangement'
import queryClient from '@infrastructure/reactQuery/queryClient'

import { keys } from '../reactQuery'
import { name } from '../repository'

const detailListener = () => {
  queryClient.invalidateQueries({ queryKey: keys.detail() })
}

configureEntityChangement({
  entity: name,
  config: [
    {
      changements: ['updated'],
      listener: detailListener
    },
    {
      changements: ['metaPatched'],
      listener: () => {
        queryClient.invalidateQueries({ queryKey: keys.meta() })
      }
    }
  ],
  dependenciesConfig: [
    {
      entities: [userAnalysesHelpers.entityName],
      changements: ['all_documents_uploaded', 'examined'],
      listener: detailListener
    },
    {
      entities: [medicalConclusionRepository.name],
      changements: ['nextStepDone', 'currentStepFinished'],
      listener: detailListener
    },
    {
      entities: [userSurveyHelpers.entityName, userAnalysesHelpers.entityName],
      changements: ['created'],
      listener: detailListener
    },
    {
      entities: [userSurveyHelpers.entityName],
      changements: ['passed'],
      listener: detailListener
    },
    {
      entities: [servicePackageRepository.userPackageName],
      changements: ['created'],
      listener: detailListener
    }
  ]
})

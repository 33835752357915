import { useCallback } from 'react'

import { IconQuestion } from '@atoms/Icons'

import classNames from 'classnames'
import Loader from './Loader'

const Switch = ({
  className,
  onChange,
  label,
  value = false,
  isDisabled = false,
  isLoading = false,
  tooltip = undefined
}) => {
  isDisabled = isDisabled || isLoading
  const onChangeCb = useCallback(
    (e) => {
      if (!isDisabled) {
        onChange(e.target.checked, e)
      }
    },
    [onChange, isDisabled]
  )
  return (
    <div className={classNames('section-switch', className)}>
      <label aria-disabled={isDisabled} className="switch">
        <input
          disabled={isDisabled}
          checked={value}
          type="checkbox"
          onChange={onChangeCb}
        />
        <i />
        {isLoading && <Loader className="mr-6" size="sm" />}
        {label}
      </label>
      {tooltip && (
        <div className="section-switch-question" href="#">
          <img src={IconQuestion} alt="Question sign" />
        </div>
      )}
    </div>
  )
}
export default Switch

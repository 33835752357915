import { times } from 'lodash-es'
import { memo } from 'react'

import { Section, SectionWrapper } from '@atoms/Section'
import { LineSkeleton } from '@atoms/Skeleton'
import NonMobileBreadcrumbs from '@features/rootNavigation/components/NonMobileBreadcrumbs'

import CategoryPanelMarkerList from './AnalysesPanelMarkerList'
import AnalysesPanelMarkerListItem from './AnalysesPanelMarkerListItem'
import { UserAnalysesDocumentsSkeleton } from './UserAnalysesDocuments'

export default memo(function RecommendedUserAnalysesDetailPageSkeleton() {
  return (
    <Section>
      <UserAnalysesDocumentsSkeleton />
      <SectionWrapper>
        <NonMobileBreadcrumbs />
        <h2 className="section-title" style={{ width: '100%' }}>
          <LineSkeleton />
        </h2>
        <div className="section-subtitles">
          <div className="section-subtitle" style={{ width: '100%' }}>
            <LineSkeleton />
          </div>
        </div>
        <div className="lists">
          {times(4)?.map((index) => (
            <CategoryPanelMarkerList key={index} title={<LineSkeleton />}>
              {times(index === 0 ? 4 : index === 2 ? 2 : 3).map((index) => (
                <AnalysesPanelMarkerListItem
                  key={index}
                  title={<LineSkeleton />}
                />
              ))}
            </CategoryPanelMarkerList>
          ))}
        </div>
      </SectionWrapper>
    </Section>
  )
})

export const authTypeMap = {
  guest: 'guest',
  admin: 'admin',
  user: 'user',
  regpwd: 'regpwd'
}

export const tokenDsgnMap = {
  setPwd: 'setPwd',
  setEmail: 'setEmail',
  pwdrec: 'pwdrec'
}

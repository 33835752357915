import classNames from 'classnames'

const SectionNWrapper = ({
  children,
  beforeWrapperElement = null,
  className = undefined,
  wrapperClassName = undefined,
  ...rest
}) => {
  return (
    <section className={classNames('section', className)} {...rest}>
      {beforeWrapperElement}
      <div className={classNames('section-wrapper', wrapperClassName)}>
        {children}
      </div>
    </section>
  )
}

export default SectionNWrapper

import classNames from 'classnames'
import { produce } from 'immer'
import { merge, pick, upperFirst } from 'lodash-es'
import { forwardRef, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  PopupArrow,
  PopupOverlay,
  PopupPortal,
  usePopupClose,
  usePopupDescription,
  usePopupFloating,
  usePopupHeading,
  usePopupReference
} from '@atoms/Popup'
import { FloatingFocusManager } from '@floating-ui/react'

import './style.scss'

export const GuidelineCardButton = forwardRef(function GuidelineCardButton(
  { className, As = 'button', children, ...props },
  ref
) {
  return (
    <As
      className={classNames(className, 'guideline-card__button')}
      ref={ref}
      {...props}
    >
      {children}
    </As>
  )
})

export const GuidelineCardOkButton = (props) => {
  const [t] = useTranslation()
  return (
    <GuidelineCardButton {...props}>
      {upperFirst(t('common.ok'))}
    </GuidelineCardButton>
  )
}

export const GuidelineCardPopupOkButton = ({ onClick, ...props }) => {
  const { close } = usePopupClose()
  return (
    <GuidelineCardOkButton
      {...props}
      onClick={(e) => {
        onClick?.(e)
        close()
      }}
    />
  )
}

export const GuidelineCardHeading = ({
  As = 'h3',
  className,
  children,
  ...props
}) => {
  return (
    <As className={classNames(className, 'guideline-card__heading')} {...props}>
      {children}
    </As>
  )
}

export const GuidelineCardDescription = ({
  As = 'p',
  className,
  children,
  ...props
}) => {
  return (
    <As
      className={classNames(className, 'guideline-card__description')}
      {...props}
    >
      {children}
    </As>
  )
}

export const GuidelineCardPopupHeading = ({ children, ...rest }) => {
  const { id } = usePopupHeading()
  return (
    <GuidelineCardHeading id={id} {...rest}>
      {children}
    </GuidelineCardHeading>
  )
}

export const GuidelineCardPopupDescription = ({ children, ...rest }) => {
  const { id } = usePopupDescription()
  return (
    <GuidelineCardDescription id={id} {...rest}>
      {children}
    </GuidelineCardDescription>
  )
}

export const GuidelineCard = forwardRef(function GuidelineCard(
  {
    heading,
    description,
    actions = <GuidelineCardOkButton />,
    className,
    ...rest
  },
  ref
) {
  return (
    <div
      ref={ref}
      className={classNames(className, 'guideline-card')}
      {...rest}
    >
      <article className="guideline-card__content">
        {typeof heading === 'string' ? (
          <GuidelineCardHeading>{heading}</GuidelineCardHeading>
        ) : (
          heading
        )}
        {typeof description === 'string' ? (
          <GuidelineCardDescription>{description}</GuidelineCardDescription>
        ) : (
          description
        )}
      </article>
      <div className="guideline-card__actions">{actions}</div>
    </div>
  )
})

export const GuidelineCardPopupReference = ({ children, ...rest }) => {
  const { ref, getProps } = usePopupReference()
  return (
    <div ref={ref} {...getProps(rest)} className="guideline-card-reference">
      {children}
    </div>
  )
}

export const GuidelineCardPopup = ({
  style,
  heading,
  description,
  reference,
  actions = <GuidelineCardPopupOkButton />,
  ...rest
}) => {
  const { context, ref, getProps, styles, labelId, descriptionId } =
    usePopupFloating()
  const [{ top, left }, setPosition] = useState({})
  const anchorRef = useCallback((node) => {
    if (!node) {
      return
    }
    setTimeout(
      () =>
        setPosition(
          produce((draft) => {
            merge(draft, pick(node.getBoundingClientRect(), ['top', 'left']))
          })
        ),
      400
    )
  }, [])
  return (
    <>
      <PopupPortal>
        <PopupOverlay>
          {top && left && (
            <>
              <GuidelineCardPopupReference
                style={{ top: `${top}px`, left: `${left}px` }}
              >
                {reference}
              </GuidelineCardPopupReference>
              <FloatingFocusManager context={context} modal>
                <div
                  ref={ref}
                  style={{ ...styles, ...style }}
                  aria-labelledby={labelId}
                  aria-describedby={descriptionId}
                  {...getProps(rest)}
                >
                  <GuidelineCard
                    heading={
                      typeof heading === 'string' ? (
                        <GuidelineCardPopupHeading>
                          {heading}
                        </GuidelineCardPopupHeading>
                      ) : (
                        heading
                      )
                    }
                    description={
                      typeof description === 'string' ? (
                        <GuidelineCardPopupDescription>
                          {description}
                        </GuidelineCardPopupDescription>
                      ) : (
                        description
                      )
                    }
                    actions={actions}
                    {...rest}
                  />
                  <PopupArrow width={12} height={9} fill="#1D1E2C" />
                </div>
              </FloatingFocusManager>
            </>
          )}
        </PopupOverlay>
      </PopupPortal>
      <div ref={anchorRef}>{reference}</div>
    </>
  )
}

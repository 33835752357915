import ListsPageSkeleton from '@atoms/ListsPageSkeleton'
import { createLazyComponent } from '@common/utils'

const LazyUserAnalysesPage = createLazyComponent(
  () =>
    import('@features/analysesSection/analyses/components/UserAnalysesPage'),
  <ListsPageSkeleton />
)

export default LazyUserAnalysesPage

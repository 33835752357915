import { forwardRef, memo } from 'react'
import { useSelector } from 'react-redux'

import { selectAuthType } from '@features/auth/redux/authSlice'
import { authTypeMap } from '@features/auth/service/constants'
import { getHomePathByAuthType } from '@features/rootNavigation/service/navigationHelpers'
import userRQ from '@features/user/reactQuery'

import DesktopHeaderBase from './DesktopHeaderBase'
import DesktopMenu from './DesktopMenu'

export default memo(
  forwardRef(function AuthDesktopHeader(
    { sticky = false, visible = true, ...rest },
    outerRef
  ) {
    const authType = useSelector(selectAuthType)
    const { data: { email } = {} } = userRQ.useDetailQ()
    return (
      <DesktopHeaderBase
        homePath={getHomePathByAuthType(authType)}
        sticky={sticky}
        visible={visible}
        ref={outerRef}
        {...rest}
      >
        {authType === authTypeMap.admin && (
          <span
            style={{
              marginLeft: '8px',
              marginRight: '8px',
              textOverflow: 'ellipsis',
              overflow: 'hidden'
            }}
          >
            Impersonating {email ?? '...'}
          </span>
        )}
        <DesktopMenu />
      </DesktopHeaderBase>
    )
  })
)

import { combineReducers } from '@reduxjs/toolkit'

import { LOADING_STATES } from '../../common/constants'

export const createThunkTask = (api) => {
  return async (payload, thunkApi) => {
    let response

    try {
      response = await api(payload)
    } catch (e) {
      thunkApi.rejectWithValue(e)
      throw e
    }

    return response.data
  }
}

export const createLoadingCasesWPayload = (
  action,
  stateChild,
  propToSetPayload
) => {
  return {
    [action.pending]: (state) => {
      state[stateChild].loadingState = LOADING_STATES.LOADING
    },
    [action.fulfilled]: (state, action) => {
      state[stateChild].loadingState = LOADING_STATES.IDLE
      state[stateChild][propToSetPayload] = action.payload
    }
  }
}

export const createLoadingCases = (action, stateChild, loadingProp) => {
  return {
    [action.pending]: (state) => {
      state[stateChild][loadingProp] = LOADING_STATES.LOADING
    },
    [action.fulfilled]: (state) => {
      state[stateChild][loadingProp] = LOADING_STATES.IDLE
    },
    [action.rejected]: () => {}
  }
}

export function createReducerManager(initialReducers) {
  const reducers = { ...initialReducers }
  let combinedReducer = combineReducers(reducers)
  let keysToRemove = []
  return {
    getReducerMap: () => reducers,
    reduce: (state, action) => {
      if (keysToRemove.length > 0) {
        state = { ...state }
        for (let key of keysToRemove) {
          delete state[key]
        }
        keysToRemove = []
      }
      return combinedReducer(state, action)
    },
    add: (key, reducer) => {
      if (!key || reducers[key]) {
        return
      }
      reducers[key] = reducer
      combinedReducer = combineReducers(reducers)
    },
    remove: (key) => {
      if (!key || !reducers[key]) {
        return
      }
      delete reducers[key]
      keysToRemove.push(key)
      combinedReducer = combineReducers(reducers)
    }
  }
}

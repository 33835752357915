import { selectIsLoggedIn } from '@features/auth/redux/authSlice'
import restApi from '@infrastructure/api/restApi'
import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import { FirebaseMessaging } from '@capacitor-firebase/messaging'
import { isNativePlatform } from '@common/constants'
import { FIREBASE_CONFIG, NODE_ENV } from '@common/env'
import { initializeApp } from 'firebase/app'
import { method } from 'lodash-es'

export const storeToken = (token) =>
  restApi.put(`/users/me/fcm-tokens/${token}`)

export const deleteToken = (token) =>
  restApi.delete(`/users/me/fcm-tokens/${token}`)

const register = async () => {
  try {
    let permStatus = await FirebaseMessaging.checkPermissions()
    if (
      permStatus.receive === 'prompt' ||
      permStatus.receive === 'prompt-with-rationale'
    ) {
      permStatus = await (isNativePlatform
        ? FirebaseMessaging.requestPermissions()
        : new Promise((resolve) => {
            const listener = async () => {
              document.removeEventListener('click', listener)
              resolve(await FirebaseMessaging.requestPermissions())
            }
            document.addEventListener('click', listener)
          }))
    }
    if (permStatus.receive !== 'granted') {
      throw new Error('User denied permissions!')
    }
    const options = {
      vapidKey: FIREBASE_CONFIG.vapidKey
    }
    if (!isNativePlatform) {
      options.serviceWorkerRegistration =
        await navigator.serviceWorker.register('/firebase-messaging-sw.js')
    }
    const tokenData = await FirebaseMessaging.getToken(options)
    await storeToken(tokenData.token).catch((e) => e)
    return tokenData
  } catch (e) {
    if (NODE_ENV === 'development') {
      console.error(e)
    }
    return { token: null }
  }
}

const unregister = async (token) => {
  try {
    await Promise.all([FirebaseMessaging.deleteToken(), deleteToken(token)])
  } catch (e) {
    if (NODE_ENV === 'development') {
      console.error(e)
    }
  }
}

export const useFirebase = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const tokenRef = useRef(null)
  useEffect(() => {
    if (isNativePlatform || !navigator.serviceWorker) {
      return
    }
    initializeApp(FIREBASE_CONFIG)
  }, [])
  useEffect(() => {
    if (!isLoggedIn || (!isNativePlatform && !navigator.serviceWorker)) {
      return
    }
    if (!isNativePlatform) {
      navigator.serviceWorker.addEventListener('message', (event) => {
        const notification = new Notification(event.data.notification.title, {
          body: event.data.notification.body
        })
        if (NODE_ENV === 'development') {
          notification.onclick = (event) => {
            console.log('notification clicked: ', { event })
          }
        }
      })
    }
    let listeners
    const addListeners = () =>
      Promise.all([
        FirebaseMessaging.addListener('tokenReceived', async ({ token }) => {
          try {
            await storeToken(token).catch((e) => e)
            tokenRef.current = tokenRef
            if (NODE_ENV === 'development') {
              console.info('Received token: ', token)
            }
          } catch (e) {
            console.log(e)
          }
        }),
        FirebaseMessaging.addListener(
          'notificationReceived',
          ({ notification }) => {
            if (NODE_ENV === 'development') {
              console.log('Push notification received: ', notification)
            }
          }
        ),
        FirebaseMessaging.addListener(
          'notificationActionPerformed',
          ({ actionId, inputValue }) => {
            if (NODE_ENV === 'development') {
              console.log(
                'Push notification action performed',
                actionId,
                inputValue
              )
            }
          }
        )
      ])
    addListeners().then((resListeners) => {
      listeners = resListeners
      register()
    })
    return () => {
      if (tokenRef.current) {
        unregister(tokenRef.current)
        tokenRef.current = null
      }
      listeners?.map(method('remove'))
    }
  }, [isLoggedIn])
}

import { jwtDecode } from 'jwt-decode'
import { property } from 'lodash-es'
import { useCallback, useEffect, useId, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

export function usePrevRenderValue(value) {
  const ref = useRef(value)
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

export function usePrevValue(value) {
  const currentRef = useRef(value)
  const [previous, setPrevious] = useState(null)
  useEffect(() => {
    setPrevious(currentRef.current)
    currentRef.current = value
  }, [value])
  return previous
}

export function useHashAsParams(hash) {
  return useMemo(() => new URLSearchParams(hash.slice(1)), [hash])
}

export function useLocationHashParams() {
  const { hash } = useLocation()
  return useHashAsParams(hash)
}

export function useDecodeToken(token) {
  const decodedToken = useMemo(() => {
    if (!token) {
      return null
    }
    try {
      return jwtDecode(token)
    } catch (e) {
      return null
    }
  }, [token])
  const expiresAt = useMemo(
    () => decodedToken && new Date(decodedToken?.exp),
    [decodedToken?.exp]
  )
  const [expired, setExpired] = useState(
    decodedToken ? decodedToken.exp * 1000 < Date.now() : false
  )
  useEffect(() => {
    if (!decodedToken || expired) {
      return
    }
    const timeout = setTimeout(
      () => {
        setExpired(true)
      },
      decodedToken.exp * 1000 - Date.now()
    )
    return () => {
      clearTimeout(timeout)
    }
  }, [decodedToken])
  return {
    isValid: decodedToken && !expired,
    isValidStructure: !!decodedToken,
    expired,
    expiresAt,
    decodedToken
  }
}

export const useValueRef = (value) => {
  const ref = useRef(value)
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref
}

export const useConfirmedScrollToIndex = ({ scrollToIndex, get }) => {
  const timerRef = useRef(null)
  const fn = useCallback(
    (index, ...args) => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
      scrollToIndex(index, ...args)
      timerRef.current = setTimeout(() => {
        const range = get()
        if (!range) {
          return
        }
        const { startIndex, endIndex } = range
        const isVisible = index >= startIndex && index <= endIndex
        if (!isVisible) {
          fn(index, ...args)
        }
      }, 200)
    },
    [scrollToIndex, get]
  )
  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [get])
  return fn
}

const getPrev = property('current.prev')
const getCurr = property('current.curr')

export const useRefWithPrev = (initialValue = null) => {
  const ref = useRef({
    prev: initialValue,
    curr: initialValue
  })
  const setValue = useCallback((value) => {
    ref.current.prev = ref.current.curr
    ref.current.curr = value
  }, [])
  return useMemo(
    () => ({
      setValue,
      ref,
      getPrev: getPrev.bind(null, ref),
      getCurr: getCurr.bind(null, ref)
    }),
    []
  )
}

export const useRefWithEffect = ({ effectFn }) => {
  const ref = useRef(null)
  useEffect(() => {
    return effectFn(ref)
  }, [effectFn])
  return ref
}

const eventSourceMap = new Map()

export const useEventSourceRef = ({ url, enabled = true }) => {
  const id = useId()
  const ref = useRefWithEffect({
    effectFn: useCallback(
      (ref) => {
        if (!enabled) {
          return
        }
        let es
        if (eventSourceMap.has(url)) {
          const data = eventSourceMap.get(url)
          ;({ es } = data)
          data.ids.add(id)
        } else {
          es = new EventSource(url, { withCredentials: true })
          eventSourceMap.set(url, { es, ids: new Set([id]) })
        }
        ref.current = es
        return () => {
          const { es, ids } = eventSourceMap.get(url)
          if (ids.size <= 1) {
            es.close()
            eventSourceMap.delete(url)
          } else {
            ids.delete(id)
          }
        }
      },
      [url, id, enabled]
    )
  })
  return ref
}

import LayoutBase from '@features/layout/components/LayoutBase'
import { useIsMobileScreenSize } from '@features/layout/service/layoutHelpers'

import GuestDesktopHeader from './GuestDesktopHeader'
import GuestMobileHeader from './GuestMobileHeader'

const GuestLayout = ({ children, ...rest }) => {
  const isMobileSize = useIsMobileScreenSize()
  return (
    <LayoutBase {...rest}>
      {isMobileSize ? <GuestMobileHeader /> : <GuestDesktopHeader />}
      {children}
    </LayoutBase>
  )
}

export default GuestLayout

import entityChangementHelpers from './service/entityChangementHelpers'

export default ({ entity, config = [], dependenciesConfig = undefined }) => {
  for (const { changements, listener } of config) {
    for (const changement of changements)
      entityChangementHelpers.addEntityChangementListener(
        entity,
        changement,
        listener
      )
  }
  if (dependenciesConfig) {
    for (const {
      entities,
      changements,
      listener,
      emitChangementOptions
    } of dependenciesConfig) {
      for (const dependency of entities) {
        for (const changement of changements) {
          entityChangementHelpers.addDependencyChangementListener(
            entity,
            dependency,
            changement,
            { listener, emitChangementOptions }
          )
        }
      }
    }
  }
}

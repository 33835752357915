import queryClient from '@infrastructure/reactQuery/queryClient'

import { keys } from '../reactQuery'
import analysesHelpers from '../service/userAnalysesHelpers'

export default {
  entity: analysesHelpers.entityName,
  config: [
    {
      changements: ['all_documents_uploaded'],
      listener: ({ meta: { id } }) => {
        const key = keys.documents(id)
        const old = queryClient.getQueryData(key)
        if (old) {
          queryClient.setQueryData(key, {
            allDocumentsUploaded: true,
            ...old
          })
        }
      }
    },
    {
      changements: ['new_document'],
      listener: ({ meta: { id, fileUrl } }) => {
        const key = keys.detail(id)
        const oldData = queryClient.getQueryData(key)
        if (!oldData) {
          return
        }
        queryClient.setQueryData(key, {
          ...oldData,
          userAnalysesData: {
            ...oldData.userAnalysesData,
            documents: [
              {
                fileUrl,
                processedAt: null
              },
              ...oldData.userAnalysesData.documents
            ]
          }
        })
      }
    },
    {
      changements: ['document_deleted'],
      listener: ({ meta: { id, fileUrl } }) => {
        const key = keys.detail(id)
        const oldData = queryClient.getQueryData(key)
        if (!oldData) {
          return
        }
        queryClient.setQueryData(key, {
          ...oldData,
          userAnalysesData: {
            ...oldData.userAnalysesData,
            documents: oldData.userAnalysesData.documents.filter(
              ({ fileUrl: s }) => s !== fileUrl
            )
          }
        })
      }
    }
  ],
  dependenciesConfig: [
    {
      entities: ['user_analyses_document'],
      changements: ['deleted'],
      listener: ({ meta: { analysesId, filename } }) => {
        const key = keys.documents(analysesId)
        const old = queryClient.getQueryData(key)
        if (old) {
          queryClient.setQueryData(key, {
            ...old,
            documents: old.documents.filter(
              (item) => item.filename !== filename
            )
          })
        }
      }
    }
  ]
}

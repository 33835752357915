import { useSelector } from 'react-redux'

import { createSlice } from '@reduxjs/toolkit'

import { authTypeMap } from '../service/constants'

const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  isError: null,
  authType: 'guest'
}

export const authSlice = createSlice({
  initialState,
  name: 'auth',
  reducers: {
    initialized: (state) => {
      state.isInitialized = true
    },
    login: (state, action) => {
      state.isLoggedIn = true
      state.isError = false
      state.authType = action.payload.authType
    },
    logout: (state) => {
      state.isLoggedIn = false
      state.isError = false
      state.authType = 'guest'
    },
    error: (state) => {
      state.isLoggedIn = false
      state.isError = true
      state.authType = 'guest'
    }
  }
})

export const authActions = authSlice.actions

export const authReducer = authSlice.reducer

export const selectIsLoggedIn = (state) => state.auth.isLoggedIn
export const selectAuthType = (state) => state.auth.authType
export const selectToken = (state) => state.auth.token
export const selectIsAuthInitialized = (state) => state.auth.isInitialized
export const selectIsAuthError = (state) => state.auth.isError

export const selectIsAdminAuth = (state) =>
  selectAuthType(state) === authTypeMap.admin

export const useIsAdminAuth = () => useSelector(selectIsAdminAuth)

export const selectIsUserAuth = (state) =>
  selectAuthType(state) === authTypeMap.user

export const useIsUserAuth = () => useSelector(selectIsUserAuth)

import restApi from '@infrastructure/api/restApi'

export const name = 'user'

export const getMeta = () => restApi.get('/users/me/meta')

export const getDetail = () => restApi.get('/users/me')

export const patchDetail = (data) => restApi.patch('/users/me', data)

export const patchMeta = (data) => restApi.patch('/users/me/meta', data)

// export const sendEmailToSetEmail = () => restApi.get('set-email-email')

// export const sendEmailToSetPassword = () => restApi.get('set-pasword-email')

const userRepo = {
  patchDetail
}

export default userRepo

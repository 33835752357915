import { memo } from 'react'
import { NavLink } from 'react-router-dom'

import useNavItems from '@features/rootNavigation/hooks/useNavItems'

import './style.scss'

const DesktopMenu = () => {
  const items = useNavItems()
  return (
    <div className="header-menu">
      {items.map((item) =>
        item.isExternal ? (
          <a
            key={item.to}
            href={item.to}
            target={item.isNewWindow ? '_blank' : '_self'}
            rel="noopener noreferrer"
            className="header-menu-link"
            style={{ position: 'relative' }}
          >
            {item.title}
            {item.hasNew && <span className="header-menu-badge show"></span>}
          </a>
        ) : (
          <NavLink
            to={item.to}
            key={item.to}
            target={item.isNewWindow ? '_blank' : '_self'}
            className="header-menu-link"
            style={{ position: 'relative' }}
          >
            {item.title}
            {item.hasNew && <span className="header-menu-badge show"></span>}
          </NavLink>
        )
      )}
    </div>
  )
}

export default memo(DesktopMenu)

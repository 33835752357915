import { Device } from '@capacitor/device'
import { cookies } from '@common/constants'
import i18n from 'i18next'
import i18nextHttpBackend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import { localeCookieKey } from '.'
import { localeMap } from '../constants'

const parseLanguage = (tag) => localeMap[tag.split('-')[0]] ?? null

const languageDetector = {
  type: 'languageDetector',
  async: true,
  detect: async (callback) => {
    const cookie = cookies.get(localeCookieKey)
    if (cookie) {
      return callback(cookie)
    }
    const deviceLanguage = parseLanguage((await Device.getLanguageTag()).value)
    if (deviceLanguage) {
      return callback(deviceLanguage)
    }
    return callback(localeMap.uk)
  }
}

i18n
  .use(i18nextHttpBackend)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: false,
    supportedLngs: Object.values(localeMap),
    interpolation: {
      escapeValue: false
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    }
  })

export default i18n

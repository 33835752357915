import SomethingWentWrongPage from '@atoms/SomethingWentWrongPage'
import TestPage from '@common/TestPage'
import { NODE_ENV } from '@common/env'
import { createLazyElement } from '@common/utils'
import HomePage from '@features/HomePage'
import introNavigationConfig from '@features/Intro/navigation/introNavigationConfig'
import ArticleSkeleton from '@features/article/components/ArticlePageSkeleton'
import authNavigationConfig from '@features/auth/navigation/authNavigationConfig'
import { authTypeMap } from '@features/auth/service/constants'
import Layout from '@features/layout/components/Layout'

import RootRoute from '../components/RootRoute'

const rootRoute = {
  element: <RootRoute />,
  errorElement: (
    <Layout>
      <SomethingWentWrongPage />
    </Layout>
  ),
  children: [
    introNavigationConfig,
    authNavigationConfig,
    {
      path: 'articles/:id',
      element: createLazyElement(
        () => import('@features/article/components/ArticleDetailPage'),
        <ArticleSkeleton />
      ),
      handle: {
        breadcrumbTrPath: 'article.ArticleDetailPage.breadcrumb'
      }
    },
    {
      path: 'markers/:id',
      element: createLazyElement(
        () =>
          import(
            '@features/analysesSection/marker/components/MarkerDetailPage.js'
          ),
        <ArticleSkeleton />
      ),
      handle: {
        breadcrumbTrPath: 'analysesSection.MarkerDetailPage.breadcrumb'
      }
    },
    {
      path: 'help',
      element: createLazyElement(() => import('@features/Help/HelpPage'))
    },
    {
      path: '*',
      element: <HomePage />
    }
  ]
}

if (NODE_ENV === 'development') {
  rootRoute.children.push({
    path: 'test',
    handle: {
      authType: [authTypeMap.admin, authTypeMap.guest, authTypeMap.user]
    },
    element: <TestPage />
  })
}

export default rootRoute

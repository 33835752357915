import { merge } from 'lodash-es'

import store from '@features/redux/store'
import { createSlice } from '@reduxjs/toolkit'

import { typeMap } from '../repository'

const initialState = {
  type: typeMap.default,
  desktopHeaderProps: {
    isVisible: false,
    width: 0,
    height: 0
  }
}

export const slice = createSlice({
  initialState,
  name: 'layout',
  reducers: {
    setDesktopHeaderProps: (state, { payload }) => {
      merge(state.desktopHeaderProps, payload)
    },
    setType: (state, { payload }) => {
      if (!payload) {
        return
      }
      state.type = typeMap[payload.type] ?? typeMap.default
    },
    resetType: (state) => {
      state.type = typeMap.default
    }
  }
})

store.reducerManager.add(slice.name, slice.reducer)

export const selectDesktopHeaderProps = (state) =>
  state[slice.name]?.desktopHeaderProps

export const selectType = (state) => state[slice.name]?.type

const layoutRedux = {
  slice,
  selectType,
  selectDesktopHeaderProps
}

export default layoutRedux

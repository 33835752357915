import { memo, useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useDispatch } from 'react-redux'
import { useScrollDirection } from 'use-scroll-direction'

import AuthDesktopHeader from '@features/layout/components/AuthDesktopHeader'
import LayoutBase from '@features/layout/components/LayoutBase'
import { useIsMobileScreenSize } from '@features/layout/service/layoutHelpers'

import { slice } from '../redux'
import MobileMenu from './MobileMenu'

const useCurrOrPrevNotNoneScrollDirection = () => {
  const { scrollDirection } = useScrollDirection()
  const [lastDirection, setLastDireciton] = useState(null)
  useEffect(() => {
    if (scrollDirection === 'NONE') {
      return
    }
    setLastDireciton(scrollDirection)
  }, [scrollDirection])
  return lastDirection
}

const DesktopHeader = memo(function DesktopHeader() {
  const direction = useCurrOrPrevNotNoneScrollDirection()
  const dispatch = useDispatch()
  useEffect(() => {
    return () => {
      dispatch(
        slice.actions.setDesktopHeaderProps({
          isVisible: false,
          sticky: false,
          height: 72
        })
      )
    }
  }, [])
  const [viewRef, inView] = useInView({ initialInView: true })
  useEffect(() => {
    dispatch(
      slice.actions.setDesktopHeaderProps({
        isVisible: !direction || inView || direction === 'UP'
      })
    )
  }, [inView, direction])
  return (
    <>
      <div ref={viewRef} className="header__sticky-stub"></div>
      <AuthDesktopHeader sticky visible={inView || direction === 'UP'} />
    </>
  )
})

const Mobile = memo(function Mobile() {
  const direction = useCurrOrPrevNotNoneScrollDirection()
  return <MobileMenu visible={!direction || direction === 'UP'} />
})

const AuthHugeLayout = ({ children, ...rest }) => {
  const isMobileSize = useIsMobileScreenSize()
  return (
    <LayoutBase
      {...rest}
      style={
        isMobileSize
          ? {
              paddingBottom: '51px'
            }
          : {}
      }
    >
      {!isMobileSize && <DesktopHeader />}
      {children}
      {isMobileSize && <Mobile />}
    </LayoutBase>
  )
}

export default AuthHugeLayout

import { useLocation } from 'react-router-dom'

export const baseSegment = 'home'

export const basePath = '/home'

export const analysesUploadSubPath = 'analyses/:id/upload'

export const createAnalysesUploadPath = (id) => `/home/analyses/${id}/upload`

export const analysesDetailSubPath = 'analyses/:id'

export const createAnalysesPath = (id) => `/home/analyses/${id}`

export const surveyDetailSubPath = 'surveys/:id/*'

export const createSurveyDetailPath = (id) => `/home/surveys/${id}`

export const recommendationDetailSubPath = 'recommendations/:id'

export const createRecommendationDetailPath = (id) =>
  `/home/recommendations/${id}`

export const recommendationGroupDetailSubPath = 'recommendation-groups/:id'

export const createRecommendationSequenceDetailPath = (id) =>
  `/home/recommendation-groups/${id}`

export const recommendationGroupRecommendationDetailSubPath =
  'recommendations/:recommendationId'

export const createRecommendationSequenceRecommendationDetailPath = (
  id,
  recommendationId
) => `/home/recommendation-groups/${id}/recommendations/${recommendationId}`

export const useIsUnderOverview = () => {
  const { pathname } = useLocation()
  return pathname.startsWith(basePath)
}

const overviewNavigation = {
  basePath,
  useIsUnderOverview,
  createAnalysesUploadPath,
  createAnalysesCategoryPath: createAnalysesPath,
  createSurveyDetailPath,
  createRecommendationDetailPath,
  createRecommendationSequenceDetailPath,
  createRecommendationSequenceRecommendationDetailPath
}

export default overviewNavigation

import classNames from 'classnames'
import { memo } from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { FillingSkeleton } from '@atoms/Skeleton'

import './style.scss'

export const ArticleContentSkeleton = () => (
  <div className="text">
    <p>
      <Skeleton count={8} />
    </p>
    <div className="article-skeleton__c-image-container">
      <Skeleton containerClassName="article-skeleton__c-image" height="100%" />
    </div>
    <p>
      <Skeleton count={4} />
    </p>
    <p>
      <Skeleton count={5} />
    </p>
  </div>
)

export default memo(function ArticleSkeleton({ underMedia }) {
  return (
    <>
      <div
        className={classNames(
          'article-skeleton',
          'image-hero',
          'image-hero-article'
        )}
      >
        <div className="image-hero-inner">
          <FillingSkeleton />
        </div>
      </div>
      {underMedia}
      <div className="section-wrapper article-skeleton">
        <h2 className="section-title">
          <Skeleton />
        </h2>
        <div className="text">
          <p>
            <Skeleton count={8} />
          </p>
          <div className="article-skeleton__c-image-container">
            <Skeleton
              containerClassName="article-skeleton__c-image"
              height="100%"
            />
          </div>
          <p>
            <Skeleton count={4} />
          </p>
          <p>
            <Skeleton count={5} />
          </p>
        </div>
      </div>
    </>
  )
})

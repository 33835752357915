import classNames from 'classnames'
import { useSelector } from 'react-redux'

import { selectAuthType } from '@features/auth/redux/authSlice'
import { authTypeMap } from '@features/auth/service/constants'
import BackButton from '@features/rootNavigation/components/BackButton'
import { useCurrentBreadcrumb } from '@features/rootNavigation/service/navigationHooks'
import userRQ from '@features/user/reactQuery'
import { memo } from 'react'
export default memo(function AuthMobileHeader({ className }) {
  const currentBreadCrumb = useCurrentBreadcrumb()
  const authType = useSelector(selectAuthType)
  const { data: { email } = {} } = userRQ.useDetailQ()
  return (
    <header className={classNames(className, 'header-mobile')}>
      <BackButton className="header-mobile__back-btn" />
      <div className="header-mobile-center" style={{ flexDirection: 'column' }}>
        <h6 className="header-mobile-title">
          {currentBreadCrumb?.breadcrumb ?? 'Biobetter'}
        </h6>
        {authType === authTypeMap.admin && (
          <span
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden'
            }}
          >
            Impersonating {email ?? '...'}
          </span>
        )}
      </div>
    </header>
  )
})

import { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import {
  GuidelineCardPopup,
  GuidelineCardPopupOkButton
} from '@atoms/GuidelineCard'
import { PopupProvider } from '@atoms/Popup'
import userRQ from '@features/user/reactQuery'

import RecommendationFeedContentsButton from './RecommendationFeedContentsButton'

export default memo(function RecFeedContentsGuidePopup() {
  const [t] = useTranslation()
  const { isPending, mutate } = userRQ.usePatchDetailM()
  const onOpenedChange = useCallback(
    (opened) => {
      if (opened) {
        return
      }
      mutate({ data: { showRecFeedContentsGuide: false } })
    },
    [mutate]
  )
  return (
    <PopupProvider
      initialOpened
      onOpenedChange={onOpenedChange}
      placement="bottom-start"
    >
      <GuidelineCardPopup
        reference={<RecommendationFeedContentsButton />}
        heading={t('recommendationFeed.RecFeedContentsGuidePopup.heading')}
        description={t(
          'recommendationFeed.RecFeedContentsGuidePopup.description'
        )}
        actions={<GuidelineCardPopupOkButton disabled={isPending} />}
      />
    </PopupProvider>
  )
})

import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import restApi from '@infrastructure/api/restApi'

export const name = 'userDay'

export const getDay = (date) => restApi.get(`/user-day/day/${date}`)

export const getStats = (query) =>
  restApi.get('/user-day/stats', {
    params: query
  })

export const toggleCompletedTodoWhenById = (id, date) =>
  restApi.patch(`/user-day/todos/whens/${id}/toggle-completed/${date}`)

export const toggleActivatedTodoById = (id) =>
  restApi.patch(`/user-day/todos/${id}/toggle-activated`)

export const getActivatableTodos = () => restApi.get('/user-day/activatable')

export function useMakeTodoTitle() {
  const [t] = useTranslation()
  return useCallback(
    ({ type, todoTask, supplementDose, supplementDoseUnit, supplement }) => {
      return type === todoTypeMap.task
        ? t('userDay.todoAsTaskTitle', {
            taskTitle: todoTask?.title ?? '?'
          })
        : t('userDay.todoAsSupplementTitle', {
            supplementTitle: supplement?.title ?? '?',
            value: supplementDose?.value ?? '?',
            unitTitle: supplementDoseUnit?.title ?? '?'
          })
    },
    [t]
  )
}

export const todoTypeMap = {
  task: 'task',
  supplement: 'supplement'
}

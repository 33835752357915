import analysesChangementConfig from '@features/analysesSection/analyses/entityChangement'
import userSurveyChangementConfig from '@features/userSurvey/entityChangement/userSurveyChangementConfig'

import configureEntityChangement from './configureEntityChangement'
import entityChangementHelpers from './service/entityChangementHelpers'

configureEntityChangement(analysesChangementConfig)

configureEntityChangement(userSurveyChangementConfig)

export default (data) => {
  const { name, type, meta } = data
  entityChangementHelpers.eventEmitter.emit(
    entityChangementHelpers.getEntityChangementEventName(name, type),
    { ...data, meta: meta ?? {} },
    {}
  )
}

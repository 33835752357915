import { useMediaQuery } from 'react-responsive'

export const useIsDesktopScreenSize = () =>
  useMediaQuery({
    query: '(min-width: 744px)'
  })

export const useIsMobileScreenSize = () =>
  useMediaQuery({
    query: '(max-width: 743px)'
  })

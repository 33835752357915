export function CheckmarkIcon(props) {
  return (
    <svg
      viewBox="0 0 11 8"
      fill="none"
      stroke="white"
      strokeLinecap="round"
      strokeWidth="2"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M1 4L4 7" />
      <path d="M10 1L4 7" />
    </svg>
  )
}

export {
  ReactComponent as IconMyDayCalendar,
  default as iconMyDayCalendarSrc
} from '@assets/images/icons/my-day-calendar.svg'

export {
  ReactComponent as IconModalClose,
  default as iconModalCloseSrc
} from '@assets/images/icons/dialog-close.svg'

export {
  ReactComponent as IconAngleRight,
  default as IconAngleRightSrc
} from '@assets/images/icons/angle-right.svg'

export {
  ReactComponent as IconCalendar2,
  default as IconCalendar2Src
} from '@assets/images/icons/calendar-2.svg'

export {
  ReactComponent as IconSheetAndCheck,
  default as IconSheetAndCheckSrc
} from '@assets/images/icons/sheet-and-check.svg'

export {
  ReactComponent as IconNoteSheet,
  default as IconNoteSheetSrc
} from '@assets/images/icons/note-sheet.svg'

export {
  ReactComponent as IconCheckedSheet,
  default as IconCheckedSheetSrc
} from '@assets/images/icons/checked-sheet.svg'

export {
  ReactComponent as IconWebcamera,
  default as IconWebcameraSrc
} from '@assets/images/icons/webcamera.svg'

export {
  ReactComponent as IconBankCard,
  default as IconBankCardSrc
} from '@assets/images/icons/bank-card.svg'

export {
  ReactComponent as IconAnalysesJar,
  default as IconAnalysesJarSrc
} from '@assets/images/icons/analyses-jar.svg'

export {
  ReactComponent as IconMastercardCircles,
  default as IconMastercardCirclesSrc
} from '@assets/images/icons/mastercard-circles.svg'

export {
  ReactComponent as IconDiscountLabel,
  default as IconDiscountLabelSrc
} from '@assets/images/icons/discount-label.svg'

export {
  ReactComponent as IconCancel,
  default as IconCancelSrc
} from '@assets/images/icons/cancel.svg'

export {
  ReactComponent as IconSwitch,
  default as IconSwitchSrc
} from '@assets/images/icons/switch.svg'

export {
  ReactComponent as IconStarSelected,
  default as IconStarSelectedSrc
} from '@assets/images/icons/star-selected.svg'

export {
  ReactComponent as IconListBullet,
  default as IconListBulletSrc
} from '@assets/images/icons/list-bullet.svg'

export {
  ReactComponent as IconStar,
  default as IconStarSrc
} from '@assets/images/icons/star.svg'

export {
  ReactComponent as IconDNA,
  default as IconDNASrc
} from '@assets/images/icons/dna.svg'

export {
  ReactComponent as IconChat,
  default as IconChatSrc
} from '@assets/images/icons/chat.svg'

export {
  ReactComponent as BackIcon,
  default as BackIconSrc
} from '@assets/images/icons/back.svg'

export {
  ReactComponent as BBAvatarIcon,
  default as BBAvatarIconSrc
} from '@assets/images/icons/avatar_bb.svg'

export {
  ReactComponent as TelegramIcon,
  default as TelegramIconSrc
} from '@assets/images/icons/telegram.svg'

export {
  ReactComponent as MessengerIcon,
  default as MessengerIconSrc
} from '@assets/images/icons/messenger.svg'

export {
  ReactComponent as EmailIcon,
  default as EmailIconSrc
} from '@assets/images/icons/email.svg'

export {
  ReactComponent as ArrowUpIcon,
  default as ArrowUpIconSrc
} from '@assets/images/icons/arrow-up.svg'

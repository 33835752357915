import { BarLoader } from 'react-spinners'
import AnalysesDocumentCardBody from '../AnalysesDocumentCardBody'
import './style.scss'

export default function UploadingUserAnalysesDocumentCard({ progressCoef }) {
  return (
    <AnalysesDocumentCardBody className="uploading-u-a-d-card">
      <div className="account-tests-download-progress uploading-u-a-d-card__progress">
        {`${progressCoef * 100}%`}
      </div>
      <BarLoader className="uploading-u-a-d-card__loader" color="#8ACBDA" />
    </AnalysesDocumentCardBody>
  )
}

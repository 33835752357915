import { EventEmitter } from 'eventemitter3'

import { dotsJoin } from '@common/utils'

const eventEmitter = new EventEmitter()

const getEntityChangementEventName = (entity, changement) =>
  dotsJoin(entity, changement)

const getDependencyChangementEventListener = (
  entity,
  {
    listener = undefined,
    emitChangementOptions: {
      isEnabled = false,
      processData = undefined,
      changement: emitChangement = undefined
    } = {}
  }
) =>
  isEnabled
    ? async (data, context) => {
        context = {
          ...context,
          isInternal: true
        }
        await listener?.(data, context)
        eventEmitter.emit(
          getEntityChangementEventName(entity, emitChangement),
          ...[processData?.(data, context) ?? data, context]
        )
      }
    : listener

const addEntityChangementListener = (entity, changement, listener) => {
  eventEmitter.addListener(
    getEntityChangementEventName(entity, changement),
    listener
  )
}

const addDependencyChangementListener = (
  entity,
  dependency,
  changement,
  { emitChangementOptions = undefined, listener = undefined }
) => {
  eventEmitter.addListener(
    getEntityChangementEventName(dependency, changement),
    getDependencyChangementEventListener(entity, {
      listener,
      emitChangementOptions
    })
  )
}

export default {
  eventEmitter,
  getEntityChangementEventName,
  addEntityChangementListener,
  addDependencyChangementListener
}

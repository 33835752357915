import classNames from 'classnames'

import { forwardRef } from 'react'
import scss from './index.module.scss'

export const Page = forwardRef(function Page(
  { As = 'main', className, children, variant, ...rest },
  ref
) {
  return (
    <As
      ref={ref}
      className={classNames(className, scss.page, scss[variant])}
      {...rest}
    >
      {children}
    </As>
  )
})

export const PageContentSpacing = forwardRef(function PageContentSpacing(
  { As = 'div', className, type = 'padding', children, ...rest },
  ref
) {
  return (
    <As
      ref={ref}
      className={classNames(className, scss.contentSpacing, scss[type])}
      {...rest}
    >
      {children}
    </As>
  )
})

export const PageContentContainer = forwardRef(function PageContentContainer(
  { As = 'section', className, size = 'md', children, ...rest },
  ref
) {
  return (
    <As
      ref={ref}
      className={classNames(className, scss.contentContainer, scss[size])}
      {...rest}
    >
      {children}
    </As>
  )
})

import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

import { homePathByAuthType } from '@features/rootNavigation/service/navigationHelpers'

import { selectAuthType } from './auth/redux/authSlice'

const HomePage = () => {
  const actualAuthType = useSelector(selectAuthType)
  return <Navigate to={homePathByAuthType[actualAuthType]} />
}

export default HomePage

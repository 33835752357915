import classNames from 'classnames'

import './style.scss'

const LayoutBase = ({ className = undefined, children, ...rest }) => {
  return (
    <div className={classNames('layout', className)} {...rest}>
      {children}
    </div>
  )
}

export default LayoutBase

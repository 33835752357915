import configureEntityChangement from '@infrastructure/entityChangement/configureEntityChangement'
import queryClient from '@infrastructure/reactQuery/queryClient'

import { keys } from '../reactQuery'
import {
  acquiringName,
  name,
  orderAcquiringStatusMap,
  userPackageName
} from '../repository'

configureEntityChangement({
  entity: name,
  dependenciesConfig: [
    {
      entities: [acquiringName],
      changements: ['updated'],
      listener: ({ meta: { status, orderId } }) => {
        queryClient.invalidateQueries({ queryKey: keys.order(orderId) })
        queryClient.invalidateQueries({
          queryKey: keys.acquiringsHistory()
        })
        switch (status) {
          case orderAcquiringStatusMap.success: {
            queryClient.invalidateQueries({
              queryKey: keys.currentUserPackages()
            })
          }
        }
      }
    },
    {
      entities: [userPackageName],
      changements: ['created'],
      listener: () => {
        queryClient.invalidateQueries({
          queryKey: keys.currentUserPackages()
        })
      }
    }
  ]
})

import axios from 'axios'

import { API_URL } from '@common/env'

const restApi = axios.create({
  baseURL: API_URL,
  withCredentials: true
})

export default restApi

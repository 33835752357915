import { produce } from 'immer'
import { find, merge, reject } from 'lodash-es'

import userAnalysesHelpers from '@features/analysesSection/analyses/service/userAnalysesHelpers'
import medicalConclusionRepository from '@features/medicalConclusions/repository'
import userSurveyHelpers from '@features/userSurvey/service/userSurveyHelpers'
import configureEntityChangement from '@infrastructure/entityChangement/configureEntityChangement'
import queryClient from '@infrastructure/reactQuery/queryClient'

import { keys } from '../reactQuery'

const keyByName = {
  [userAnalysesHelpers.entityName]: 'userAnalyses',
  [userSurveyHelpers.entityName]: 'userSurveys',
  [medicalConclusionRepository.name]: 'medicalConclusion'
}

const modifyItem = (overviewData, prop, predicate, modifiedData) => {
  const item = find(overviewData[prop], predicate)
  merge(item, modifiedData)
  return item
}

configureEntityChangement({
  entity: 'overview',
  dependenciesConfig: [
    {
      entities: [
        userAnalysesHelpers.entityName,
        medicalConclusionRepository.name,
        userSurveyHelpers.entityName
      ],
      changements: ['viewed'],
      listener: ({ meta, name }) => {
        const { id, viewedAt, viewedByCustomerAt } = meta
        const prop = keyByName[name]
        const data = queryClient.getQueryData(keys.all)
        if (!data) {
          return
        }
        queryClient.setQueryData(
          keys.all,
          produce(data, (draft) => {
            modifyItem(draft, prop, ['id', id], {
              viewedAt,
              viewedByCustomerAt
            })
          })
        )
      }
    },
    {
      entities: [userAnalysesHelpers.entityName],
      changements: ['all_documents_uploaded', 'examined'],
      listener: () => {
        queryClient.invalidateQueries({ queryKey: keys.all })
      }
    },
    {
      entities: [medicalConclusionRepository.name],
      changements: ['nextStepStarted', 'currentStepFinished'],
      listener: () => {
        queryClient.invalidateQueries({ queryKey: keys.all })
      }
    },
    {
      entities: [medicalConclusionRepository.stageName],
      changements: ['updated'],
      listener: () => {
        queryClient.invalidateQueries({ queryKey: keys.all })
      }
    },
    {
      entities: [userSurveyHelpers.entityName, userAnalysesHelpers.entityName],
      changements: ['created'],
      listener: () => {
        queryClient.invalidateQueries({ queryKey: keys.all })
      }
    },
    {
      entities: [userSurveyHelpers.entityName],
      changements: ['passed'],
      listener: ({ meta }) => {
        const { id } = meta
        const data = queryClient.getQueryData(keys.all)
        if (!data) {
          return
        }
        queryClient.setQueryData(
          keys.all,
          produce(data, (draft) => {
            draft.userSurveys = reject(draft.userSurveys, ['id', id])
          })
        )
      }
    }
  ]
})

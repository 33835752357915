import urlJoin from 'url-join'

export const thumbnail180 = {
  width: 180,
  height: 180
}

export const thumbnail360 = {
  width: 360,
  height: 360
}

export const thumbnail540 = {
  width: 540,
  height: 540
}

export const thumbnail720 = {
  width: 720,
  height: 720
}

export const thumbnails = [
  thumbnail180,
  thumbnail360,
  thumbnail540,
  thumbnail720
]

const createThumbnailString = ({ width, height }) =>
  `${width ?? height}x${height ?? width}`

export const createThumbnailUrl = (imageUrl, thumbnail) => {
  return urlJoin(imageUrl, `?thumbnail=${createThumbnailString(thumbnail)}`)
}

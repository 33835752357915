import { compact, flow, groupBy, keyBy, property, uniq } from 'lodash-es'
import { memo, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import WaitingEn from '@assets/images/content/recommendations-waiting-picture-en.png'
import SomethingWentWrongPage from '@atoms/SomethingWentWrongPage'
import UserAnalysesCard from '@features/analysesSection/analyses/components/UserAnalysesCard'
import { selectIsAdminAuth } from '@features/auth/redux/authSlice'
import ConclusionCard from '@features/medicalConclusions/components/ConclusionCard'
import PurchaseServicePackageCard from '@features/servicePackage/components/PurchaseServicePackageCard'
import servicePackageRQ from '@features/servicePackage/reactQuery'
import userRQ from '@features/user/reactQuery'
import UserSurveyCard from '@features/userSurvey/components/UserSurveyCard'

import { formatISODate } from '@common/utils'
import { useDataQ } from '../reactQuery'
import DateAssignmentList from './DateAssignmentList'
import HowItWorksGuide from './HowItWorksGuideCard'
import OverviewPageSkeleton from './OverviewPageSkeleton'

const groupByIteratee = flow(property('assignedAt'), formatISODate)

const select = (
  { medicalConclusion, userAnalyses, userSurveys, surveys, markerCategories },
  {
    createdAt,
    showHowItWorksGuide,
    purchaseServicePackageAssignedAt,
    purchaseServicePackageViewed
  }
) => {
  const categoriesById = keyBy(markerCategories, 'id')
  const surveysById = keyBy(surveys, 'id')
  userAnalyses = userAnalyses.map(({ categoryId, ...rest }) => ({
    ...rest,
    category: categoriesById[categoryId]
  }))
  userSurveys = userSurveys.map(({ surveyId, ...rest }) => ({
    ...rest,
    survey: surveysById[surveyId]
  }))
  const userAnalysesByDate = groupBy(userAnalyses, groupByIteratee)
  const userSurveysByDate = groupBy(userSurveys, groupByIteratee)
  const medicalConclusionDateStr =
    medicalConclusion && formatISODate(medicalConclusion.startedAt)
  const createdDateStr = showHowItWorksGuide && formatISODate(createdAt)
  const servPackageAssignedDateStr =
    purchaseServicePackageAssignedAt &&
    formatISODate(purchaseServicePackageAssignedAt)
  const dateStrs = uniq(
    compact([
      ...Object.keys(userSurveysByDate),
      ...Object.keys(userAnalysesByDate),
      medicalConclusionDateStr,
      createdDateStr,
      servPackageAssignedDateStr
    ])
  ).sort((a, b) => (a < b ? 1 : a > b ? -1 : 0))
  return dateStrs.map((dateStr) => ({
    date: new Date(dateStr),
    userAnalyses: userAnalysesByDate[dateStr] ?? [],
    medicalConclusion:
      medicalConclusionDateStr === dateStr ? medicalConclusion : null,
    userSurveys: userSurveysByDate[dateStr] ?? [],
    showHowItWorksGuide: showHowItWorksGuide && createdDateStr === dateStr,
    showPurchaseServicePackage:
      servPackageAssignedDateStr && servPackageAssignedDateStr === dateStr,
    purchaseServicePackageViewed
  }))
}

const imageByLang = {
  uk: WaitingEn,
  en: WaitingEn
}

const NoData = () => {
  const [t, i18n] = useTranslation()
  const lang = i18n.language
  return (
    <section className="section">
      <div className="section-wrapper">
        <h2 className="section-title">
          {t('overview.OverviewPage.noDataMsg')}
        </h2>
        <div className="section-subtitles">
          <div
            className="section-subtitle"
            style={{
              whiteSpace: 'pre-line'
            }}
          >
            {t('overview.OverviewPage.waitNewDataMsg')}
          </div>
        </div>
        <div className="recommendations-waiting-picture">
          <img src={imageByLang[lang]} alt="Suggestions preview" />
        </div>
      </div>
    </section>
  )
}

export default memo(function OverviewPage() {
  const {
    isLoading: isUserLoading,
    data: {
      hasNewOverviewData,
      createdAt,
      showHowItWorksGuide,
      purchaseServicePackageAssignedAt,
      purchaseServicePackageViewed
    } = {}
  } = userRQ.useDetailQ()
  const { isLoading: servicePackageLoading, data: servicePackage } =
    servicePackageRQ.useFirstAcquirablePreviewQ({
      enabled: !!purchaseServicePackageAssignedAt
    })
  const {
    isLoading,
    isError,
    data: datesData = []
  } = useDataQ({
    select: useCallback(
      (state) =>
        isUserLoading
          ? []
          : select(state, {
              createdAt,
              showHowItWorksGuide,
              purchaseServicePackageAssignedAt,
              purchaseServicePackageViewed
            }),
      [
        isUserLoading,
        createdAt,
        showHowItWorksGuide,
        purchaseServicePackageAssignedAt,
        purchaseServicePackageViewed
      ]
    )
  })
  const isAdminAsUser = useSelector(selectIsAdminAuth)
  const { mutate } = userRQ.usePatchDetailM()
  useEffect(() => {
    if (isAdminAsUser || !hasNewOverviewData) {
      return
    }
    mutate({ data: { hasNewOverviewData: false } })
  }, [hasNewOverviewData])

  return isLoading ? (
    <OverviewPageSkeleton />
  ) : isError ? (
    <SomethingWentWrongPage />
  ) : datesData.length ? (
    <section className="section account-reports-report">
      <div className="section-wrapper section-wrapper-400">
        {datesData.map(
          ({
            date,
            medicalConclusion,
            userAnalyses,
            userSurveys,
            showHowItWorksGuide,
            showPurchaseServicePackage,
            purchaseServicePackageViewed
          }) => {
            return (
              <DateAssignmentList
                key={date}
                date={date}
                subtitle=""
                cards={compact([
                  showHowItWorksGuide && <HowItWorksGuide key={1} />,
                  medicalConclusion && (
                    <ConclusionCard
                      key={medicalConclusion.id}
                      data={medicalConclusion}
                    />
                  ),
                  ...userAnalyses.map((item) => (
                    <UserAnalysesCard key={item.id} data={item} />
                  )),
                  ...userSurveys.map((item) => (
                    <UserSurveyCard key={item.id} data={item} />
                  )),
                  showPurchaseServicePackage && !servicePackageLoading && (
                    <PurchaseServicePackageCard
                      key={2}
                      {...servicePackage}
                      viewed={purchaseServicePackageViewed}
                    />
                  )
                ])}
              />
            )
          }
        )}
      </div>
    </section>
  ) : (
    <NoData />
  )
})

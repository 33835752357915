/* eslint-disable no-undef */
import { pick, transform } from 'lodash-es'

// const parseBoolString = (str) => !!str && (str === 'true' || str === 't')

export const { REACT_APP_FIREBASE_CONFIG: FIREBASE_CONFIG } = transform(
  pick(process.env, ['REACT_APP_FIREBASE_CONFIG']),
  (acc, val, key) => {
    acc[key] = val ? JSON.parse(val) : null
  }
)

export const {
  NODE_ENV,
  SENTRY_ENV,
  REACT_APP_API_SOCKET_URL: API_SOCKET_URL,
  REACT_APP_API_URL: API_URL,
  REACT_APP_ENV: ENV
} = process.env

console.log(
  JSON.stringify({
    NODE_ENV,
    SENTRY_ENV,
    API_SOCKET_URL,
    API_URL
  })
)

import '@features/layout'
import 'react-toastify/dist/ReactToastify.css'
import './assets/styles/main.scss'

import GlobalLoader from '@atoms/GlobalLoader'
import { NODE_ENV } from '@common/env'
import { authTypeMap } from '@features/auth/service/constants'
import queryClient from '@infrastructure/reactQuery/queryClient'
import { QueryClientProvider } from '@tanstack/react-query'
import { get } from 'lodash-es'
import { Suspense } from 'react'
import TagManager from 'react-gtm-module'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import AppLoader from './AppLoader'
import authService, { AUTH_EVENTS } from './features/auth/authService'
import { authActions } from './features/auth/redux/authSlice'
import store from './features/redux/store'

authService.addListener(AUTH_EVENTS.initialized, () => {
  store.dispatch(authActions.initialized())
})
authService.addListener(AUTH_EVENTS.login, (authData) => {
  const authType = get(authTypeMap, authData.authType)
  if (!authType) {
    return
  }
  store.dispatch(
    authActions.login({
      authType
    })
  )
})
authService.addListener(AUTH_EVENTS.logout, () => {
  store.dispatch(authActions.logout())
})
authService.addListener(AUTH_EVENTS.error, () => {
  store.dispatch(authActions.error())
})

if (NODE_ENV !== 'development') {
  TagManager.initialize({
    gtmId: 'GTM-KXCCS8G9',
    events: {
      'gtm.start': new Date().getTime(),
      event: 'gtm.js'
    }
  })
}

const App = () => {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <Suspense fallback={<GlobalLoader />}>
          <AppLoader />
        </Suspense>
        <ToastContainer newestOnTop />
      </QueryClientProvider>
    </Provider>
  )
}

export default App

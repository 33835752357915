import { groupBy, property } from 'lodash-es'
import { createSelector } from 'reselect'

import { byId } from '@common/utils'
import queryClient from '@infrastructure/reactQuery/queryClient'
import { createEntityAdapter } from '@reduxjs/toolkit'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import {
  getActivatableTodos,
  getDay,
  getStats,
  todoTypeMap,
  toggleActivatedTodoById,
  toggleCompletedTodoWhenById
} from '../repository'

export const keys = {
  all: ['userDay'],
  activatableTodos: () => [...keys.all, 'activatableTodos'],
  days: () => [...keys.all, 'days'],
  day: (date) => [...keys.days(), date],
  stats: () => [...keys.all, 'stats']
}

const concTodoWhenAdapter = createEntityAdapter()

export const concTodoWhenSelectors = concTodoWhenAdapter.getSelectors(
  ({ medConcStgGrRecTodoWhenState }) => medConcStgGrRecTodoWhenState
)

const concTodoStatAdapter = createEntityAdapter({
  selectId: property('date')
})

export const concTodoStatSelectors = concTodoStatAdapter.getSelectors()

export const selectTodoWhens = ({ todoWhens }) => todoWhens

export const selectTodoWhenById = (state, id) =>
  selectTodoWhens(state).find((item) => item.id === id)

export const selectTodoWhensByWhenId = createSelector(
  [concTodoWhenSelectors.selectAll],
  (concTodoWhens) => groupBy(concTodoWhens, 'whenId')
)

export const createSelectExtendedTodoById = (selectById) =>
  createSelector(
    [
      selectById,
      createSelector([property('medConcStgGrRecTodoAsTasks')], byId),
      createSelector([property('medConcStgGrRecTodoAsSupplements')], byId),
      createSelector([property('todoTasks')], byId),
      createSelector([property('supplements')], byId),
      createSelector([property('supplementDoses')], byId),
      createSelector([property('supplementDoseUnits')], byId)
    ],
    (
      medConcStgGrRecTodo,
      medConcStgGrRecTodoAsTaskMap,
      medConcStgGrRecTodoAsSupplementMap,
      todoTaskMap,
      supplementMap,
      supplementDoseMap,
      supplementDoseUnitMap
    ) =>
      medConcStgGrRecTodo
        ? {
            medConcStgGrRecTodo,
            medConcStgGrRecTodoAsTask:
              medConcStgGrRecTodo.type === todoTypeMap.task
                ? medConcStgGrRecTodoAsTaskMap[medConcStgGrRecTodo.id]
                : null,
            medConcStgGrRecTodoAsSupplement:
              medConcStgGrRecTodo.type === todoTypeMap.supplement
                ? medConcStgGrRecTodoAsSupplementMap[medConcStgGrRecTodo.id]
                : null,
            todoTask:
              medConcStgGrRecTodo.type === todoTypeMap.task
                ? todoTaskMap[
                    medConcStgGrRecTodoAsTaskMap[medConcStgGrRecTodo.id].taskId
                  ]
                : null,
            supplementDose:
              medConcStgGrRecTodo.type === todoTypeMap.supplement
                ? supplementDoseMap[
                    medConcStgGrRecTodoAsSupplementMap[medConcStgGrRecTodo.id]
                      .supplementDoseId
                  ]
                : null,
            supplement:
              medConcStgGrRecTodo.type === todoTypeMap.supplement
                ? supplementMap[
                    supplementDoseMap[
                      medConcStgGrRecTodoAsSupplementMap[medConcStgGrRecTodo.id]
                        .supplementDoseId
                    ].supplementId
                  ]
                : null,
            supplementDoseUnit:
              medConcStgGrRecTodo.type === todoTypeMap.supplement
                ? supplementDoseUnitMap[
                    supplementDoseMap[
                      medConcStgGrRecTodoAsSupplementMap[medConcStgGrRecTodo.id]
                        .supplementDoseId
                    ].unitId
                  ]
                : null
          }
        : null
  )

const concTodoAdapter = createEntityAdapter()

export const concTodoSelectors = concTodoAdapter.getSelectors(
  property('medConcStgGrRecTodoState')
)

export const selectExtendedConcTodoById = createSelectExtendedTodoById(
  concTodoSelectors.selectById
)

const concActivatableTodoAdapter = createEntityAdapter()

export const concActivatableTodoSelectors =
  concActivatableTodoAdapter.getSelectors(property('medConcStgGrRecTodoState'))

export const selectExtendedConcActivatableTodoById =
  createSelectExtendedTodoById(concActivatableTodoSelectors.selectById)

export const useDayQ = (date, options) =>
  useQuery({
    queryKey: keys.day(date),
    queryFn: async () => {
      const {
        data: { medConcStgGrRecTodos, medConcStgGrRecTodoWhens, ...rest }
      } = await getDay(date)
      return {
        medConcStgGrRecTodoState: concTodoAdapter.addMany(
          concTodoAdapter.getInitialState(),
          medConcStgGrRecTodos
        ),
        medConcStgGrRecTodoWhenState: concTodoWhenAdapter.addMany(
          concTodoWhenAdapter.getInitialState(),
          medConcStgGrRecTodoWhens
        ),
        ...rest
      }
    },
    ...options
  })

export const useStatsQ = (options) =>
  useQuery({
    queryKey: keys.stats(),
    queryFn: async () => {
      const { data } = await getStats()
      return concTodoStatAdapter.addMany(
        concTodoStatAdapter.getInitialState(),
        data
      )
    },
    ...options
  })

export const useActivatableTodosQ = (options) =>
  useQuery({
    queryKey: keys.activatableTodos(),
    queryFn: async () => {
      const {
        data: { medConcStgGrRecTodos, ...rest }
      } = await getActivatableTodos()
      return {
        medConcStgGrRecTodoState: concActivatableTodoAdapter.addMany(
          concActivatableTodoAdapter.getInitialState(),
          medConcStgGrRecTodos
        ),
        ...rest
      }
    },
    ...options
  })

export const useToggleCompletedTodoWhenM = (options) => {
  const queryClient = useQueryClient()
  return useMutation({
    ...options,
    mutationFn: ({ id, date }) => toggleCompletedTodoWhenById(id, date),
    onMutate: async ({ id, date }) => {
      const dayKey = keys.day(date)
      const statsKey = keys.stats()
      const oldDayData = queryClient.getQueryData(dayKey)
      if (!oldDayData) {
        return null
      }
      const oldStatsData = queryClient.getQueryData(statsKey)
      await queryClient.cancelQueries({ queryKey: dayKey })
      await queryClient.cancelQueries({ queryKey: statsKey })
      const oldWhen = concTodoWhenSelectors.selectById(oldDayData, id)
      const oldStat = concTodoStatSelectors.selectById(oldStatsData, date)
      queryClient.setQueryData(dayKey, {
        ...oldDayData,
        medConcStgGrRecTodoWhenState: concTodoWhenAdapter.updateOne(
          oldDayData.medConcStgGrRecTodoWhenState,
          {
            id,
            changes: {
              isCompleted: !oldWhen.isCompleted
            }
          }
        )
      })
      queryClient.setQueryData(
        statsKey,
        concTodoStatAdapter.updateOne(oldStatsData, {
          id: date,
          changes: {
            completedWhenCount:
              oldStat.completedWhenCount + (oldWhen.isCompleted ? -1 : 1)
          }
        })
      )
      return {
        dayKey,
        oldDayData,
        statsKey,
        oldStatsData
      }
    },
    onError: (err, data, context) => {
      if (!context) {
        return
      }
      const { dayKey, oldDayData, statsKey, oldStatsData } = context
      queryClient.setQueryData(dayKey, oldDayData)
      queryClient.setQueryData(statsKey, oldStatsData)
    }
  })
}

export const useToggleActivatedTodoM = (options) =>
  useMutation({
    mutationFn: ({ id }) => toggleActivatedTodoById(id),
    onMutate: async ({ id }) => {
      const rQKey = keys.activatableTodos()
      const old = queryClient.getQueryData(rQKey)
      await queryClient.cancelQueries({ queryKey: rQKey })
      if (!old) {
        return null
      }
      queryClient.setQueryData(rQKey, {
        ...old,
        medConcStgGrRecTodoState: concActivatableTodoAdapter.updateOne(
          old.medConcStgGrRecTodoState,
          {
            id,
            changes: {
              activatedAt: concActivatableTodoSelectors.selectById(old, id)
                .activatedAt
                ? null
                : new Date().toISOString()
            }
          }
        )
      })
      return { rQKey, old }
    },
    onError: (err, data, context) => {
      if (!context) {
        return
      }
      const { rQKey, old } = context
      queryClient.setQueryData(rQKey, old)
    },
    ...options
  })

import classNames from 'classnames'
import { Link } from 'react-router-dom'

import { IconAngleRight } from '@atoms/Icons'
import articleHelpers from '@features/article/service/articleHelpers'

const AnalysesPanelMarkerListItem = ({ articleId, title, result }) => {
  return (
    <Link
      to={articleId && articleHelpers.getDetailUrl(articleId)}
      className={classNames('list-item', {
        done: !!result
      })}
    >
      <div className="list-item-left">
        <div className="list-item-title" style={{ width: '100%' }}>
          {title}
        </div>
      </div>
      <div className="list-item-right">
        <img
          className="list-item-arrow"
          src={IconAngleRight}
          alt="angle-right"
        />
      </div>
    </Link>
  )
}

export default AnalysesPanelMarkerListItem

import { getBrowserTimeZone, getDataProp } from '@common/utils'
import queryClient from '@infrastructure/reactQuery/queryClient'
import { useMutation, useQuery } from '@tanstack/react-query'

import {
  selectIsLoggedIn,
  useIsAdminAuth
} from '@features/auth/redux/authSlice'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getDetail, getMeta, name, patchDetail, patchMeta } from '../repository'

export const keys = {
  all: [name],
  meta: () => [...keys.all, 'meta'],
  detail: () => [...keys.all, 'detail'],
  patchDetail: () => [...keys.all, 'patchDetail'],
  patchMeta: () => [...keys.all, 'patchMeta']
}

queryClient.setQueryDefaults(keys.meta(), {
  queryFn: () => getMeta().then(getDataProp)
})

export const useMetaQ = (options) =>
  useQuery({
    queryKey: keys.meta(),
    ...options
  })

queryClient.setQueryDefaults(keys.detail(), {
  queryFn: () => getDetail().then(getDataProp)
})

export const useDetailQ = (options) =>
  useQuery({
    queryKey: keys.detail(),
    ...options
  })

queryClient.setMutationDefaults(keys.patchDetail(), {
  mutationFn: ({ data }) => patchDetail(data),
  onMutate: async ({ data }) => {
    const key = keys.detail()
    await queryClient.cancelQueries({ queryKey: key })
    const oldData = queryClient.getQueryData(key)
    if (!oldData) {
      return
    }
    queryClient.setQueryData(key, {
      ...oldData,
      ...data
    })
    return { key, oldData }
  },
  onError: (err, data, context) => {
    if (!context) {
      return
    }
    const { key, oldData } = context
    queryClient.setQueryData(key, oldData)
  }
})

export const usePatchDetailM = (options) =>
  useMutation({ mutationKey: keys.patchDetail(), ...options })

queryClient.setMutationDefaults(keys.patchMeta(), {
  mutationFn: ({ data }) => patchMeta(data),
  onMutate: async ({ data }) => {
    const key = keys.meta()
    await queryClient.cancelQueries({ queryKey: key })
    const oldData = queryClient.getQueryData(key)
    if (!oldData) {
      return
    }
    queryClient.setQueryData(key, {
      ...oldData,
      ...data
    })
    return { key, oldData }
  },
  onError: (err, data, context) => {
    if (!context) {
      return
    }
    const { key, oldData } = context
    queryClient.setQueryData(key, oldData)
  }
})

export const useSyncTimezone = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const isAdminAuth = useIsAdminAuth()
  const { isLoading, data: { timeZone } = {} } = useDetailQ({
    enabled: isLoggedIn
  })
  const { mutate: patchUser } = usePatchDetailM()
  useEffect(() => {
    if (isLoading || !isLoggedIn || isAdminAuth) {
      return
    }
    const browserTimeZone = getBrowserTimeZone()
    if (timeZone === browserTimeZone) {
      return
    }
    patchUser({
      data: {
        timeZone: browserTimeZone
      }
    })
  }, [isLoggedIn, !timeZone, isAdminAuth])
}

const userRQ = {
  keys,
  useMetaQ,
  useDetailQ,
  usePatchDetailM,
  useSyncTimezone
}

export default userRQ

import { BackIcon } from '@atoms/Icons2'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import './style.scss'

export default function BackButton({ onClick, className, ...rest }) {
  const navigate = useNavigate()
  return (
    <button
      {...rest}
      className={classNames(className, 'back-button')}
      onClick={(e) => {
        navigate(-1)
        onClick?.(e)
      }}
    >
      <BackIcon />
    </button>
  )
}

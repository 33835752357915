import ListsPageSkeleton from '@atoms/ListsPageSkeleton'
import { createLazyComponent, createLazyElement } from '@common/utils'
import ArticlePageSkeleton from '@features/article/components/ArticlePageSkeleton'
import overviewRoute from '@features/overview/navigation/route'

const LazyConclusionDetailPage = createLazyComponent(
  () => import('../components/MedicalConclusionPage'),
  <ListsPageSkeleton />
)

const LazyConclusionStageDetailPage = createLazyComponent(
  () => import('../components/MedConcStageDetailPage'),
  <ArticlePageSkeleton />
)

const LazyConclusionsPage = createLazyComponent(
  () => import('../components/ConclusionsPage'),
  <ListsPageSkeleton />
)

export const createDetailOverviewPath = (id) => `/home/conclusions/${id}`

export const createStageDetailOverviewPath = (id, stageId) =>
  `/home/conclusions/${id}/stages/${stageId}`

export const createDetailAccountPath = (id) => `/account/conclusions/${id}`

export const createStageDetailAccountPath = (id, stageId) =>
  `/account/conclusions/${id}/stages/${stageId}`

export const createArticleDetailAccountPath = (id, articleId) =>
  `/account/conclusions/${id}/articles/${articleId}`

export const createArticleDetailOverviewPath = (id, articleId) =>
  `/home/conclusions/${id}/diagnosis-articles/${articleId}`

export const conclusionAccountRouteConfig = {
  path: 'conclusions',
  handle: {
    breadcrumbTrPath: 'medicalConclusion.ConclusionsPage.breadcrumb'
  },
  children: [
    {
      path: ':conclusionId',
      handle: {
        breadcrumbTrPath: 'medicalConclusion.ConclusionDetailPage.breadcrumb'
      },
      children: [
        {
          path: 'diagnosis-articles/:articleId',
          element: createLazyElement(
            () => import('../components/MedConcDiagnosisArticlePage'),
            <ListsPageSkeleton />
          )
        },
        {
          path: 'stages/:stageId',
          element: <LazyConclusionStageDetailPage />
        },
        {
          index: true,
          element: <LazyConclusionDetailPage />
        }
      ]
    },
    {
      index: true,
      element: <LazyConclusionsPage />
    }
  ]
}

overviewRoute.children.push({
  path: 'conclusions/:conclusionId',
  handle: {
    breadcrumbTrPath: 'medicalConclusion.ConclusionDetailPage.breadcrumb'
  },
  children: [
    {
      path: 'diagnosis-articles/:articleId',
      element: createLazyElement(
        () => import('../components/MedConcDiagnosisArticlePage'),
        <ArticlePageSkeleton />
      )
    },
    {
      path: 'stages/:stageId',
      element: <LazyConclusionStageDetailPage />
    },
    {
      index: true,
      element: <LazyConclusionDetailPage />
    }
  ]
})

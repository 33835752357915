import classNames from 'classnames'
import { memo } from 'react'
import { NavLink } from 'react-router-dom'

import { usePrevRenderValue } from '@common/hooks'
import useNavItems from '@features/rootNavigation/hooks/useNavItems'

import './style.scss'

const MobileMenu = ({ visible = true }) => {
  const items = useNavItems()
  const previousVisible = usePrevRenderValue(visible)
  return (
    <section
      className={classNames('mobile-menu', {
        show: !previousVisible && visible,
        hide: !visible
      })}
    >
      {items.map((item) =>
        item.isExternal ? (
          <a
            key={item.to}
            href={item.to}
            target={item.isNewWindow ? '_blank' : '_self'}
            rel="noopener noreferrer"
            className="mobile-menu-item mobile-menu__item"
          >
            <div className="mobile-menu-image">
              <img
                src={item.icon}
                alt={item.title}
                className="mobile-menu-icon"
              />
              <img
                src={item.iconActive}
                alt={item.title}
                className="mobile-menu-icon-active"
              />
            </div>
            <div className="mobile-menu-text">{item.title}</div>
            {item.hasNew && <span className="mobile-menu-badge show" />}
          </a>
        ) : (
          <NavLink
            to={item.to}
            key={item.to}
            target={item.isNewWindow ? '_blank' : '_self'}
            className="mobile-menu-item mobile-menu__item"
          >
            <div className="mobile-menu-image">
              <img
                src={item.icon}
                alt={item.title}
                className="mobile-menu-icon"
              />
              <img
                src={item.iconActive}
                alt={item.title}
                className="mobile-menu-icon-active"
              />
            </div>
            <div className="mobile-menu-text">{item.title}</div>
            {item.hasNew && <span className="mobile-menu-badge show" />}
          </NavLink>
        )
      )}
    </section>
  )
}

export default memo(MobileMenu)
